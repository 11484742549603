import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    ModalFooter
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'
import moment from 'moment';
import {
  showLoader,
  AddNewBlog
} from "../../../store/actions/Auth";
import './newblog.css'
import { MenuItem, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Files from "react-files";
import EventBus from "eventing-bus";

import RichTextEditor from "react-rte";
import ReactQuill from 'react-quill';

var serialize = require('serialize-javascript');

const config = {
  readonly: false // all options from https://xdsoft.net/jodit/doc/
}
class NewBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            SingleRowData: null,
            agreementFile: '',
             // content: RichTextEditor.createEmptyValue(),
            content: "",
            formData: {
              title: "",
              excerpt:"",
              date:"",
              content:"",
              category:"",
              writtenBy:"",
            },
        };

        // props.getAllAgreements()
    }

    modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }

    toggle = () => {
        this.setState({ showModal: !this.state.showModal, agreementFile: '' })
    }

    handleUpload = (e) => {
        this.setState({ agreementFile: e.target.files })
    }

    handleUploadFile = (e) => {
        let data = new FormData();
        for (let i = 0; i < this.state.agreementFile.length; i++) {
            data.append('document', this.state.agreementFile[i]);
            if (this.state.agreementFile.length-1 == i) {
                this.props.uploadAgreement(data)
            }
        }
    }

    componentDidMount(){
      ValidatorForm.addValidationRule('isOnlySpecialCharater', (value) => {
        var pattern = /^[^a-zA-Z0-9]+$/;
        return (!pattern.test(value))
      })
    }

    componentWillReceiveProps(props) {
        if (props.buttonLoader === false)
            this.setState({ showModal: false })
    }

    handleCategoryImage = e => {
      var imageValue = e.length - 1;
      this.setState({ imageValue: URL.createObjectURL(e[imageValue]) });
      var imageDetail = e[0];
      this.setState({ cardImageData: imageDetail });
    };

    handleRemoveImage = () => this.setState({ imageValue: "" });

    handleThumbImage = e => {
      var imageValue = e.length - 1;
      this.setState({ imageThumb: URL.createObjectURL(e[imageValue]) });
      var imageDetail = e[0];
      this.setState({ thumbImageData: imageDetail });
    };

    handleRemoveThumbImage = () => this.setState({ imageThumb: "" });


    onFilesError = e => {
      console.log("file error :: ", e);
      EventBus.publish("error", e.message);
    };

    handleFormChange = event => {
      this.setState({ propertyDescriptionError: false });
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    };

    handleSubmit = () => {
      // serialize({
      //   haxorXSS: this.state.content
      // });

      this.setState({ submitted: true });
      if (
        this.state.imageValue === undefined ||
        this.state.imageValue.length === 0
      )
        EventBus.publish("handleAlert", {
          auth: false,
          message: "Top Banner required"
        });
      else {

        this.props.showLoader(true);
        let data = new FormData();
        let { formData } = this.state;
        data.append("title", formData.title);
        data.append("intro", formData.excerpt);
        data.append("category", formData.category);
        data.append("writtenBy", formData.writtenBy);
        data.append("body", this.state.contentToUpload);
        data.append(`image`, this.state.cardImageData);
        data.append(`image`, this.state.thumbImageData);

        this.props.AddNewBlog({ data, history: this.props.history });
      }
      setTimeout(() => this.setState({ submitted: false }), 4000);
    };

    onChangeEditor = (value) => {
      console.log('value', value.toString("html"));
      this.setState({content:value})
      this.setState({contentToUpload:value.toString("html")})
      // this.setState({ sections }, () => console.log("sections :: ", sections, sections[name].toString("html")));
      // if (this.props.onChange)
      // sections.value1.toString('html')
    };

    render() {
        return (
            <div className="content">
                <div className="main-container">
                    <Modal isOpen={this.state.showModal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <h3 style={{ margin: "0px 0px 0px 25px" }}>Upload Agreement</h3>
                        <ModalBody>
                            <div className="download-row">
                                <Input
                                    type="file"
                                    multiple
                                    accept=".pdf,.docs"
                                    onChange={this.handleUpload}
                                    name="document"
                                />
                            </div>
                            <hr />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleUploadFile}>
                                {
                                    this.props.buttonLoader
                                        ? 'Uploading...'
                                        : 'Upload'
                                }
                            </Button>
                            <Button color="info" onClick={this.toggle}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <div className="agreement-header">
                        <h2 style={{ marginBottom: "20px" }}>Add New Blog</h2>
                    </div>
                    <ValidatorForm
                      className="validatorListForm"
                      onSubmit={this.handleSubmit}
                    >
                    <Grid container spacing={3}>
                      <Grid item xs={7}>
                        <TextValidator
                          type="text"
                          name="title"
                          label="Title"
                          margin="dense"
                          variant="outlined"
                          validators={["required",'isOnlySpecialCharater']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.title}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Title can not be empty","Should not contain only special chrachters"]}
                        />
                      </Grid>

                      <Grid item xs={7}>
                        <TextValidator
                          type="text"
                          name="writtenBy"
                          label="Author"
                          margin="dense"
                          variant="outlined"
                          validators={["required",'matchRegexp:^[A-Za-z\\s]+$']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.writtenBy}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Author can not be empty","Special Chrachters and Numbers are not allowed"]}
                          />
                      </Grid>

                      <Grid item xs={12}>
                        <TextValidator
                          type="text"
                          name="excerpt"
                          label="Excerpt"
                          margin="dense"
                          variant="outlined"
                          validators={["required",'isOnlySpecialCharater']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.excerpt}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Excerpt can not be empty","Should not contain only special chrachters"]}
                        />
                      </Grid>
                      {/*
                        <Grid item xs={8}>
                          <TextValidator
                            select
                            type="text"
                            margin="dense"
                            name="category"
                            label="Category"
                            variant="outlined"
                            className="MyTextField"
                            validators={["required"]}
                            onChange={this.handleFormChange}
                            value={this.state.formData.category}
                            errorMessages={["Category can not be empty"]}
                          >
                            <MenuItem
                              name="category"
                              onChange={this.handleFormChange}
                              value="Residential"
                            >
                              Residential
                            </MenuItem>
                            <MenuItem
                              name="category"
                              onChange={this.handleFormChange}
                              value="Multifamily"
                            >
                              Multifamily
                            </MenuItem>
                            <MenuItem
                              name="category"
                              onChange={this.handleFormChange}
                              value="Office"
                            >
                              Office
                            </MenuItem>
                            <MenuItem
                              name="category"
                              onChange={this.handleFormChange}
                              value="Mixed Use"
                            >
                              Mixed Use
                            </MenuItem>
                            <MenuItem
                              name="category"
                              onChange={this.handleFormChange}
                              value="Warehouse | Industrial"
                            >
                              Warehouse | Industrial
                            </MenuItem>
                          </TextValidator>
                        </Grid>
                      */}
                      <Grid item xs={12}>

                      <h4 className="divider-heading">Top Banner</h4>
                      {this.state.imageValue !== "" &&
                      this.state.imageValue !== undefined ? null : (
                        <Files
                          onChange={e => this.handleCategoryImage(e)}
                          style={{ backgroundColor: "#f4f4f4" }}
                          onError={this.onFilesError}
                          accepts={["image/*"]}
                          className="dropZone"
                          maxFileSize={10000000}
                          minFileSize={0}
                          clickable
                        >
                          <div className="activities-form-cardimage-dropzone-text">
                            <p>Drop the Top Banner here or click to upload it.</p>
                            <p>
                              Recommended <strong>Height</strong> of at least{" "}
                              <strong>390px</strong>.
                            </p>
                          </div>
                        </Files>
                      )}

                      {this.state.imageValue !== "" &&
                      this.state.imageValue !== undefined ? (
                        <div className="singleImage">
                          <img className="imageList" src={this.state.imageValue} />
                          <button
                            type="button"
                            onClick={() => {
                              this.handleRemoveImage();
                            }}
                            style={{ fontSize: "14px" }}
                            className="btn btn-danger ml-auto iconButton"
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      ) : null}

                      </Grid>

                      <Grid item xs={12}>

                      <h4 className="divider-heading">Thumbnail</h4>
                      {this.state.imageThumb !== "" &&
                      this.state.imageThumb !== undefined ? null : (
                        <Files
                          onChange={e => this.handleThumbImage(e)}
                          style={{ backgroundColor: "#f4f4f4" }}
                          onError={this.onFilesError}
                          accepts={["image/*"]}
                          className="dropZone"
                          maxFileSize={10000000}
                          minFileSize={0}
                          clickable
                        >
                          <div className="activities-form-cardimage-dropzone-text">
                            <p>Drop the Thumbnail here or click to upload it.</p>
                            <p>
                              Recommended <strong>Height</strong> of max{" "}
                              <strong>250px</strong>.
                            </p>
                          </div>
                        </Files>
                      )}

                      {this.state.imageThumb !== "" &&
                      this.state.imageThumb !== undefined ? (
                        <div className="singleImage">
                          <img className="imageList" src={this.state.imageThumb} />
                          <button
                            type="button"
                            onClick={() => {
                              this.handleRemoveThumbImage();
                            }}
                            style={{ fontSize: "14px" }}
                            className="btn btn-danger ml-auto iconButton"
                          >
                            <i className="fa fa-trash" />
                          </button>
                        </div>
                      ) : null}

                      </Grid>

                      <Grid item xs={12}>
                        {/*
                          <RichTextEditor
                            value={this.state.content}
                            name="content"
                            onChange={value =>
                              this.onChangeEditor(value)
                            }
                          />
                        */}

                        <ReactQuill
                           name="content"
                           id="content"
                           modules={this.modules}
                           value={this.state.content}
                           onChange={value =>
                             this.onChangeEditor(value)
                           }
                         />

                      </Grid>

                    </Grid>

                    <Button
                      type="submit"
                      className="btn-info"
                      style={{ width: "210px", marginTop: "10px" }}
                      disabled={this.props.buttonLoader}
                    >
                      {!this.props.buttonLoader ? (
                        "Submit Blog"
                      ) : (
                        <i
                          style={{ color: "white" }}
                          className="fa fa-spinner fa-spin fa-1x fa-fw"
                        ></i>
                      )}
                    </Button>

                    </ValidatorForm>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("Agreements state :: ", state)
    return {
        agreements: state.Auth.agreements,
        buttonLoader: state.Auth.buttonLoader
    }
}

const mapDispatchToProps =
{
showLoader:showLoader,
AddNewBlog:AddNewBlog
};

export default connect(mapStateToProps, mapDispatchToProps)(NewBlog);
