import { Button, Modal,
ModalHeader,
ModalBody,
ModalFooter,
Input,
} from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getAllEvents, deleteEvent } from "../../../store/actions/Auth";
import { startLoader, stopLoader } from "../../../store/actions/Tokinize";

import moment from 'moment';

import './Events.css'

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.startLoader(`Fetching Events`);
    props.getAllEvents()
  }


  toggle = () => {
      this.props.history.push(`/home/new-event`);
    // this.setState({ showModal: !this.state.showModal, agreementFile: '' })
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleViews = (e) => window.open(e, '_blank')

  // viewDetails = (user) => {
  //   let query = `id=${user['_id']}`;
  //   this.props.history.push(`/home/support-details?${query}`);
  // }

  deleteEvent = (user) => {
    this.props.startLoader(`Deleting Event`);
    let query = `id=${user['_id']}`;
    this.props.deleteEvent(user['_id'])
    // this.props.history.push(`/home/support-details?${query}`);
  }

  editEvent = (user) => {
    let query = `id=${user['_id']}`;
    this.props.history.push(`/home/edit-event?${query}`);
  }


  render() {
    console.log('getAllEvents', this.props.allEvents);
    const { allEvents } = this.props
    const webinar = allEvents ? allEvents['media'] : [];
    const events = allEvents ? allEvents['events'] : [];
    const columns = [{
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Sign up Link',
      accessor: 'signUp',
    },
    {
      Header: 'Start Date',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {moment(props.original.startDate).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: 'End Date',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {moment(props.original.endDate).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      minWidth: 180,
      maxWidth: 200,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          <Button 
          onClick={() => {
            this.editEvent(props.original)
          }} 
          className="btn-info action-btn action-btn-outline-info">
          Edit
          </Button>
          <Button 
          onClick={() => {
            this.deleteEvent(props.original)
          }} 
          className="btn-info action-btn action-btn-outline-info">
          Delete
          </Button>
          </span>
        )
      }
    }
    ]

    const columns2 = [{
      Header: 'Title',
      accessor: 'title',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Video Link',
      accessor: 'link',
    },
    {
      Header: 'Date',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {moment(props.original.date).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      minWidth: 180,
      maxWidth: 200,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
            <Button 
              onClick={() => {
                this.editEvent(props.original)
              }} 
              className="btn-info action-btn action-btn-outline-info">
              Edit
          </Button>
          <Button 
          onClick={() => {
            this.deleteEvent(props.original)
          }}
            className="btn-info action-btn action-btn-outline-info">
          Delete
          </Button>
          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="agreement-header">
              <h2 style={{ marginBottom: "20px" }}>Events</h2>
              <Button onClick={this.toggle} className="btn-info action-btn py-3 px-2" style={{ minWidth: "80px", background: "#92BF55"}}>
                  Add Event or Media
              </Button>
          </div>

          {this.props.allEvents !== ''
            && <ReactTable
               defaultPageSize={10}
              data={events}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={5}
              getTdProps={this.getTableProps}
            />
          }
          <div className="agreement-header mt-5">
              <h2 style={{ marginBottom: "20px" }}>Media</h2>
          </div>
          {this.props.allEvents !== ''
            && <ReactTable
               defaultPageSize={10}
              data={webinar}
              columns={columns2}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={5}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { allEvents } = Auth;
  return { allEvents }
}

const mapDispatchToProps = { getAllEvents, deleteEvent, startLoader };
export default connect(mapStateToProps, mapDispatchToProps)(Events);
