import { Button } from "reactstrap";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import React, { Component } from "react";
import { MenuItem, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { addPropertyOwner } from './../../../store/actions/Property'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class AddPropertyOwners extends Component {

    state = {
      firstName:"", 
      lastName: "",
      gender:"",
      address:"",
      email:"", 
      dob: new Date("2000-08-18T15:11:54.000Z"),
      phone:"", 
      publicAddress:""
    };

  componentDidMount() {

    ValidatorForm.addValidationRule('isOnlySpecialCharater', (value) => {
      var pattern = /^[^a-zA-Z0-9]+$/;
      return (!pattern.test(value))
    })

    ValidatorForm.addValidationRule('validURL', (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    })
  }
  
  handleOnChange = event => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleSubmit = () => {
    console.log("state :: ", this.state);
    this.props.addPropertyOwner(this.state)
    this.setState({ 
      firstName:"", 
      lastName: "",
      gender:"",
      address:"",
      email:"", 
      dob: new Date("2000-08-18T15:11:54.000Z"),
      phone:"", 
      publicAddress:""
    })

  }

  handleDate = dob => {
    this.setState({ dob });
  }


  render() {
      const { firstName, lastName, gender, address, email, dob, phone, publicAddress } = this.state
      const { handleOnChange, handleDate } = this
    return (
      <div className="content">
        <div className="main-container list-new">
        <h2 className="mb-5 pb-3">Add Property Owner</h2>
          <ValidatorForm
            className="validatorListForm"
            onSubmit={this.handleSubmit}
          >
            <Grid container justify="space-around" spacing={3}>
                  <Grid item xs={6}>
                    <TextValidator
                      type="text"
                      name="firstName"
                      label="First Name"
                      margin="dense"
                      variant="outlined"
                      onChange={handleOnChange}
                      value={firstName}
                      className="MyTextField mx-auto"
                      validators={["required"]}
                      errorMessages={["Title can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="Last Name"
                      name="lastName"
                      type="text"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      onChange={handleOnChange}
                      value={lastName}
                      validators={["required"]}
                      errorMessages={["Postal Code can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="Email"
                      name="email"
                      type="text"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      onChange={handleOnChange}
                      value={email}
                      validators={["required"]}
                      errorMessages={["Email can not be empty"]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      select
                      type="text"
                      margin="dense"
                      name="gender"
                      label="Gender"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={handleOnChange}
                      value={gender}
                      errorMessages={["Gender can not be empty"]}
                    >
                      <MenuItem
                        value="fe-male"
                      >
                        Fe Male
                      </MenuItem>
                      <MenuItem
                        value="male"
                      >
                        Male
                      </MenuItem>
                    </TextValidator>
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      name="address"
                      type="text"
                      label="Address"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={handleOnChange}
                      value={address}
                      errorMessages={["Address can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      type="text"
                      margin="dense"
                      name="publicAddress"
                      label="Public Address"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={handleOnChange}
                      value={publicAddress}
                      errorMessages={["Public Address can not be empty"]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="number"
                      margin="dense"
                      name="phone"
                      variant="outlined"
                      className="MyTextField"
                      label="Phone No."
                      onChange={handleOnChange}
                      value={phone}
                      validators={["required"]}
                      errorMessages={["phone can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      className="MyTextField"
                      onChange={handleDate}
                      selected={dob}
                      placeholderText="Webinar date"
                     />
                  </Grid>
                </Grid>
            
            <div className='row'>
              <div className='col-6 text-right mt-4'>
              <Button
                className="btn-info col-4"
                type="submit"
              >
                Add Owner
              </Button>
              </div>
            </div>
          </ValidatorForm>
          </div>
          </div>
    );
  }
}

const mapStateToProps = ({ Auth, Property }) => {
  let { singlePropertyRequest, listedProperties, buttonLoader } = Auth;
  let { propertyOwners } = Property;
  return { singlePropertyRequest, listedProperties, buttonLoader, propertyOwners };
};

const mapDispatchToProps = { addPropertyOwner };

export default connect(mapStateToProps, mapDispatchToProps)(AddPropertyOwners);
