import Dashboard from "views/PropertyOwner/Dashboard/Dashboard.js";
import ListedProperties from "views/PropertyOwner/ListedProperties/ListedProperties.js";
import PropertyDetails from "views/PropertyOwner/PropertyDetails/PropertyDetails.js"
import PropertyOwnerProfile from "views/PropertyOwner/PropertyOwnerProfile/PropertyOwnerProfile.js"

var routes = [
  {
    layout: "/home",
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    icon: "tim-icons icon-chart-pie-36",
  },

  {
    name: "Property",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-bank",
    childs: [
    {
      name: "Listed Properties",
      path: "/active-listings",
      icon: "tim-icons icon-single-copy-04",
    }]
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/active-listings",
    component: ListedProperties,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/property-details",
    component: PropertyDetails,
  },
  {
    layout: "/home",
    name: "Profile",
    icon: "far fa-user",
    path: "/property-owner-profile",
    component: PropertyOwnerProfile,
  },
];
export default routes;
