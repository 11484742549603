import classNames from "classnames";
import { connect } from "react-redux";
import React, { Component, Fragment } from "react";

import { web3 } from '../../store/web3';

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Input,
  NavbarBrand,
  Navbar,
  NavLink,
  Nav,
  Container,
  Modal
} from "reactstrap";

import { logout } from "../../store/actions/Auth";


class AdminNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      networkId: 0,
      walletAddress: '',
      collapseOpen: false,
      modalSearch: false,
      color: "navbar-transparent"
    };
  }

  async componentDidMount() {
    let networkId = await web3.eth.net.getId();
    let walletAddress = (await web3.currentProvider.enable())[0];
    this.setState({ networkId, walletAddress });
    window.addEventListener("resize", this.updateColor);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateColor);
  }

  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen)
      this.setState({ color: "bg-white" });
    else this.setState({ color: "navbar-transparent" });
  };

  toggleCollapse = () => {
    if (this.state.collapseOpen)
      this.setState({ color: "navbar-transparent" });
    else this.setState({ color: "bg-white" });
    this.setState({ collapseOpen: !this.state.collapseOpen });
  };

  toggleModalSearch = () => this.setState({ modalSearch: !this.state.modalSearch });

  handleLogout = () => {
    this.props.logout();
    this.props.history.push('/login');
  }

  render() {
    let { role } = this.props;
    let { networkId, walletAddress } = this.state;
    return (
      <div>
        <Navbar
          className={classNames("navbar-absolute", this.state.color)}
          expand="lg"
        >
          <Container fluid>
            <div className="navbar-wrapper">
              <div
                className={classNames("navbar-toggle d-inline", {
                  toggled: this.props.sidebarOpened
                })}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={this.props.toggleSidebar}
                >
                  <span className="navbar-toggler-bar bar1" />
                  <span className="navbar-toggler-bar bar2" />
                  <span className="navbar-toggler-bar bar3" />
                </button>
              </div>
              <NavbarBrand href="#pablo" onClick={e => e.preventDefault()}>
                <img style={{ objectFit: "scale-down", width: "50%", borderRadius: "50%" }} alt="..." src={"/favicon.png"} />
              </NavbarBrand>
            </div>
            <button
              aria-expanded={false}
              aria-label="Toggle navigation"
              className="navbar-toggler"
              data-target="#navigation"
              data-toggle="collapse"
              id="navigation"
              type="button"
              onClick={this.toggleCollapse}
            >
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
              <span className="navbar-toggler-bar navbar-kebab" />
            </button>
            <Collapse navbar isOpen={this.state.collapseOpen}>
              <Nav className="ml-auto" navbar>
                <div className='mt-3 row mr-3' style={{ color: 'rgba(0,0,0,.65)' }} >
                  <i className="mr-2 mt-1 tim-icons icon-single-02" />
                  <p style={{ color: 'rgba(0,0,0,.65)' }}>{roles[role]}</p>
                  <i className="ml-4 mr-2 mt-1 tim-icons icon-world" />
                  <p style={{ color: 'rgba(0,0,0,.65)' }}>{networks[networkId]}</p>
                  <i className="ml-4 mr-2 mt-1 tim-icons icon-wallet-43" />
                  <p style={{ color: 'rgba(0,0,0,.65)' }}>{walletAddress}</p>
                </div>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    caret
                    color="default"
                    data-toggle="dropdown"
                    nav
                    onClick={e => e.preventDefault()}
                  >
                    <div className="photo">
                      <img alt="..." src="/favicon.png" />
                    </div>
                    <b className="caret d-none d-lg-block d-xl-block" />
                    <p className="d-lg-none" >Log out</p>
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-navbar" right tag="ul">
                    <NavLink tag="li">
                      <DropdownItem className="nav-item">Profile Settings</DropdownItem>
                    </NavLink>
                    <DropdownItem divider tag="li" />
                    <NavLink tag="li">
                      <DropdownItem className="nav-item" onClick={this.handleLogout} >Log out</DropdownItem>
                    </NavLink>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="separator d-lg-none" />
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <Modal
          modalClassName="modal-search"
          isOpen={this.state.modalSearch}
          toggle={this.toggleModalSearch}
        >
          <div className="modal-header">
            <Input id="inlineFormInputGroup" placeholder="SEARCH" type="text" />
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={this.toggleModalSearch}
            >
              <i className="tim-icons icon-simple-remove" />
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

const roles = {
  admin: 'Super Admin',
  assets: 'Assets Manager',
  finance: 'Finance Manager',
  propertyOwner: 'Property Owner',
}

const mapStateToProps = ({ Auth }) => {
  let { role } = Auth;
  return { role }
}

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(AdminNavbar);