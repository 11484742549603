import { setToken } from '../axios';
import { PURGE } from "redux-persist";

var initialState = {
  users: '',
}

const Roles = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case 'GET_ROLE_RESPONSE':
      console.log(`******users= `, payload);

      return {
        ...state,
        users: payload
      };
    default:
      return state;
  }
};
export default Roles;
