import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from 'react';
// import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { web3 } from '../../store/web3';
import { transferOwnership, extendStoTime } from '../../store/actions/Property';
import { startLoader, stopLoader, finalizeSTO } from "../../store/actions/Tokinize";
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ModalFooter
} from "reactstrap";
import YearPicker from "react-year-picker";


class PropertyOffering extends Component {
  state = {
    hasClosed: false,
    finalized: false,
    showModal: false,
    endDate: new Date(this.props.stoDetails['endDate']),
  }

  async componentDidMount() {
    let { abi, contractAddress } = this.props.stoDetails;
    console.log('this.props.stoDetails', this.props.stoDetails);
    if (contractAddress) {
      const STO = new web3.eth.Contract(abi, contractAddress);
      let hasClosed = await STO.methods.hasClosed().call();
      let finalized = await STO.methods.finalized().call();
      this.setState({ hasClosed, finalized });
    }
    this.handleChange = this.handleChange.bind(this);

  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
  }

  componentWillReceiveProps(props) {
    if (props.buttonLoader === false)
    this.setState({ showModal: false })
  }

  finalizeSTO = async () => {
    try {
      let { symbol } = this.props.tokenDetails;
      let { abi, contractAddress } = this.props.stoDetails;
      const STO = new web3.eth.Contract(abi, contractAddress);
      let from = ((await web3.currentProvider.enable())[0]).toLowerCase();

      this.props.startLoader(`Finalizing ${symbol} Offering`);
      await STO.methods.finalize().send({ from });

      let hasClosed = await STO.methods.hasClosed().call();
      this.setState({ hasClosed });

      this.props.finalizeSTO({ propertyId: this.props.propertyDetails['_id'] });
    } catch (e) {
      this.props.stopLoader()
      EventBus.publish("error", `Error while transfering`);
    }
  }

  transferOwnership = async () => {
    try {
      let newAdmin = this.props.stoDetails.contractAddress;
      let { symbol, adminWallet, abi, contractAddress } = this.props.tokenDetails;

      let from = ((await web3.currentProvider.enable())[0]).toLowerCase();
      let prevAdmin = (adminWallet).toLowerCase();

      if (from !== prevAdmin) {
        EventBus.publish("error", `Only ${symbol} Admin can transfer Ownership`);
        return;
      }

      this.props.startLoader(`Transfering ${symbol} Ownership to Security Offering`);

      const ST = new web3.eth.Contract(abi, contractAddress);
      await ST.methods.transferOwnership(newAdmin).send({ from });
      // this.props.transferOwnership({ stoId: this.props.stoDetails['_id'], propertyId: this.props.propertyDetails['_id'] });
    } catch (e) {
      this.props.stopLoader()
      console.log(`************`, e);

      EventBus.publish("error", `Error while transfering`);
    }
  }

  extendTime = async () => {
    try {
      let { symbol, adminWallet, abi, contractAddress } = this.props.stoDetails;

      let from = ((await web3.currentProvider.enable())[0]).toLowerCase();
      console.log(`*********abi, contractAddress =`, abi, contractAddress, this.state.endDate);
      let timeStamp = this.state.endDate.getTime()
      console.log('timeStamp', timeStamp);
      this.props.startLoader(`Extending End Date`);
      this.toggle()
      const STO= new web3.eth.Contract(abi, contractAddress);
      let receipt = await STO.methods.extendTime(this.state.endDate.getTime()).send({ from });
      console.log(`*********receipt =`, receipt);
      this.props.extendStoTime({ stoId: this.props.stoDetails['_id'] });
    } catch (e) {
      this.props.stopLoader()
      console.log(`************`, e);

      EventBus.publish("error", `Error while extending`);
    }
  }
  handleChange(date) {
    console.log(date);
    this.setState({endDate:date})
  }

  render() {
    const { hasClosed, finalized } = this.state;
    const { stoDetails, tokenDetails } = this.props;
    console.log('aaaaaaaaaaaa',stoDetails['startDate'], this.state.endDate);

    return (
      <div className='property-overview'>
        <div className='property-header'>
          <h3 style={{ 'fontWeight': '650' }}>Token Details</h3>
          {!stoDetails.ownershipTransfered &&
            <div>
              <Button onClick={() => this.transferOwnership()}>
                Transfer Ownership
              </Button>
            </div>
          }
          {hasClosed && !finalized &&
            <div>
              <Button onClick={() => this.finalizeSTO()}>
                Finalize STO
            </Button>
            </div>
          }
          <Modal isOpen={this.state.showModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <h3 style={{ margin: "0px 0px 0px 25px" }}>Extend Property End Date</h3>
            <ModalBody>

              <DatePicker
                onChange={this.handleChange}
                selected={this.state.endDate ? this.state.endDate : ''}
                showTimeSelect
                dateFormat="Pp"
                />
              <hr />
            </ModalBody>
            <ModalFooter>
              <Button color="info" disabled={this.props.buttonLoader} onClick={this.extendTime}>
                {
                  this.props.buttonLoader
                  ? 'Updating...'
                  : 'Update'
                }
              </Button>
              <Button color="info" onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
          {!hasClosed &&
            <div>
              <Button onClick={this.toggle}>
                Extend Date
              </Button>
            </div>
          }

        </div>
        <div>
          <div className="single-row">
            <div className="modal-content-title">
              Start Date
            </div>
            <div className="modal-content-data">
              {moment(stoDetails['startDate']).format('lll')}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              End Date
            </div>
            <div className="modal-content-data">
              {moment(stoDetails['endDate']).format('lll')}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Owner Ship Transfered
            </div>
            <div className="modal-content-data">
              {stoDetails.ownershipTransfered ? 'Done' : 'Pending'}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              STO State
            </div>
            <div className="modal-content-data">
              {stoDetails.status}
            </div>
          </div>

          {stoDetails.status === 'Pre-Launch' &&
            <div className="single-row">
              <div className="modal-content-title">
                Days Remaining to Start
            </div>
              <div className="modal-content-data">
                {stoDetails.daysRemaining}
                <span className='symbol'>
                  Days
              </span>
              </div>
            </div>
          }

          {(stoDetails.status === 'Pre-Goal' || stoDetails.status === 'Post-Goal') &&
            <div className="single-row">
              <div className="modal-content-title">
                Days Remaining to End
            </div>
              <div className="modal-content-data">
                {stoDetails.daysRemaining}
                <span className='symbol'>
                  Days
              </span>
              </div>
            </div>
          }

          <div className="single-row">
            <div className="modal-content-title">
              Property Value
            </div>
            <div className="modal-content-data">
              {stoDetails.propertyValue}
              <span className='symbol'>
                TKUSD
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Token Price
            </div>
            <div className="modal-content-data">
              {stoDetails.price}
              <span className='symbol'>
                TKUSD
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Total Tokens
            </div>
            <div className="modal-content-data">
              {stoDetails.totalTokens}
              <span className='symbol'>
                {tokenDetails.symbol}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Offering Goal
            </div>
            <div className="modal-content-data">
              {stoDetails.goalPercent}
              <span className='symbol'>
                %
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Offering Cap
            </div>
            <div className="modal-content-data">
              {stoDetails.capPercent}
              <span className='symbol'>
                %
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens On Sale
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenOnSale}
              <span className='symbol'>
                {tokenDetails.symbol}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens UnSold
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenUnSold}
              <span className='symbol'>
                {tokenDetails.symbol}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens Sold
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenSold}
              <span className='symbol'>
                {tokenDetails.symbol}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Network
            </div>
            <div className="modal-content-data">
              {stoDetails.network}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Stable Coin Currency
            </div>
            <div className="modal-content-data">
              {stoDetails.currency}
            </div>
          </div>

          {stoDetails.currencyAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Stable Coin Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.currencyAddress} target='_blank'>
                  {stoDetails.currencyAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.propertyWallet &&
            <div className="single-row">
              <div className="modal-content-title">
                Property Store Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.propertyWallet} target='_blank'>
                  {stoDetails.propertyWallet}
                </a>
              </div>
            </div>
          }

          {stoDetails.propertyOwner &&
            <div className="single-row">
              <div className="modal-content-title">
                Property Owner Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.propertyOwner} target='_blank'>
                  {stoDetails.propertyOwner}
                </a>
              </div>
            </div>
          }

          {stoDetails.fromWallet &&
            <div className="single-row">
              <div className="modal-content-title">
                Tokenism Manager Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.fromWallet} target='_blank'>
                  {stoDetails.fromWallet}
                </a>
              </div>
            </div>
          }

          {stoDetails.whitelistAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Tokenism Whitelist Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.whitelistAddress} target='_blank'>
                  {stoDetails.whitelistAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.contractAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Contract Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.contractAddress} target='_blank'>
                  {stoDetails.contractAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.transactionHash &&
            <div className="single-row">
              <div className="modal-content-title">
                Transaction Hash
              </div>
              <div className="modal-content-data">
                <a href={(stoDetails.explorer + stoDetails.transactionHash).replace(`address`, `tx`)} target='_blank'>
                  {stoDetails.transactionHash}
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    buttonLoader: state.Auth.buttonLoader
  }
}
const mapDispatchToProps = { startLoader, stopLoader, transferOwnership, finalizeSTO, extendStoTime };
export default connect(mapStateToProps, mapDispatchToProps)(PropertyOffering);
