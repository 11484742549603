import Files from "react-files";
import { Button } from "reactstrap";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import React, { Component } from "react";
import { MenuItem, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { startLoader, stopLoader } from "./../../../store/actions/Tokinize";
import { ListNewPropertyFn, showLoader, getSinglePropertyRequest } from "./../../../store/actions/Auth";
import { propertyStep1 } from "./../../../store/actions/Property";
import GoogleMapReact from 'google-map-react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { GeolocatedProps, geolocated } from "react-geolocated";

import "./listNewPropertySteps.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
var url = require("url");

class MapView extends Component {
  static defaultProps = {
    center: {
      lat: 40.7128,
      lng: 74.0060
    },
    zoom: 11
  };

// 33.5413248
// 73.0988544

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      step: 1,
      locations: [],
      lat:74.0060,
      lng:40.7128,
      apiKey: 'AIzaSyAnBQj18m0VPF6D28H5PJ_inTfHO0SGi6E',
    };
    this.handleMapClick = this.handleMapClick.bind(this);
  }

  componentDidMount() {
    navigator.geolocation.getCurrentPosition((position) =>{
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      this.props.center.lat = position.coords.latitude
      this.props.center.lng = position.coords.longitude

      this.setState({lat:position.coords.latitude, lng:position.coords.longitude})
      this.props.onChangeValue(position.coords.latitude, position.coords.longitude);

    });
    console.log('geolocated', this.props.coords);
  }

  componentWillReceiveProps(nextProps) {
    console.log('nextProps', nextProps);
    this.setState({lat:nextProps.lat, lng:nextProps.lng})
  }

  handleMapClick = (ref, map, ev) => {
    const location = ev.latLng;
    // console.log('latlng received', this.state.lat, this.state.lng);
    map.panTo(location);
    this.setState({locations:location, lat:location.lat(), lng:location.lng()})
    this.props.onChangeValue(location.lat(), location.lng());

  };


  moveMarker = (ref, map, ev) => {
    const location = ev.latLng;
    // console.log('latlng received', this.state.lat, this.state.lng);
    this.setState({locations:location, lat:location.lat(), lng:location.lng()})
    this.props.onChangeValue(location.lat(), location.lng());

    // map.panTo(location);
  };

  handleApiLoaded = (map, maps) => {
    console.log('map, maps', map, maps);
  // use map and maps objects
  };


  render() {
    return (

            <div className="map-area" locations={this.state}>
              <h3>Location</h3>
              <div className="map-outer">
                <Map
                  google={this.props.google}
                  className={"map"}
                  center={{
                    lat: this.state.lat,
                    lng: this.state.lng
                  }}
                  zoom={this.props.zoom}
                  onClick={this.handleMapClick}
                  >
                      <Marker
                        title="Location"
                        id={1}
                        position={{ lat: this.state.lat, lng: this.state.lng }}
                        draggable={true}
                        onDragend={this.moveMarker.bind(this)}
                        >
                      </Marker>
                </Map>
              </div>
            </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
};

const mapDispatchToProps = { };
export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
  apiKey: 'AIzaSyAnBQj18m0VPF6D28H5PJ_inTfHO0SGi6E',
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,

})(MapView));
// export default connect(mapStateToProps, mapDispatchToProps)(Step1);
