import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getAllPremiumRequests, deletePremiumRequest, acceptPremiumRequest } from "../../../store/actions/Auth";

import './PremiumRequests.css'
import moment from 'moment';

class PremiumRequests extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.getAllPremiumRequests()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleViews = (e) => window.open(e, '_blank')

  viewDetails = (user) => {
    console.log(user,'user');
    let query = `id=${user['userId']}`;
    this.props.history.push(`/home/user-details?${query}`);
  }

  deleteRequest = (user) => {
    this.props.deletePremiumRequest(user['_id'])
  }

  acceptRequest = (user) => {
    this.props.acceptPremiumRequest(user['_id'])
  }



  render() {
    console.log('this.props.premiumRequests', this.props.premiumRequests);
    const columns = [
      {
        Header: 'User Name',
        accessor: 'status',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
        Cell: props => {
          return (
            <>
            {
              props.original.userId &&
              <span style={{ display: "flex" }}>
              {props.original.userId.firstName + " " + props.original.userId.lastName}
              </span>
            }
            </>
          )
        }
      },
      {
        Header: 'User Email',
        accessor: 'status',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
        Cell: props => {
          return (
            <>
            {
              props.original.userId &&
              <span style={{ display: "flex" }}>
              {props.original.userId.email}
              </span>
            }
            </>
          )
        }
      },
      {
        Header: 'Status',
        accessor: 'status',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
      },
    {
      Header: 'Status',
      accessor: 'status',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Reason',
      accessor: 'reason',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span>
            {moment(props.original.createdAt).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: 'Action',
      accessor: 'publicAddress',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
      width: 220,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
            {props.original.status == "Pending" && (
            <>
            <Button onClick={() => this.acceptRequest(props.original)} className="btn-info action-btn action-btn-outline-info">
              Accept
              </Button>
              <Button onClick={() => this.deleteRequest(props.original)} className="btn-info action-btn action-btn-outline-info">
              Reject
              </Button>
              </>
            )
            }
          </span>
        )
      }
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: 120,
      minWidth: 90,
      maxWidth: 120,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
            <Button onClick={() => this.viewDetails(props.original)} className="btn-info action-btn action-btn-outline-info">
              View
              </Button>
          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 style={{ marginBottom: "20px" }}>Premium Requests</h2>
            </div>
          </div>

          {this.props.premiumRequests !== ''
            && <ReactTable
              data={this.props.premiumRequests}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { premiumRequests } = Auth;
  return { premiumRequests }
}

const mapDispatchToProps = { getAllPremiumRequests, deletePremiumRequest, acceptPremiumRequest };
export default connect(mapStateToProps, mapDispatchToProps)(PremiumRequests);
