export const onDeleteRole = (data) => ({
  type: 'DELETE_ROLE',
  payload: data
});

export const getAllRoles = () => ({
  type: 'GET_ALL_ROLES',
});

export const addNewRole = ({ payload, history }) => ({
  type: 'ADD_NEW_ROLE',
  payload,
  history
});

export const showLoader = (data) => ({
  type: 'SHOW_LOADER',
  payload: data
});
