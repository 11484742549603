export const updatePropertyOverview = data => ({
  payload: data,
  type: "UPDATE_PROPERTY_OVERVIEW"
});

export const deleteImage = data => ({
  payload: data,
  type: "DELETE_IMAGE"
});

export const uploadCarousel = data => ({
  type: "UPLOAD_CAROUSEL",
  payload: data
});

export const updateSection = data => ({
  type: "UPDATE_SECTION",
  payload: data
});

export const transferOwnership = data => ({
  type: "TRANSFER_OWNERSHIP",
  payload: data
});

export const getPropertyOwner = data => ({
  type: "GET_PROPERTY_OWNER",
  payload: data
});

export const addPropertyOwner = data => ({
  type: "ADD_PROPERTY_OWNER",
  payload: data
});

export const extendStoTime = data => ({
  type: "EXTEND_STO_TIME",
  payload: data
});

export const propertyStep1 = ({ data, history }) => ({
  type: 'PROPERTY_STEP_1',
  payload: data,
  history
});

export const propertyStep2 = ({ data, history, id }) => ({
  type: 'PROPERTY_STEP_2',
  payload: data,
  history,
  id
});

export const propertyStep3 = ({ data, history, id }) => ({
  type: 'PROPERTY_STEP_3',
  payload: data,
  history,
  id
});

export const onDeleteUserDocument = ({data, id}) => ({
  type: 'DELETE_USER_DOCUMENT',
  payload: data,
  id:id
});

export const getAllUserDocuments = () => ({
  type: 'GET_ALL_USER_DOCUMENT',
});

export const uploadUserDocument = ({data, id}) => ({
  type: 'UPLOAD_USER_DOCUMENT',
  payload: data,
  id:id
});
