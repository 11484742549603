import { connect } from "react-redux";
import React, { Component } from 'react';
import { getPropertyInvestments } from '../../../store/actions/PropertyOwner';
import ReactTable from 'react-table-6';


class PropertyInvestments extends Component {
        state = {
          SingleRowData: null
        }
    
      filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
      }
    
      getTableProps = (state, rowInfo, col, instance) => ({
        onClick: (e, cb) => {
          if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
        }
      })

    componentDidMount(){
        const url = require('url');
        const { query } = url.parse(document.location.href, true);
        this.props.getPropertyInvestments(query['id'])
    }
    
    render() { 
        const { investmentDet } = this.props
        const columns = [{
            Header: 'Name',
            accessor: 'userId.firstName',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },{
            Header: 'Action',
            accessor: 'action',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },
          {
            Header: 'Amount',
            accessor: 'amount',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },
          {
            Header: 'Fee',
            accessor: 'fee',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },
          {
            Header: 'Currency',
            accessor: 'currency',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },
          {
            Header: 'Filled Amount',
            accessor: 'filledAmount',
            style: {
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              textAlign: 'center',
            },
          },
        //   {
        //     Header: '',
        //     style: {
        //       display: "flex",
        //       justifyContent: "center",
        //     },
        //     width: 220,
        //     minWidth: 120,
        //     maxWidth: 250,
        //     sortable: false,
        //     filterable: false,
        //     resizable: false,
        //     Cell: props => {
        //       return (
        //         <span style={{ display: "flex" }}>
        //           <Button onClick={() => this.viewDetails(props.original)} className="btn-info action-btn action-btn-outline-info">
        //             View
        //             </Button>
        //         </span>
        //       )
        //     }
        //   }
          ]
        
        return ( 
            <div className='property-overview'>
                <div className='property-header'>
                    <h3 style={{ 'fontWeight': '650' }}>Investment Details</h3>
                </div>
                {investmentDet.length > 0 ? 
                    <ReactTable
                    data={investmentDet}
                    columns={columns}
                    defaultFilterMethod={this.filterMethod}
                    filterable
                    style={{ backgroundColor: "white" }}
                    minRows={8}
                    getTdProps={this.getTableProps}
                  />
                    :
                    <div className="single-row justify-content-center">
                        <div className="modal-content-title">
                        Nothing To Show
                        </div>
                    </div>
                }
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        investmentDet: state.PropertyOwner.investmentDet
    }
  }
const mapDispatchToProps = { getPropertyInvestments };
 
export default connect(mapStateToProps, mapDispatchToProps)(PropertyInvestments);