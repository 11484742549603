import React from 'react';
import createSagaMiddleware from 'redux-saga';
import { render } from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { logger } from 'redux-logger';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";
import rootReducer from './store/reducers';
import App from './App';
import rootSaga from './store/sagas';

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import 'antd/dist/antd.css'
import 'react-table-6/react-table.css'

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "root",
  storage
};

// const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
// const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, logger));
// let persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
render(
  <Provider store={store}>
    {/* <PersistGate persistor={persistor}> */}
      <App />
    {/* </PersistGate> */}
  </Provider>,
  document.getElementById('root'),
);
if (module.hot) { module.hot.accept(App);}
