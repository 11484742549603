import React, { Component } from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import { Button } from "reactstrap";
import EditSectionDetails from "./EditSectionDetails";
import { updateSection } from "../../store/actions/Property";

class SectionDetails extends Component {
  state = {
    showEdit: false,
    isLoading: false
  };

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  handleSubmit = e => {
    console.log("handle submit :: ", e);
    this.setState({ isLoading: true });
    const url = require("url");
    const { query } = url.parse(document.location.href, true);
    this.props.updateSection({ propertyId: query["id"], propertySections: e });
  };

  componentWillReceiveProps(props) {
    this.setState({ showEdit: false });
    this.setState({ isLoading: false });
  }

  render() {
    const { propertyDetails } = this.props;
    const { showEdit } = this.state;

    if (showEdit)
      return (
        <>
          <div hidden={!this.state.isLoading} className="loader-edit-property">
            <p className="loader-edit-property-text">
              {" "}
              <i className="fa fa-spinner fa-spin fa-1x fa-fw"></i> Loading,
              Please Wait...{" "}
            </p>
          </div>
          <EditSectionDetails
            onSubmit={this.handleSubmit}
            propertyDetails={propertyDetails}
          />
        </>
      );
    else
      return (
        <div>
          <div className="property-header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4
                style={{
                  fontWeight: "650",
                  margin: "0px",
                  marginRight: "20px"
                }}
              >
                Sections Details
              </h4>
              <i
                className="fa fa-edit"
                style={{ cursor: "pointer" }}
                onClick={this.handleEdit}
              />
            </div>
          </div>

          {propertyDetails.sections !== null &&
          propertyDetails.sections !== undefined ? (
            <>
              {propertyDetails.sections.map((section, index) => (
                <div className="single-row">
                  <div className="modal-content-title">Section {index + 1}</div>
                  <div className="modal-content-data">
                    {ReactHtmlParser(section)}
                  </div>
                </div>
              ))}
            </>
          ) : null}
        </div>
      );
  }
}

const mapDispatchToProps = { updateSection };
export default connect(null, mapDispatchToProps)(SectionDetails);
