import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getListedProperties } from "../../../store/actions/Auth";

import './listProperty.css'

class ListedProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.getListedProperties()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleListProperty = () => this.props.history.push("/home/list-property")

  handleViews = (e) => window.open(e, '_blank')
  launchSTO = (symbol, id) => this.props.history.push(`/home/launch-sto?symbol=${symbol}&id=${id}`);
  tokenizeProperty = (id, name) => this.props.history.push(`/home/tokenize?id=${id}&name=${name}`);
  viewDetails = (property) => {
    let query = `id=${property['_id']}&status=${property['status']}`;
    if (property['tokenId'] && property['tokenId']['symbol'])
      query = query.concat(`&symbol=${property['tokenId']['symbol']}`)
    this.props.history.push(`/home/property-details?${query}`);
  }

  render() {
    const columns = [{
      Header: 'Title',
      accessor: 'title',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Stage',
      accessor: 'stage',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'NOI %',
      accessor: 'noiPercent',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'City',
      accessor: 'city',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Country',
      accessor: 'country',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: 220,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
            <Button onClick={() => this.viewDetails(props.original)} className="btn-info action-btn action-btn-outline-info">
              View
              </Button>
            {
              props.original.status == 'pending' ?
                <Button onClick={() => this.tokenizeProperty(props.original._id, props.original.title)} className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                  Tokenize
                  </Button>
                : props.original.status == 'listed' ?
                  <Button onClick={() => this.launchSTO(props.original['tokenId']['symbol'], props.original['_id'])} className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                    Launch STO
                  </Button>
                  : props.original.status == 'launched' ?
                    <Button disabled className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                      STO Launched
                  </Button>
                  : props.original.status == 'overview' ?
                    <Button onClick={() => this.props.history.push(`/home/property-sections/?id=${props.original._id}`)} className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                      Add Details
                  </Button>
                  : props.original.status == 'sections' ?
                    <Button onClick={() => this.props.history.push(`/home/property-documents/?id=${props.original._id}`)} className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                      Add Documents
                  </Button>
                  : props.original.status == 'documents' ?
                    <Button onClick={() => this.props.history.push(`/home/tokenize?id=${props.original._id}&name=${props.original.title}`)} className="btn-info action-btn" style={{ minWidth: "110px", background: "#92BF55" }}>
                      Create Token
                  </Button>
                    : null
            }
          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 style={{ marginBottom: "20px" }}>Listed Properties</h2>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12" style={{ display: "flex", justifyContent: 'flex-end' }}>
              <Button onClick={() => this.handleListProperty()} className="add-btn" style={{ background: 'royalblue' }}>
                Add Property
              </Button>
            </div>
          </div>

          {this.props.listedProperties !== ''
            && <ReactTable
              data={this.props.listedProperties}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { listedProperties } = Auth;
  return { listedProperties }
}

const mapDispatchToProps = { getListedProperties };
export default connect(mapStateToProps, mapDispatchToProps)(ListedProperties);
