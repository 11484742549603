import moment from 'moment';
import EventBus from 'eventing-bus';
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getSinglePropertyDetails } from '../../../store/actions/Auth';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { fetchingTokens, fetchedToken, isError, startLoader, stopLoader } from "../../../store/actions/Tokinize";

import './propertyDetail.css';
import PropertyToken from '../../../components/SinglePropertyTabs/PropertyToken';
import PropertyImages from '../../../components/SinglePropertyTabs/PropertyImages';
import SectionDetails from '../../../components/SinglePropertyTabs/SectionDetails';
import PropertyOffering from '../../../components/SinglePropertyTabs/PropertyOffering';
import PropertyOverview from '../../../components/SinglePropertyTabs/PropertyOverview';
import PropertyDocuments from '../../../components/SinglePropertyTabs/PropertyDocuments';
import UserDocuments from '../../../components/SinglePropertyTabs/UserDocuments';

class PropertyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      symbol: '',
      stoTiers: '',
      activeTab: '1',
      stoDetails: '',
      tokenDetails: '',
      propertyDetails: '',
    };
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);

    if (!query['id']) {
      EventBus.publish("error", `Please select property`)
      setTimeout(() => this.props.history.push(`/home/active-listings`), 1000);
    } else this.props.getSinglePropertyDetails(query['id']);
  }

  componentWillReceiveProps({ singlePropertyDetails }) {
    console.log(`**************singlePropertyDetails = `, singlePropertyDetails);

    if (singlePropertyDetails) this.setState({ propertyDetails: singlePropertyDetails['property'], tokenDetails: singlePropertyDetails['token'], stoDetails: singlePropertyDetails['sto'] })
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {
    let { propertyDetails, tokenDetails, stoDetails, activeTab } = this.state;
    return (
      <div className='content'>
        <div className='main-container'>
          <h3 align='center'>
            Property Details
          </h3>
          <hr className='py-3' />

          <Nav tabs>
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}>
                Property Overview
              </NavLink>
            </NavItem>
            {tokenDetails &&
              <NavItem style={{ width: "16%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}>
                  Security Token
                </NavLink>
              </NavItem>
            }
            {stoDetails &&
              <NavItem style={{ width: "16%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}>
                  Token Offering
                </NavLink>
              </NavItem>
            }
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}>
                Property Images
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { this.toggle('5'); }}>
                Section Detail
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { this.toggle('6'); }}>
                Property Documents
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '7' })}
                onClick={() => { this.toggle('7'); }}>
                User Documents
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PropertyOverview {...this.state} />
            </TabPane>
            {tokenDetails &&
              <TabPane tabId="2">
                <PropertyToken {...this.state} />
              </TabPane>
            }
            {stoDetails &&
              <TabPane tabId="3">
                <PropertyOffering {...this.state} />
              </TabPane>
            }
            <TabPane tabId="4">
              <PropertyImages {...this.state} />
            </TabPane>
            <TabPane tabId="5">
              <SectionDetails {...this.state} />
            </TabPane>
            <TabPane tabId="6">
              <PropertyDocuments {...this.state} />
            </TabPane>
            <TabPane tabId="7">
              <UserDocuments {...this.state} />
            </TabPane>
          </TabContent>

          {(propertyDetails !== null && propertyDetails !== undefined && propertyDetails !== '') && <div className="modal-container" />}
        </div>
      </div>
    )
  }
}

const currencies =
  [
    'ETH',
    'POLY',
    'DAI (USD Stablecoin)',
    'Non-USD Stablecoin',
  ];

const mapStateToProps = (state) => {
  let { sdk } = state.Tokenize;
  let { singlePropertyDetails } = state.Auth;
  return { singlePropertyDetails, sdk };
}

const mapDispatchToProps = { getSinglePropertyDetails, fetchingTokens, startLoader, stopLoader, fetchedToken, isError };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDetails)
