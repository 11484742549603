import EventBus from 'eventing-bus';
import classnames from 'classnames';
import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './propertyDetail.css';
import PropertyStoDetails from '../../../components/PropertyOwner/PropertyStoDetails/PropertyStoDetails';
import PropertyWallet from '../../../components/PropertyOwner/PropertyWallet/PropertyWallet';
import PropertyInvestments from '../../../components/PropertyOwner/PropertyInvestments/PropertyInvestments';

class PropertyDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: '1',
    };
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);
    if (!query['id']) {
      EventBus.publish("error", `Please select property`)
      setTimeout(() => this.props.history.push(`/home/active-listings`), 1000);
    }
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  render() {
    let { activeTab } = this.state;
    return (
      <div className='content'>
        <div className='main-container'>
          <h3 align='center'>
            Property Details
          </h3>
          <hr className='py-3' />

          <Nav tabs>
            <NavItem style={{ width: "16%" }}>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}>
                Property Wallet
              </NavLink>
            </NavItem>
              <NavItem style={{ width: "16%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}>
                 Property Investments
                </NavLink>
              </NavItem>
              <NavItem style={{ width: "16%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}>
                  STO Details
                </NavLink>
              </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PropertyWallet {...this.state} />
            </TabPane>
              <TabPane tabId="2">
                <PropertyInvestments {...this.state} />
              </TabPane>
              <TabPane tabId="3">
                <PropertyStoDetails {...this.state} />
              </TabPane>
          </TabContent>
        </div>
      </div>
    )
  }
}

export default PropertyDetails
