import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import EditPropertyOverview from './EditPropertyOverview';
import { updatePropertyOverview } from '../../store/actions/Property';

class PropertyOverview extends Component {
  state = {
    showEdit: false,
  }

  handleEdit = () => this.setState({ showEdit: !this.state.showEdit })

  handleSubmit = (e) => {
    console.log("*********on submit :: ", e);
    let { propertyDetails } = this.props;
    if (propertyDetails && propertyDetails['_id']) e['_id'] = propertyDetails['_id'];

    this.setState({ showEdit: !this.state.showEdit });
    this.props.updatePropertyOverview(e)
  }

  render() {
    const { showEdit } = this.state;
    const { propertyDetails } = this.props;
    
    if (showEdit) return <EditPropertyOverview onSubmit={this.handleSubmit} propertyDetails={propertyDetails} />
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>Property Overview</h4>
              <i className="fa fa-edit" style={{ cursor: "pointer" }} onClick={this.handleEdit} />
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
              Title
            </div>
            <div className="modal-content-data">
              {propertyDetails.title}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Development Stage
            </div>
            <div className="modal-content-data">
              {propertyDetails.stage}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Annual Income
            </div>
            <div className="modal-content-data">
              {propertyDetails.annualIncome}
              <span className='symbol'>
                TKUSD
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Units
            </div>
            <div className="modal-content-data">
              {propertyDetails.units}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              NOI %
            </div>
            <div className="modal-content-data">
              {propertyDetails.noiPercent}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Location
            </div>
            <div className="modal-content-data">
              {propertyDetails.location}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              City
            </div>
            <div className="modal-content-data">
              {propertyDetails.city}
            </div>
          </div>

          {propertyDetails.postalCode &&
            <div className="single-row">
              <div className="modal-content-title">
                Postal Code
              </div>
              <div className="modal-content-data">
                {propertyDetails.postalCode}
              </div>
            </div>
          }

          <div className="single-row">
            <div className="modal-content-title">
              Country
            </div>
            <div className="modal-content-data">
              {propertyDetails.country}
            </div>
          </div>
          {new Date(propertyDetails.webinarDate) > new Date() && 
           <>
             <div className="single-row">
              <div className="modal-content-title">
                Webinar URL
              </div>
              <div className="modal-content-data">
                {propertyDetails.webinarURL}
              </div>
            </div>
          <div className="single-row">
            <div className="modal-content-title">
              Webinar Date
            </div>
            <div className="modal-content-data">
              {moment(propertyDetails.webinarDate).format('lll')}
            </div>
          </div>
          </>
        }

          {new Date(propertyDetails.webinarDate) < new Date() && 
            <div className="single-row">
              <div className="modal-content-title">
                Webinar Video
              </div>
              <div className="modal-content-data">
                {propertyDetails.videoURL}
              </div>
            </div>
          }
        </div>
      )
  }
}

const mapDispatchToProps = { updatePropertyOverview };
export default connect(null, mapDispatchToProps)(PropertyOverview);