/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import React, { Component } from "react";
import ReactHtmlParser from "react-html-parser";
import { Button } from "reactstrap";
import RichTextEditor from "react-rte";
import ReactQuill from 'react-quill';

class EditSectionDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // sections: { value1: ReactQuill },
      propertyDetails: props.propertyDetails.sections || ['<p></p>'],
    };
  }

  componentWillMount() {
    this.mapData()
  }

  modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }

  mapData=() => {
    const { propertyDetails } = this.state;

    let sec = { ...this.state.sections };
    {
      propertyDetails !== null && propertyDetails !== undefined ? (
        <>
          {propertyDetails.forEach((section, index) => {
            sec[`value${index + 1}`] = RichTextEditor.createValueFromString(
              section,
              "html"
            );
            this.setState({ sections: sec });
          })}
        </>
      ) : null;
    }
  }

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  onChangeEditor = (value, name, index) => {
    const { sections, propertyDetails } = this.state;
    sections[name] = value;
    propertyDetails[index] = sections[name].toString("html")
    this.setState({ sections, propertyDetails }, () => {
      console.log("sections :: ", sections, sections[name].toString("html"));
    });
  };

  appendInput() {
    let sec = { ...this.state.sections };
    sec[`value${this.state.propertyDetails.length+1}`] = RichTextEditor.createEmptyValue();

    this.setState({ sections: sec }, () => {
      var newInput = `input-${this.state.propertyDetails.length + 1}`;
      this.setState(prevState => ({
        propertyDetails: prevState.propertyDetails.concat([newInput])
      }));

        this.setState({
          propertyDetails: this.state.propertyDetails.concat('<p></p>')
        });
    });
  }

  handleRemove(e) {
    let propertyDetailsArray = this.state.propertyDetails;
    propertyDetailsArray.splice(e, 1);
    this.setState({ propertyDetails: propertyDetailsArray },()=>{
      console.log("after delete :: ", this.state);
    });
    this.mapData()
  }

  render() {
    const { propertyDetails } = this.state;
    const { showEdit } = this.state;
    console.log("render state :: ", this.state);

    return (
      <div>
        <div className="property-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              style={{ fontWeight: "650", margin: "0px", marginRight: "20px" }}
            >
              Edit Sections Details
            </h4>
          </div>
          {/* <Button onClick={()=>this.props.onSubmit(this.state.sections)}> Add New Section </Button> */}
          <Button onClick={() => this.props.onSubmit(this.state.propertyDetails)}>
            {" "}
            Save{" "}
          </Button>
          <Button className="btn-info" onClick={() => this.appendInput()}>
            Add New Section
          </Button>
        </div>

        {propertyDetails !== null && propertyDetails !== undefined ? (
          <>
            {propertyDetails.map((section, index) => (
              <div className="single-row">
                <div className="modal-content-title">Section {index + 1}</div>
                <div className="modal-content-data">
                  {console.log('$$$$ section ', section)}
                  {/* {section} */}
                  <div className="col-md-11">
                    <ReactQuill
                       name={`editor${this.state.propertyDetails.length}`}
                       id={`editor${this.state.propertyDetails.length}`}
                       key={`value${index + 1}`}
                       modules={this.modules}
                       value={section}
                       onChange={value =>
                         this.onChangeEditor(value, `value${index + 1}`, index)
                       }
                     />
                   {
                     /*
                     <RichTextEditor
                       name={`editor${this.state.propertyDetails.length}`}
                       id={`editor${this.state.propertyDetails.length}`}
                       key={`value${index + 1}`}
                       value={this.state.sections[`value${index + 1}`]}
                       onChange={value =>
                         this.onChangeEditor(value, `value${index + 1}`, index)
                       }
                     />
                     */
                   }

                  </div>
                  <div className="col-md-1">
                    <button
                      className="btn btn-danger ml-auto iconButton"
                      onClick={() => this.handleRemove(index)}
                    >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        ) : null}
      </div>
    );
  }
}

export default EditSectionDetails;
