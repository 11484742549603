import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { updatePropertyOverview } from '../../store/actions/Property';

class PropertyToken extends Component {

  render() {
    const { tokenDetails } = this.props;
    console.log(`************this.props `, this.props);

    return (
      <div className='property-overview'>
        <div className='property-header'>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>Token Details</h4>
          </div>
        </div>
        <div className="single-row">
          <div className="modal-content-title">
            Symbol
          </div>
          <div className="modal-content-data">
            {tokenDetails.symbol}
          </div>
        </div>

        {tokenDetails.name &&
          <div className="single-row">
            <div className="modal-content-title">
              Token Name
            </div>
            <div className="modal-content-data">
              {tokenDetails.name}
            </div>
          </div>
        }

        {tokenDetails.supply &&
          <div className="single-row">
            <div className="modal-content-title">
              Total Tokens Supply
            </div>
            <div className="modal-content-data">
              {tokenDetails.supply}
            </div>
          </div>
        }

        <div className="single-row">
          <div className="modal-content-title">
            Created At
          </div>
          <div className="modal-content-data">
            {moment(tokenDetails['createdAt']).format('lll')}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Network
          </div>
          <div className="modal-content-data">
            {tokenDetails.network}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Contract Address
          </div>
          <div className="modal-content-data">
            <a href={tokenDetails.explorer + 'address/' + tokenDetails.contractAddress} target='_blank'>
              {tokenDetails.contractAddress}
            </a>
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Created By Manager Wallet
          </div>
          <div className="modal-content-data">
            <a href={tokenDetails.explorer + 'address/' + tokenDetails.fromWallet} target='_blank'>
              {tokenDetails.fromWallet}
            </a>
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Admin Wallet
          </div>
          <div className="modal-content-data">
            <a href={tokenDetails.explorer + 'address/' + tokenDetails.adminWallet} target='_blank'>
              {tokenDetails.adminWallet}
            </a>
          </div>
        </div>

        {tokenDetails.transactionHash &&
          <div className="single-row">
            <div className="modal-content-title">
              Transaction Hash
            </div>
            <div className="modal-content-data">
              <a href={(tokenDetails.explorer + 'tx/' + tokenDetails.transactionHash)} target='_blank'>
                {tokenDetails.transactionHash}
              </a>
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = { updatePropertyOverview };
export default connect(null, mapDispatchToProps)(PropertyToken);