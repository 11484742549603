import { connect } from "react-redux";
import React, { Component } from "react";
import { Button } from "reactstrap";
import { deleteImage, uploadCarousel } from "../../store/actions/Property";
import Files from "react-files";

class PropertyDocuments extends Component {
  state = {
    showEdit: false,
    isLoading: false
  };

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  onUpload = e => {
    console.log("doc uploaded :: ", e);
    this.setState({ isLoading: true });
    let data = new FormData();
    data.append("propertyId", this.props.propertyDetails["_id"]);
    e.forEach(i => {
      data.append("files", i);
    });
    data.append("type", "documents");
    this.props.uploadCarousel(data);
  };

  handleRemoveDoc = async (e, type) => {
    console.log("deleted req for :: ", e, this.props.propertyDetails["_id"]);
    this.setState({ isLoading: true });
    let data = {
      _id: this.props.propertyDetails["_id"],
      location: e,
      type: "documents"
    };
    this.props.deleteImage(data);
  };

  componentWillReceiveProps(props) {
    this.setState({ isLoading: false });
  }

  render() {
    const { propertyDetails } = this.props;
    const { showEdit } = this.state;

    return (
      <div>
        <div hidden={!this.state.isLoading} className="loader-edit-property">
          <p className="loader-edit-property-text"> <i className='fa fa-spinner fa-spin fa-1x fa-fw'></i> Loading, Please Wait... </p>
        </div>
        <div className="property-header">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%"
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4
                style={{
                  fontWeight: "650",
                  margin: "0px",
                  marginRight: "20px"
                }}
              >
                Property Documents
              </h4>
              <i
                className="fa fa-edit"
                style={{ cursor: "pointer" }}
                onClick={this.handleEdit}
              />
            </div>
            <div hidden={!showEdit}>
              <Button onClick={this.handleEdit}> Done </Button>
            </div>
          </div>
        </div>
        <div hidden={!showEdit}>
          <Files
            onChange={e => this.onUpload(e)}
            style={{ marginBottom: "10px" }}
            onError={this.onFilesError}
            accepts={[".pdf"]}
            className="dropZoneEdit"
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <div className="activities-form-cardimage-dropzone-text">
              Upload Documents
            </div>
          </Files>
        </div>
        {propertyDetails && Array.isArray(propertyDetails.documents) &&
          propertyDetails.documents.map(item => {
            return (
              <div className="single-row">
                <div className="modal-content-title" style={{ width: "80%" }}>
                  <a href={item.location}> {item.name} </a>
                </div>
                <button
                  hidden={!showEdit}
                  onClick={() => {
                    this.handleRemoveDoc(item.location);
                  }}
                  style={{ fontSize: "14px", height: "45px" }}
                  className="btn btn-danger ml-auto iconButton"
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            );
          })}
      </div>
    );
  }
}
const mapDispatchToProps = { deleteImage, uploadCarousel };

const mapStateToProps = state => {
  let { singlePropertyDetails } = state.Auth;
  return { propertyDetails: singlePropertyDetails['property'] };
};

export default connect(mapStateToProps, mapDispatchToProps)(PropertyDocuments);
