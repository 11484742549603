import { connect } from "react-redux";
import React, { Component } from 'react';
import { getPropertyOwnerWallet } from '../../../store/actions/PropertyOwner';

class PropertyWallet extends Component {
  state = {
  }

  componentDidMount(){
    const url = require('url');
    const { query } = url.parse(document.location.href, true);
    this.props.getPropertyOwnerWallet(query['id'])
  }
  
  render() {
    const { propertyAddresses } = this.props;
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>Property Wallet Addresses</h4>
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
            Property Owner
            </div>
            <div className="modal-content-data">
              {propertyAddresses.propertyOwner}
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
            Property Wallet
            </div>
            <div className="modal-content-data">
              {propertyAddresses.propertyWallet}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
            TKUSD
            </div>
            <div className="modal-content-data">
              {propertyAddresses.TKUSD}
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
            Property Token
            </div>
            <div className="modal-content-data">
              {propertyAddresses.propertyToken}
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
            Sto Address
            </div>
            <div className="modal-content-data">
              {propertyAddresses.stoAddress}
            </div>
          </div>
        </div>
      )
  }
}
const mapStateToProps = (state) => {
    let { propertyAddresses } = state.PropertyOwner;
    return { propertyAddresses };
}

const mapDispatchToProps = { getPropertyOwnerWallet };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyWallet);