import axios from 'axios';
import EventBus from 'eventing-bus';
import { put, all, takeEvery, call, takeLeading } from 'redux-saga/effects';

function* addNewRole({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/admin/manager`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    EventBus.publish("success", response.data.message);
    setTimeout(() => history.push(`/home/list-manager`), 1000);
  };
  yield put({ type: 'SHOW_LOADER', payload: false });
}

function* getAllRoles() {
  const { error, response } = yield call(getCall, '/admin/manager');
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'GET_ROLE_RESPONSE', payload: response.data.body })
}

function* onDeleteRole({ payload }) {
  // const { error, response } = yield call(deleteCall, `/blog/${payload}`);
  // if (error) EventBus.publish("error", error.response.data.message)
  // else if (response)
  // {
  //   EventBus.publish("success", response['data']['message'])
  //   yield put({ type: 'GET_ALL_BLOGS' })
  // }
}


function* actionWatcher() {
  yield takeEvery('ADD_NEW_ROLE', addNewRole)
  yield takeEvery('GET_ALL_ROLES', getAllRoles)
  yield takeEvery('DELETE_ROLE', onDeleteRole)
}

function postCall({ path, payload }) {
  return axios.post(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401) console.log(`**********Please Logout`);
      return ({ error })
    });
}

function getCall(path) {
  return axios.get(path)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401) console.log(`***********Please Logout`);
      return ({ error })
    });
}

function deleteCall(path) {
  return axios.delete(path)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401) console.log(`***********Please Logout`);
      return ({ error })
    });
}

function putCall({ path, payload }) {
  return axios.put(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401) console.log(`**********Please Logout`);
      return ({ error })
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
