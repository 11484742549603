const { web3 } = require('../web3');

console.log('******* env', process['env']['REACT_APP_SERVER']);
const STData = require(`./${process['env']['REACT_APP_SERVER']}/ST`);
const STOData = require(`./${process['env']['REACT_APP_SERVER']}/STO`);
const TkusdData = require(`./${process['env']['REACT_APP_SERVER']}/TKUSD`);
const ExchangeData = require(`./${process['env']['REACT_APP_SERVER']}/Exchange`);
const WhitelistData = require(`./${process['env']['REACT_APP_SERVER']}/Whitelist`);

const networks = {
  0: 'Disconnected',
  1: 'Mainnet',
  4: 'Rinkeby',
  42: 'Kovan',
}

// const networkId = 42;
// const network = networks[networkId];

const { REACT_APP_NETWORK_ID: networkId, REACT_APP_NETWORK: network, REACT_APP_OWNER_ADDRESS } = process['env'];
console.log(`*****************network = `, network);

const STABI = STData['ABI'];
const ST = new web3.eth.Contract(STABI);

const STOABI = STOData['ABI'];
const STO = new web3.eth.Contract(STOABI);

const ExchangeABI = ExchangeData['ABI'];
const Exchange = new web3.eth.Contract(ExchangeABI);

const TKUSDABI = TkusdData['ABI'];
const TKUSDAddress = TkusdData[network]['Address'];
const TKUSD = new web3.eth.Contract(TKUSDABI, TKUSDAddress);

const WhitelistABI = WhitelistData['ABI'];
const WhitelistAddress = WhitelistData[network]['Address'];
const Whitelist = new web3.eth.Contract(WhitelistABI, WhitelistAddress);

// const Owner = TkusdData['Owner'];
const Owner = REACT_APP_OWNER_ADDRESS;

const TKInfo = TkusdData['info'];
const TKByteCode = TkusdData['ByteCode'];

module.exports = {
  STOData, STData, ExchangeData,
  networkId, network, Owner,
  STO, ST, Exchange, ExchangeABI,
  Whitelist, WhitelistAddress, WhitelistABI,
  TKInfo, TKUSD, TKUSDABI, TKUSDAddress, TKByteCode,
};
