import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    ModalFooter
} from "reactstrap";
import { getAllBlogs, onDeleteBlog, uploadAgreement } from "../../../store/actions/Auth";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'
import moment from 'moment';
import './blogs.css'

class Blogs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            SingleRowData: null,
            agreementFile: ''
        };

        props.getAllBlogs()
    }

    filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id;
        console.log(String(moment(row[id]).format('MM DD YYYY')))
        return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) || String(moment(row[id]).format('MM DD YYYY')).includes(filter.value) : true
    }

    toggle = () => {
        this.setState({ showModal: !this.state.showModal, agreementFile: '' })
    }

    getTableProps = (state, rowInfo, col, instance) => ({
        onClick: (e, cb) => {
            if (rowInfo !== undefined) {
                console.log("rowData", rowInfo.original);
                this.setState({ SingleRowData: rowInfo.original })
            }
        }
    })

    onDelete = (e) => {
      console.log('e is', e);
        this.props.onDeleteBlog(e)
    }

    handleUpload = (e) => {
        this.setState({ agreementFile: e.target.files })
    }

    handleUploadFile = (e) => {
        let data = new FormData();
        for (let i = 0; i < this.state.agreementFile.length; i++) {
            data.append('document', this.state.agreementFile[i]);
            if (this.state.agreementFile.length-1 == i) {
                this.props.uploadAgreement(data)
            }
        }
    }

    handleNewBlog = ()=> this.props.history.push("/home/new-blog")

    componentWillReceiveProps(props) {
        if (props.buttonLoader === false)
            this.setState({ showModal: false })
    }

    editBlog = (blog)=>
    {
      console.log('blog', blog);
      let query = `id=${blog['_id']}`;

      this.props.history.push(`/home/edit-blog?${query}`);
    }


    render() {
        const columns = [
            {
                Header: 'Title',
                accessor: 'title',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Intro',
                accessor: 'intro',
                style: { textAlign: 'center' },
            },
            {
                Header: 'Created At',
                accessor: 'createdAt',
                style: { textAlign: 'center' },
                Cell: props => {
                    return (
                        <span style={{ display: "flex" }}>
                            {moment(props.original.createdAt).format('MMM Do YYYY')}
                        </span>
                    )
                }
            },
            {
                Header: '',
                width: 180,
                minWidth: 120,
                maxWidth: 250,
                sortable: false,
                filterable: false,
                resizable: false,
                Cell: props => {
                    return (
                        <span style={{ display: "flex" }}>
                            <a href={props.original.location} download>
                                <Button className="btn-info action-btn" onClick={()=>this.editBlog(props.original)} style={{ minWidth: "80px", background: "#92BF55" }}>
                                    Edit
            </Button>
                            </a>
                            <Button onClick={() => this.onDelete(props.original._id)} className="btn-info action-btn action-btn-outline-info">
                                Delete
              </Button>
                        </span>
                    )
                }
            }
        ]
        const { SingleRowData } = this.state;
        return (
            <div className="content">
                <div className="main-container">
                    <Modal isOpen={this.state.showModal} toggle={this.toggle}>
                        <ModalHeader toggle={this.toggle}></ModalHeader>
                        <h3 style={{ margin: "0px 0px 0px 25px" }}>Upload Agreement</h3>
                        <ModalBody>
                            <div className="download-row">
                                <Input
                                    type="file"
                                    multiple
                                    accept=".pdf,.docs"
                                    onChange={this.handleUpload}
                                    name="document"
                                />
                            </div>
                            <hr />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleUploadFile}>
                                {
                                    this.props.buttonLoader
                                        ? 'Uploading...'
                                        : 'Upload'
                                }
                            </Button>
                            <Button color="info" onClick={this.toggle}>Close</Button>
                        </ModalFooter>
                    </Modal>
                    <div className="agreement-header">
                        <h2 style={{ marginBottom: "20px" }}>Blogs</h2>
                        <Button onClick={()=>this.handleNewBlog()} className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55", width: "160px" }}>
                            Add Blog
                        </Button>
                    </div>
                    {this.props.blogs !== ''
                        ? <ReactTable
                            data={this.props.blogs}
                            columns={columns}
                            defaultFilterMethod={this.filterMethod}
                            filterable
                            style={{ backgroundColor: "white" }}
                            minRows={8}
                            getTdProps={this.getTableProps}
                        />
                        : ''
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    console.log("Blogs state :: ", state)
    return {
        blogs: state.Auth.blogs,
        buttonLoader: state.Auth.buttonLoader
    }
}

const mapDispatchToProps =
{
    onDeleteBlog: onDeleteBlog,
    getAllBlogs: getAllBlogs,
    uploadAgreement: uploadAgreement
};

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
