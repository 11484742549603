import Files from "react-files";
import { Button } from "reactstrap";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import React, { Component } from "react";
import { MenuItem, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { startLoader, stopLoader } from "../../../../store/actions/Tokinize";
import { ListNewPropertyFn, showLoader, getSinglePropertyRequest } from "./../../../../store/actions/Auth";
import { propertyStep1, getPropertyOwner } from "../../../../store/actions/Property";
import MapView from "../MapView";
import Geocode from "react-geocode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import "./listNewPropertySteps.css";

const AnyReactComponent = ({ text }) => <div>{text}</div>;
var url = require("url");
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_KEY);
class Step1 extends Component {
  // static defaultProps = {
  //   center: {
  //     lat: 59.95,
  //     lng: 30.33
  //   },
  //   zoom: 11
  // };

  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      step: 1,
      locations: [],
      lat:59.95,
      lng:30.33,
      search:'',
      isTitle:true,
      formData: {
        title: "",
        stage: "",
        categories: "",
        location: "",
        country: 'United States',
        postalCode: "", // Add This
        units: "",
        noiPercent: "",
        city: "",
        requestId: "",
        description: "",
        lat: "",
        lng: "",
        webinarURL: "",
        webinarDate: new Date(),
        state:"",
        projectCost:"",
        ownerId:""
      },
      propertyDescriptionError: false,
      inputs: ["input-0"],
      passwordValid: true,
      sections: { value1: RichTextEditor.createEmptyValue() }
    };
    this.handleMapClick = this.handleMapClick.bind(this);
    props.getPropertyOwner()
  }

  componentDidMount() {
    const urlObj = url.parse(document.location.href, true);
    if (
      urlObj.query.id !== undefined &&
      urlObj.query.id !== null &&
      urlObj.query.id !== ""
    ) {
      this.state.formData["requestId"] = urlObj.query.id;
      this.props.getSinglePropertyRequest(urlObj.query.id);
    }
    ValidatorForm.addValidationRule('isOnlySpecialCharater', (value) => {
      var pattern = /^[^a-zA-Z0-9]+$/;
      return (!pattern.test(value))
    })
    ValidatorForm.addValidationRule('validURL', (str) => {
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    })
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.singlePropertyRequest)
      this.setSinglePropertyRequest(nextProps.singlePropertyRequest);
  }

  handleMapClick = (ref, map, ev) => {
    const location = ev.latLng;
    // this.setState(prevState => ({
    //   locations: [...prevState.locations, location]
    // }));
    console.log('latlng received', this.state.lat, this.state.lng);
    this.setState({locations:location, lat:location.lat(), lng:location.lng()})
    map.panTo(location);
  };

  setSinglePropertyRequest = e => {
    const { formData } = this.state;
    formData["city"] = e.city;
    formData["type"] = e.type;
    formData["country"] = e.country;
    formData["location"] = e.address;
    this.setState({ formData });
  };

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  handleFormChange = event => {
    console.log(event.target.value);
    this.setState({ propertyDescriptionError: false });
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    console.log("state :: ", this.state);

    this.setState({ submitted: true });

    if (this.state.formData.description === "") {
      this.setState({ propertyDescriptionError: true });
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Property Description required"
      });
    }else {
      this.props.showLoader(true);
      let { formData } = this.state;
      let data = formData;
      console.log('formData', data);
      this.props.propertyStep1({ data, history: this.props.history });
      this.props.startLoader(`Uploading Property Data`);

      // this.props.ListNewPropertyFn({ data, history: this.props.history });
    }
    setTimeout(() => this.setState({ submitted: false }), 4000);
  };

  handleApiLoaded = (map, maps) => {
    console.log('map, maps', map, maps);
  // use map and maps objects
  };

  moveMarker = (ref, map, ev) => {
    const location = ev.latLng;
    this.setState(prevState => ({
      locations: [...prevState.locations, location]
    }));
    map.panTo(location);
  };

  handleLocationChange =(lat, lng)=>{
    console.log(lat, lng);
    const { formData } = this.state;
    formData['lat'] = lat;
    formData['lng'] = lng;
    this.setState({ formData });
  }

  searchLocation=()=>{
  console.log('location to search', this.state.search);
  Geocode.fromAddress(this.state.search).then(
    response => {
      const { lat, lng } = response.results[0].geometry.location;
      if(lat && lng){
        const { formData } = this.state;
        formData['lat'] = lat;
        formData['lng'] = lng;
        this.setState({ formData });
      }
    },
    error => {
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Cannot Find This Location"
      });
      console.error('error', error);
    }
  );
  }

  handleDate = date => {
    const { formData } = this.state;
    formData['webinarDate'] = date;
    this.setState({ formData });
  };


  render() {
    return (
      <div className="content">
        <div className="main-container list-new">
        <h2 className="mb-5 pb-3">List New Property</h2>
          <ValidatorForm
            className="validatorListForm"
            onSubmit={this.handleSubmit}
          >
            {this.state.step == 1 && (
              <>
                <Grid container justify="space-around" spacing={3}>
                <Grid item xs={6}>
                    <TextValidator
                      select
                      type="text"
                      name="ownerId"
                      label="Property Owner"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={this.handleFormChange}
                      value={this.state.formData.ownerId}
                      errorMessages={["Owner can not be empty"]}
                    >
                    {/* <MenuItem>
                      <TextValidator
                        name="state"
                        type="text"
                        margin="dense"
                        variant="outlined"
                        className="MyTextField"
                        onChange={this.handleFormChange}
                      />
                    </MenuItem> */}
                      {this.props.propertyOwners.length > 0 && this.props.propertyOwners.map( item => {
                        return(
                          <MenuItem
                          value={item._id}
                        >
                          {item.firstName}
                        </MenuItem>
                        )
                      })}
                    </TextValidator>
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="text"
                      name="title"
                      label="Title"
                      margin="dense"
                      error={!this.state.isTitle}
                      variant="outlined"
                      onChange={this.handleFormChange}
                      value={this.state.formData.title}
                      className="MyTextField mx-auto col-md-10"
                      validators={["required",'isOnlySpecialCharater']}
                      errorMessages={["Title can not be empty","Should not contain only special chrachters"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="Zip Code"
                      name="postalCode"
                      type="number"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      onChange={this.handleFormChange}
                      value={this.state.formData.postalCode}
                      validators={["required", "matchRegexp:^[0-9]*$"]}
                      errorMessages={[
                        "Postal Code can not be empty",
                        "Invalid Postal Code"
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="State"
                      name="state"
                      type="text"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      onChange={this.handleFormChange}
                      value={this.state.formData.state}
                      validators={["required",'matchRegexp:^[A-Za-z\\s]+$']}
                      errorMessages={[
                        "State can not be empty"
                        ,"Special Chrachters and Numbers are not allowed"
                      ]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      select
                      type="text"
                      margin="dense"
                      name="categories"
                      label="Category"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={this.handleFormChange}
                      value={this.state.formData.categories}
                      errorMessages={["Category can not be empty"]}
                    >
                      <MenuItem
                        name="categories"
                        onChange={this.handleFormChange}
                        value="Residential"
                      >
                        Residential
                      </MenuItem>
                      <MenuItem
                        name="categories"
                        onChange={this.handleFormChange}
                        value="Multifamily"
                      >
                        Multifamily
                      </MenuItem>
                      <MenuItem
                        name="categories"
                        onChange={this.handleFormChange}
                        value="Office"
                      >
                        Office
                      </MenuItem>
                      <MenuItem
                        name="categories"
                        onChange={this.handleFormChange}
                        value="Mixed Use"
                      >
                        Mixed Use
                      </MenuItem>
                      <MenuItem
                        name="categories"
                        onChange={this.handleFormChange}
                        value="Warehouse | Industrial"
                      >
                        Warehouse | Industrial
                      </MenuItem>
                    </TextValidator>
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      name="city"
                      type="text"
                      label="City"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required",'matchRegexp:^[A-Za-z\\s]+$']}
                      onChange={this.handleFormChange}
                      value={this.state.formData.city}
                      errorMessages={["City can not be empty","Special Chrachters and Numbers are not allowed"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      type="text"
                      margin="dense"
                      name="country"
                      label="Country"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required",'matchRegexp:^[A-Za-z\\s]+$']}
                      onChange={this.handleFormChange}
                      value={this.state.formData.country}
                      errorMessages={["Country can not be empty","Special Chrachters and Numbers are not allowed"]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="number"
                      margin="dense"
                      name="lat"
                      variant="outlined"
                      className="MyTextField"
                      label="Latitude"
                      onChange={this.handleFormChange}
                      value={this.state.formData.lat}
                      validators={["required"]}
                      errorMessages={["Latitude can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="Longitude"
                      type="number"
                      margin="dense"
                      name="lng"
                      variant="outlined"
                      className="MyTextField"
                      onChange={this.handleFormChange}
                      value={this.state.formData.lng}
                      validators={["required"]}
                      errorMessages={["Longitude can not be empty"]}
                    />
                  </Grid>


                  {
                    /*
                    <Grid item xs={12}>
                      <div style={{ height: '100vh', width: '100%' }}>
                        <GoogleMapReact
                          defaultCenter={this.props.center}
                          defaultZoom={this.props.zoom}
                          yesIWantToUseGoogleMapApiInternals
                          onGoogleApiLoaded={({ map, maps }) => this.handleApiLoaded(map, maps)}
                          >
                          <Marker
                            lat={59.955413}
                            lng={30.337844}
                            text="My Marker"
                            />
                        </GoogleMapReact>
                      </div>
                    </Grid>
                    */
                  }

                  <Grid item xs={6}>
                    <TextValidator
                      select
                      type="text"
                      name="stage"
                      label="Stage"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      validators={["required"]}
                      onChange={this.handleFormChange}
                      value={this.state.formData.stage}
                      errorMessages={["Stage can not be empty"]}
                    >
                      <MenuItem
                        name="stage"
                        onChange={this.handleFormChange}
                        value="Stabilized Income"
                      >
                        Stabilized Income
                      </MenuItem>
                      <MenuItem
                        name="stage"
                        onChange={this.handleFormChange}
                        value="Re Developed Projects"
                      >
                        Re Developed Projects
                      </MenuItem>
                      <MenuItem
                        name="stage"
                        onChange={this.handleFormChange}
                        value="Ground Up Development"
                      >
                        Ground Up Development
                      </MenuItem>
                    </TextValidator>
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="number"
                      margin="dense"
                      name="projectCost"
                      variant="outlined"
                      className="MyTextField"
                      label="Project Cost"
                      onChange={this.handleFormChange}
                      value={this.state.formData.projectCost}
                      validators={["required"]}
                      errorMessages={["Project Cost can not be empty"]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="text"
                      margin="dense"
                      name="search"
                      label="Search Your Area"
                      variant="outlined"
                      className="MyTextField col-md-10"
                      onChange={this.handleChange}
                      value={this.state.search}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <Button
                      className="btn-info col-4"
                      type="button"
                      onClick={() => this.searchLocation()}
                    >
                      Search
                    </Button>
                  </Grid>


                  <Grid item xs={12}>
                    <MapView onChangeValue={this.handleLocationChange} lat={this.state.formData.lat} lng={this.state.formData.lng}/>
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      type="url"
                      margin="dense"
                      name="webinarURL"
                      label="Webinar URL"
                      variant="outlined"
                      validators={["required",'validURL']}
                      className="MyTextField col-md-10"
                      onChange={this.handleFormChange}
                      value={this.state.formData.webinarURL}
                      errorMessages={["Video URL can not be empty",'Invalid URL']}
                    />
                  </Grid>
                  <Grid item xs={6}>
                  <DatePicker
                      className="MyTextField col-md-10"
                      onChange={this.handleDate}
                      selected={this.state.formData.webinarDate}
                      placeholderText="Webinar date"
                      showTimeSelect
                      dateFormat="Pp"
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <TextValidator
                      type="text"
                      margin="dense"
                      name="location"
                      label="Location"
                      variant="outlined"
                      validators={["required",'isOnlySpecialCharater']}
                      className="MyTextField col-md-10"
                      onChange={this.handleFormChange}
                      value={this.state.formData.location}
                      errorMessages={["Location can not be empty","Should not contain only special chrachters"]}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      rowsMax="4"
                      label="Property Description"
                      validators={["required"]}
                      className="MyTextField col-md-10"
                      onChange={this.handleFormChange}
                      value={this.state.formData.description}
                      error={this.state.propertyDescriptionError}
                      helperText={
                        this.state.propertyDescriptionError
                          ? "Property Description can not be empty"
                          : ""
                      }
                      errorMessages={['Property Description can not be empty']}
                      multiline
                      rows="4"
                      variant="outlined"
                      type="text"
                      name="description"
                    />
                  </Grid>

                  <hr />
                  <h4 className="divider-heading pt-3 pb-2">
                    Property Stats
                  </h4>

                  <Grid item xs={6}>
                    <TextValidator
                      type="number"
                      margin="dense"
                      name="deptSwap"
                      variant="outlined"
                      className="MyTextField"
                      label="Debit Ratio"
                      onChange={this.handleFormChange}
                      value={this.state.formData.deptSwap}
                      validators={["required", "maxNumber:100"]}
                      errorMessages={[
                        "Debit Ratio can not be empty",
                        "cannot exceed from 100"
                      ]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="NOI $"
                      type="number"
                      margin="dense"
                      name="noiPercent"
                      variant="outlined"
                      className="MyTextField"
                      onChange={this.handleFormChange}
                      value={this.state.formData.noiPercent}
                      validators={["required"]}
                      errorMessages={["NOI can not be empty"]}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextValidator
                      type="number"
                      margin="dense"
                      name="tagetedROR"
                      variant="outlined"
                      className="MyTextField"
                      label="Targeted Rate of Return"
                      onChange={this.handleFormChange}
                      value={this.state.formData.tagetedROR}
                      validators={["required"]}
                      errorMessages={["Targeted ROR can not be empty"]}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextValidator
                      label="Units"
                      name="units"
                      type="number"
                      margin="dense"
                      variant="outlined"
                      className="MyTextField"
                      onChange={this.handleFormChange}
                      value={this.state.formData.units}
                      validators={["required"]}
                      errorMessages={["Units can not be empty"]}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            <div className='row'>
              <div className='col-6'>
              </div>
              <div className='col-6 text-right'>
              <Button
                className="btn-info col-4"
                type="submit"
              >
                Next
              </Button>
              </div>
            </div>

          </ValidatorForm>
          </div>
          </div>
    );
  }
}

const mapStateToProps = ({ Auth, Property }) => {
  let { singlePropertyRequest, listedProperties, buttonLoader } = Auth;
  let { propertyOwners } = Property;
  return { singlePropertyRequest, listedProperties, buttonLoader, propertyOwners };
};

const mapDispatchToProps = { showLoader, ListNewPropertyFn, propertyStep1, startLoader, stopLoader, getSinglePropertyRequest, getPropertyOwner };
// export default connect(mapStateToProps, mapDispatchToProps)(GoogleApiWrapper({
//   apiKey: 'AIzaSyAnBQj18m0VPF6D28H5PJ_inTfHO0SGi6E'
// })(Step1));
export default connect(mapStateToProps, mapDispatchToProps)(Step1);
