import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { uploadWithHoldingTax, editUserInfo, sendTkusd } from "./../../store/actions/Auth";
import { TKUSDAddress, ST, WhitelistAddress, WhitelistABI, network } from './../../store/contract';
import { web3 } from './../../store/web3';

import { startLoader, stopLoader } from "../../store/actions/Tokinize";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import NumberFormat from 'react-number-format';
import EventBus from 'eventing-bus';

import './userTabs.css';
class SendTkusd extends Component {
  state = {
    showEdit: false,
    taxWitholding: "",
    showModalWithholding:false,
    showModalUserInfo:false,
    showModalSuspendUser:false,
    isValidCity:true,
    isValidZip:true,
    isValidAddress:true,
    firstName:'',
    lastName:'',
    state:'',
    city:'',
    address:'',
    zipCode:'',
    securityNumber:'',
    activeUser:false,
    publicAddress:'',
    firstLoad:true,
    sendTkusd:''
  }

  componentDidMount (){
    console.log('in componentDidMount');
    // setTimeout( () => {
    // }, 15000);

  }

  handleChange = (e) => {
      this.setState({[e.target.name]:e.target.value})
  }

  componentWillReceiveProps(props) {

  }

  handleSubmit =() =>{
    // let web3 = new Web3(window.web3)
    console.log('this.state.sendTkusd', this.state.sendTkusd);
    let data = {
      description:this.state.description,
      amount:this.state.sendTkusd,
      userId:this.props.userDetails._id
    }
    this.props.startLoader("Minting Tokens")
    this.props.sendTkusd({data})
    this.setState({
      description:"",
      sendTkusd:"",
    })
  }

  render() {
    const { showEdit } = this.state;
    const { userDetails } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************userDetails `, userDetails);

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>Send TKUSD</h4>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg="3">
            </Col>

            <Col lg="6">
              <Card className="card-chart text-center">
                <CardHeader>
                  <CardTitle tag="h2">
                    Send TKUSD
                  </CardTitle>
                </CardHeader>
                <CardBody className="mt-3">
                  <Row>
                    <ValidatorForm
                      className="validatorListForm ml-3"
                      onSubmit={this.handleSubmit}
                      >
                      <Row>
                        <Col lg="12">
                          <TextValidator
                            label="Description"
                            name="description"
                            type="text"
                            margin="dense"
                            variant="outlined"
                            className="MyTextField mb-3"
                            onChange={this.handleChange}
                            value={this.state.description}
                            validators={["required"]}
                            fullWidth
                            errorMessages={[
                              "Description empty"
                              ,"Special Chrachters and Numbers are not allowed"
                            ]}
                            />
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <TextValidator
                            label="Amount"
                            name="sendTkusd"
                            type="number"
                            margin="dense"
                            variant="outlined"
                            className="MyTextField ml-3"
                            onChange={this.handleChange}
                            value={this.state.sendTkusd}
                            validators={["required"]}
                            errorMessages={[
                              "Amount empty"
                              ,"Special Chrachters and Numbers are not allowed"
                            ]}
                            />
                        </Col>
                        <Col lg="6">
                          <Button
                            className="btn-info"
                            type="submit"
                            >
                            Send TKUSD
                          </Button>
                        </Col>
                      </Row>
                    </ValidatorForm>
                  </Row>

                </CardBody>
              </Card>
            </Col>

          </Row>

        </div>

      )
  }
}

const mapDispatchToProps = {
  uploadWithHoldingTax,
  editUserInfo,
  startLoader,
  stopLoader,
  sendTkusd
};

export default connect(null, mapDispatchToProps)(SendTkusd);
