/* -- set app mode -- */
const {
  REACT_APP_SERVER
} = process['env'];

/* -- set app title --*/
const AppTitle = 'Tokenism';

// const AppMode = [''];
const AppMode = [REACT_APP_SERVER];

/*-- set API URLs --*/
const testing = "http://192.168.8.102:4000/api";
const prototype = "https://server.tknism.io/api";
const development = "https://dserver.tknism.io/api";

let ApiUrl = "";
switch (AppMode[0]) {
  case "development":
    ApiUrl = development;
    break;
  case "prototype":
    ApiUrl = prototype;
    break;
  case "testing":
    ApiUrl = testing;
    break;
  default:
    ApiUrl = "http://203.124.42.157:4000/api";
}

export {
  AppTitle, ApiUrl
};
