
import axios from 'axios';
import { ApiUrl } from './config';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { detect } from 'detect-browser';

// (async () => 
// {
//   const browser = await detect();
//   if (browser) axios.defaults.headers.common['agent'] = `${browser.name[0].toUpperCase() + browser.name.slice(1)} V${browser.version} (${browser.os})`;
//   let agent = axios.defaults.headers.common['agent'];
//   let auth = axios.defaults.headers.common['Authorization'];

//   delete axios.defaults.headers.common['agent'];
//   delete axios.defaults.headers.common['Authorization'];

//   await axios.get('https://api.ipstack.com/check', 
//   {params: {access_key: 'ef393381cb37599c054fbaa449f1fa34'}})
//   .then(({data}) =>
//   {
//     axios.defaults.headers.common['city'] = data['city'];
//     axios.defaults.headers.common['zipcode'] = data['zip'];
//     axios.defaults.headers.common['ipaddress'] = data['ip'];
//     axios.defaults.headers.common['countryname'] = data['country_name'];
//     axios.defaults.headers.common['countrycode'] = data['country_code'];
//     axios.defaults.headers.common['internet'] = data['connection']['isp'];
//     axios.defaults.headers.common['continentcode'] = data['continent_code'];
//     axios.defaults.headers.common['continentname'] = data['continent_name'];
//     axios.defaults.headers.common['callcode'] = data['location']['calling_code'];
//   }).catch(e=> console.log(`Error While Fetching API = `, e));

//   axios.defaults.headers.common['agent'] = agent;
//   if(auth) axios.defaults.headers.common['Authorization'] = auth;
//   console.log(`Final Headers = `, axios.defaults.headers.common);
//   console.log('====================Set Browser===============');
//   console.log(`${browser.name[0].toUpperCase() + browser.name.slice(1)} V${browser.version} (${browser.os})`);
//   console.log('==============================================');
// })();

setInterval(() => {
  let token = localStorage.getItem('token');
  if (token) {
    let decoded = jwt_decode(token);
    let expiry = decoded['exp'] * 1000;
    let timeNow = (new Date()).getTime();
    if (expiry <= timeNow) EventBus.publish('tokenExpired', true);
  }
}, 5000);

axios.defaults.baseURL = ApiUrl;
let token = localStorage.getItem('token');
if (token) axios.defaults.headers.common['Authorization'] = `bearer ${token}`;

export const setToken = token => {
  if (token) axios.defaults.headers.common['Authorization'] = `bearer ${token}`;
  else delete axios.defaults.headers.common['Authorization'];
};