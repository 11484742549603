import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';

class UserOverview extends Component {
  state = {
    showEdit: false,
  }

  handleEdit = () => this.setState({ showEdit: !this.state.showEdit })

  handleSubmit = (e) => {
    console.log("*********on submit :: ", e);
    let { userDetails } = this.props;
    if (userDetails && userDetails['_id']) e['_id'] = userDetails['_id'];

    this.setState({ showEdit: !this.state.showEdit });
    // this.props.updatePropertyOverview(e)
  }

  render() {
    const { showEdit } = this.state;
    const { userDetails } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************userDetails `, userDetails);

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Info</h4>
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
              Name
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.firstName} {userDetails && userDetails.lastName}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Email
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.email}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Account Type
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.type}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Address
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.address}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Citizenship
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.citizenship.name}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Zip code
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.zipCode}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              State
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.state.name}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              City
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.city}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Invest Package
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.investPackage}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Invest Plan
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.investPlan}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Security Number
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.securityNumber}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Phone Number
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.phone}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Withholding Tax
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.taxWitholding}
            </div>
          </div>



          <div className="single-row">
            <div className="modal-content-title">
              Public Address
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.publicAddress}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Nonce
            </div>
            <div className="modal-content-data">
              {userDetails && userDetails.nonce}
            </div>
          </div>
          <div className="single-row">
            <div className="modal-content-title">
            Created At
            </div>
            <div className="modal-content-data">
              {userDetails && new Date(userDetails.createdAt).toDateString() }
            </div>
          </div>
        </div>
      )
  }
}

export default connect(null, null)(UserOverview);
