import { connect } from "react-redux";
import React, { Component } from 'react';
import { getPropertyOwnerProfile } from '../../../store/actions/PropertyOwner';
import moment from 'moment';


class PropertyOwnerProfile extends Component {
        state = {
        }
    componentDidMount(){
        this.props.getPropertyOwnerProfile()
    }
    render() { 
        const { firstName, lastName, email, avatar, address, createdAt, dob, phone, publicAddress } =  this.props.propertyOwnerProfile
        console.log(this.props.propertyOwnerProfile);
        return ( 
            <div className='content'>
                <div className='main-container'>
                    <h3 align='center'>
                        Profile
                    </h3>
                    <hr className='py-3' />
                    <div className="d-flex justify-content-center mb-5">
                        <img className="rounded-circle" width='140' src={avatar} />
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Name</div>
                        <div className="modal-content-data text-capitalize">{firstName + " " + lastName}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Date of Birth</div>
                        <div className="modal-content-data">{moment(dob).format('ll')}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Email</div>
                        <div className="modal-content-data">{email}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Phone No.</div>
                        <div className="modal-content-data">{phone}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Address</div>
                        <div className="modal-content-data">{address}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Account Created</div>
                        <div className="modal-content-data">{moment(createdAt).format('ll')}</div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">Public Address</div>
                        <div className="modal-content-data">{publicAddress}</div>
                    </div>
                </div>
            </div>
         );
    }
}

const mapStateToProps = (state) => {
    return {
        propertyOwnerProfile: state.PropertyOwner.propertyOwnerProfile
    }
  }

const mapDispatchToProps = { getPropertyOwnerProfile }
 
export default connect(mapStateToProps, mapDispatchToProps)(PropertyOwnerProfile);