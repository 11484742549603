import axios from 'axios';
import EventBus from 'eventing-bus';
import { put, all, takeEvery, call } from 'redux-saga/effects';

function* getAllProperties() {
    const { error, response } = yield call(getCall, '/propertyOwner/getAllPropertiesDetail');
    if (error) EventBus.publish("error", error.response.data.message)
    else if (response) {
        EventBus.publish("success", response['data']['message'])
        console.log('body', response['data']['body']);
        yield put({
        type: 'SET_OWNER_PROPERTIES_LIST',
        payload: response['data']['body']
        })
    }
}

function* getOwnerStats() {
    const { error, response } = yield call(getCall, '/propertyOwner/getPropertyData');
    if (error) EventBus.publish("error", error.response.data.message)
    else if (response) {
        console.log('body', response['data']['body']);
        yield put({
        type: 'SET_OWNER_STATS',
        payload: response['data']['body']
        })
    }
}

function* getPropertyOwnerWallet({ payload }) {
  const { error, response } = yield call(getCall, `/propertyOwner/getWallets/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'PROPERTY_OWNER_WALLET', payload: response.data.body });
}
  
function* getPropertyOwnerSto({ payload }) {
  const { error, response } = yield call(getCall, `/propertyOwner/getPropertyProgress/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'PROPERTY_OWNER_STO', payload: response.data.body });
}

function* getPropertyInvestments({ payload }) {
  const { error, response } = yield call(getCall, `/propertyOwner/getPropertyUsers/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'PROPERTY_OWNER_INVESTMENTS', payload: response.data.body });
}

function* getPropertyOwnerProfile() {
  const { error, response } = yield call(getCall, `/propertyOwner/getProfile`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'PROPERTY_OWNER_PROFILE', payload: response.data.body });
}
  
function* actionWatcher() {
    yield takeEvery('GET_OWNER_PROPERTIES_LIST', getAllProperties)
    yield takeEvery('GET_OWNER_STATS', getOwnerStats)
    yield takeEvery('GET_PROPERTY_OWNER_WALLET', getPropertyOwnerWallet)
    yield takeEvery('GET_PROPERTY_OWNER_STO', getPropertyOwnerSto)
    yield takeEvery('GET_PROPERTY_OWNER_INVESTMENT', getPropertyInvestments)
    yield takeEvery('GET_PROPERTY_OWNER_PROFILE', getPropertyOwnerProfile)
  }
  
  export default function* rootSaga() { yield all([actionWatcher()]) };
  
  function postCall({ path, payload }) {
    return axios
      .post(path, payload)
      .then((response) => ({ response }))
      .catch(error => {
        if (error['response']['data']['code'] === 401)
          EventBus.publish('tokenExpired', true);
        return ({ error });
      });
  }
  
  function getCall(path) {
    return axios
      .get(path)
      .then((response) => ({ response }))
      .catch(error => {
        if (error['response']['data']['code'] === 401)
          // console.log(`***********Please Logout`);
          EventBus.publish('tokenExpired', true);
        return ({ error });
      });
  }
  
  function deleteCall(path) {
    return axios.delete(path)
      .then((response) => ({ response }))
      .catch(error => {
        if (error.response.data.code === 401)
          EventBus.publish('tokenExpired', true);
        return ({ error })
      });
  }
  
  function putCall({ path, payload }) {
    return axios.put(path, payload)
      .then((response) => ({ response }))
      .catch(error => {
        if (error.response.data.code === 401)
          EventBus.publish('tokenExpired', true);
        return ({ error })
      });
  }
  