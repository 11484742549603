
import { connect } from "react-redux";
import EventBus from 'eventing-bus';
import React, { Component } from "react";
import Login from "views/Manager/Login/Login.js";
import PrivateRoute from './PrivateRoute';
import { createBrowserHistory } from "history";
import AdminLayout from "layouts/Admin/Admin.jsx";
import PorpertyOwner from "./layouts/PropertyOwner/PropertyOwner";
import { ToastContainer, toast } from 'react-toastify';
import Dashboard from "views/Admin/Dashboard/Dashboard.jsx";
import NotificationAlert from "react-notification-alert";
// import SellRequest from "views/SellRequest/SellRequest.js";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { saveUserData, logout } from './store/actions/Auth';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import PropertyOwner from "./layouts/PropertyOwner/PropertyOwner";

const hist = createBrowserHistory();

class App extends Component {
  notify = ({ auth, message }) => {
    console.log("Place :: ", auth, message);
    var type;
    var time;
    if (auth) {
      type = "success";
      time = 5
    }
    else {
      type = "danger"
      time = 7
    }

    var options = {};
    options = {
      place: "tc",
      message: (
        <div>
          {message}
        </div>
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: time
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  constructor(props) {
    super(props);
    this.state = { 
      auth: props.authToken
     }
  }

  componentDidMount() {
    EventBus.on("handleAlert", this.notify);
    EventBus.on('info', (e) => toast.info(e));
    EventBus.on('error', (e) => toast.error(e));
    EventBus.on('success', (e) => toast.success(e));
    EventBus.on('saveUserData', (e) => this.props.saveUserData(e));
    EventBus.on('tokenExpired', () => {
      hist.push('/login');
      this.props.logout();
    });
  }

  render() {
    return (
      <div>
        <ToastContainer />
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Router history={hist}>
          <Switch>
            {/* {(localStorage.getItem('token') || this.props.authToken)?
              <>
                <Route path="/home" component={props => <AdminLayout {...props} />} />
                <Route path="/dashboard" component={props => <Dashboard {...props} />} />
                <Route path="/home/sell-property" component={props => <SellRequest {...props} />} />
                <Redirect exact from='/' to="/home/dashboard" />
              </>
            :
              <>
                <Route path="/login" component={props => <Login {...props} />}/>
                <Redirect to="/login" />
              </>
            } */}
            {this.props.role == "assets" ?
              <PrivateRoute path="/home" component={props => <AdminLayout {...props} />} />
              :
              <PrivateRoute path="/home" component={props => <PropertyOwner {...props} />} />
            }
          <PrivateRoute path="/dashboard" component={props => <Dashboard {...props} />} />
            <Route path="/login" component={props => <Login {...props} />} />
            <Redirect exact from='/' to="/login" />
          </Switch>
        </Router>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return { 
    authToken: state.Auth.authToken,  
    role: state.Auth.role,  
  }
}
const mapDispatchToProps = { saveUserData, logout };

export default connect(mapStateToProps, mapDispatchToProps)(App);
