import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import ReactTable from 'react-table-6';
import { getAllRoles } from "../../../store/actions/Roles";
import { showLoader } from "../../../store/actions/Auth";

class Roles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null,
      agreementFile: ''
    };
    props.getAllRoles()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) {
        console.log("rowData", rowInfo.original);
        this.setState({ SingleRowData: rowInfo.original })
      }
    }
  })

  onDelete = (e) => this.props.onDeleteRole(e)
  handleNewRole = () => this.props.history.push("/home/new-manager")

  componentWillReceiveProps(props) {
    if (props.buttonLoader === false)
      this.setState({ showModal: false })
  }

  editRole = (user) => {
    console.log('user', user);
    let query = `id=${user['_id']}`;
    this.props.history.push(`/home/edit-user?${query}`);
  }


  render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name',
      style: { textAlign: 'center' },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: { textAlign: 'center' },
    },
    {
      Header: 'Wallet',
      accessor: 'publicAddress',
      style: { textAlign: 'center' },
    },
    {
      Header: 'Role',
      accessor: 'role',
      style: { textAlign: 'center' },
    },
      // {
      //   Header: '',
      //   width: 180,
      //   minWidth: 120,
      //   maxWidth: 250,
      //   sortable: false,
      //   filterable: false,
      //   resizable: false,
      //   Cell: props => {
      //     return (
      //       <span style={{ display: "flex" }}>
      //         <a href={props.original.location} download>
      //           <Button className="btn-info action-btn" onClick={() => this.editRole(props.original)} style={{ minWidth: "80px", background: "#92BF55" }}>
      //             Edit
      //         </Button>
      //         </a>
      //         <Button onClick={() => this.onDelete(props.original._id)} className="btn-info action-btn action-btn-outline-info">
      //           Delete
      //           </Button>
      //       </span>
      //     )
      //   }
      // }
    ];

    return (
      <div className="content">
        <div className="main-container">
          <div className="agreement-header">
            <h2 style={{ marginBottom: "20px" }}>Roles</h2>
            <Button onClick={() => this.handleNewRole()} className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55", width: "160px" }}>
              Add Role
            </Button>
          </div>
          {this.props.users !== ''
            && <ReactTable
              data={this.props.users}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Roles, Auth }) => {
  console.log("************Blogs state :: ", Roles);
  let { users } = Roles;
  let { buttonLoader } = Auth;
  return { users, buttonLoader }
}

const mapDispatchToProps = {
  showLoader,
  getAllRoles,
};

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
