import { setToken } from '../axios';
import { PURGE } from "redux-persist";

var initialState = {
  blogs: '',
  agreements: '',
  loader: false,
  buttonLoader: false,
  listedProperties: '',
  propertyRequested: '',
  singleBlogDetails: '',
  singlePropertyRequest: '',
  singlePropertyDetails: '',
  role: localStorage.getItem('role') || '',
  authToken: localStorage.getItem('token') || '',
  users:'',
  premiumRequests:'',
  allSupportTickets:'',
  userDetail:'',
  supportDetail:'',
  allWireWithdrawal:'',
  singelEvent:''
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
    return initialState;

    case 'ALL_WIRE_WITHDRAWAL':
    return {
      ...state,
      allWireWithdrawal: payload
    }

    case 'SET_DASHBOARD_DATA':
    return {
      ...state,
     dashboardStats: payload
    }

    case 'SINGLE_EVENT_DATA':
    return {
      ...state,
      singelEvent: payload
    }

    case 'SAVE_USER_DATA': {
      setToken(payload.token);
      localStorage.setItem('role', payload.role);
      localStorage.setItem('token', payload.token);
      return {
        ...state,
        role: payload.role,
        authToken: payload.token,
      };
    }

    case 'SHOW_LOADER':
    return {
      ...state,
      buttonLoader: payload
    }

    case 'LOGOUT':
    localStorage.clear();
    return initialState

    case 'HANDLE_LOADER':
    return {
      ...state,
      loader: payload
    };

    case 'PROPERTY_REQUEST_RESPONSE':
    return {
      ...state,
      propertyRequested: payload
    };

    case 'GET_AGREEMENTS_RESPONSE':
    return {
      ...state,
      agreements: payload
    };

    case 'GET_BLOGS_RESPONSE':
    return {
      ...state,
      blogs: payload
    };

    case 'LISTED_PROPERTIES_RESPONSE':
    return {
      ...state,
      listedProperties: payload
    };

    case 'SINGLE_PROPERTY_REQUEST':
    return {
      ...state,
      singlePropertyRequest: payload
    };

    case 'SINGLE_PROPERTY_DETAILS':
    return {
      ...state,
      singlePropertyDetails: payload
    };

    case 'SINGLE_BLOG_DETAILS':
    return {
      ...state,
      singleBlogDetails: payload
    };

    case 'All_USERS':
    return {
      ...state,
      users: payload
    };

    case 'All_PREMIUM_REQUESTS':
    return {
      ...state,
      premiumRequests: payload
    };

    case 'All_SUPPORT_TICKETS':
    return {
      ...state,
      allSupportTickets: payload
    };

    case 'All_EVENTS':
    return {
      ...state,
      allEvents: payload
    };

    case 'SINGLE_USER_DETAILS':
    return {
      ...state,
      userDetail: payload
    };

    case 'SINGLE_SUPPORT_DETAILS':
    return {
      ...state,
      supportDetail: payload
    };

    default:
    return state;
  }
};
export default Auth;
