import { Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    ModalFooter
  } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getAllWireWithdrawal, rejectWireRequest, acceptWireRequest } from "../../../store/actions/Auth";
import { startLoader, stopLoader } from "../../../store/actions/Tokinize";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import moment from 'moment';

class WireWithdrawal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null,
      wireNumber:'',
      wireId:''
    };
    props.startLoader(`Fetching Withdrawal Request`);
    props.getAllWireWithdrawal()
  }

  toggle = (user) => {
    if(this.state.showModal) this.setState({ wireId: '', wireNumber:'' })
    else this.setState({ wireId: user['_id'] })
      this.setState({ showModal: !this.state.showModal })
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleViews = (e) => window.open(e, '_blank')

  viewDetails = (user) => {
    let query = `id=${user['_id']}`;
    this.props.history.push(`/home/support-details?${query}`);
  }

  rejectRequest =(user)=>{
    this.props.rejectWireRequest({data:user['_id']})
  }

  acceptRequest =(user)=>{
    let query = `id=${user['_id']}`;
    console.log('query', query, this.state.wireNumber);
    const data = {wireId: this.state.wireId, wireNumber:this.state.wireNumber}
    this.props.acceptWireRequest({data})
    this.toggle()
  }

  handleChange = event => {
    this.setState({[event.target.name]: event.target.value});
  };

  render() {
    const columns = [
      {
        Header: 'User Name',
        accessor: 'accountName',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
        Cell: props => {
          return (
            <>
            {
              props.original.userId &&
              <span style={{ display: "flex" }}>
              {props.original.userId.firstName + ' ' + props.original.userId.lastName}
              </span>
            }
            </>
          )
        }
      },
      {
        Header: 'User Email',
        accessor: 'accountName',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
        Cell: props => {
          return (
            <>
            {
              props.original.userId &&
              <span style={{ display: "flex" }}>
              {props.original.userId.email}
              </span>
            }
            </>
          )
        }
      },
      {
        Header: 'Payment Id',
        accessor: 'accountName',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        },
        Cell: props => {
          return (
            <>
            {
              props.original.userId &&
              <span style={{ display: "flex" }}>
              {props.original.userId.phraseId}
              </span>
            }
            </>
          )
        }
      },
      {
      Header: 'Account Name',
      accessor: 'accountName',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Amount',
      accessor: 'amount',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Bank Name',
      accessor: 'bankName',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Routing No',
      accessor: 'routingNo',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Status',
      accessor: 'status',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Swift Code',
      accessor: 'swiftCode',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {moment(props.original.createdAt).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: 220,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {
            props.original.status == "pending"
            ?
            <>
            <Button onClick={() => this.rejectRequest(props.original)} className="btn-info action-btn action-btn-outline-info">
            Reject
            </Button>
            <Button onClick={() => this.toggle(props.original)} className="btn-info action-btn action-btn-outline-info">
            Accept
            </Button>
            </>
            :
            <span>
            {props.original.status}
            </span>
          }

          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 style={{ marginBottom: "20px" }}>Wire Withdrawal Request</h2>
            </div>
          </div>
          <Modal isOpen={this.state.showModal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle}></ModalHeader>
              <h3 style={{ margin: "0px 0px 0px 25px" }}>Accept Wire Request</h3>
              <ModalBody>
                  <div className="download-row">
                  <ValidatorForm
                    className="validatorListForm"
                  >
                  <TextValidator
                    type="text"
                    margin="dense"
                    name="wireNumber"
                    label="Wire Number"
                    variant="outlined"
                    className="MyTextField col-md-10"
                    onChange={this.handleChange}
                    value={this.state.wireNumber}
                  />
                  </ValidatorForm>
                  </div>
                  <hr />
              </ModalBody>
              <ModalFooter>
                  <Button color="info" disabled={this.props.buttonLoader} onClick={this.acceptRequest}>
                      {
                          this.props.buttonLoader
                              ? 'Uploading...'
                              : 'Accept'
                      }
                  </Button>
                  <Button color="info" onClick={this.toggle}>Close</Button>
              </ModalFooter>
          </Modal>

          {this.props.allWireWithdrawal !== ''
            && <ReactTable
              data={this.props.allWireWithdrawal}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { allWireWithdrawal } = Auth;
  return { allWireWithdrawal }
}

const mapDispatchToProps = { getAllWireWithdrawal, startLoader, rejectWireRequest, acceptWireRequest };
export default connect(mapStateToProps, mapDispatchToProps)(WireWithdrawal);
