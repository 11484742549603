import { connect } from "react-redux";
import React, { Component } from "react";
import EditPropertyImages from "./EditPropertyImages";
import { deleteImage, uploadCarousel } from "../../store/actions/Property";

class PropertyImages extends Component {
  state = {
    showEdit: false
  };

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  handleSubmit = e => {
    console.log("onsubmit :: ", e);

    this.setState({ showEdit: !this.state.showEdit });
  };

  deleteImage = e => {
    let object = { location: e };
    if (this.props.propertyDetails && this.props.propertyDetails["_id"])
      object["_id"] = this.props.propertyDetails["_id"];

    console.log(`********Delete Image = `, object);
    this.props.deleteImage(object);
  };

  onUpload = (e, type) => {
    console.log("carousel uploaded :: ", e);
    let data = new FormData();
    data.append("propertyId", this.props.propertyDetails["_id"]);
    e.forEach(i => {
      data.append("files", i);
    });
    data.append("type", type);
    this.props.uploadCarousel(data);
  };

  carouselDelete = async (e, type) => {
    console.log("deleted req for :: ", e, type);
    let data = {
      _id: this.props.propertyDetails["_id"],
      location: e[0].location,
      type
    };
    this.props.deleteImage(data);
  };


  render() {
    const { propertyDetails } = this.props;
    const { showEdit } = this.state;
    if (showEdit)
      return (
        <EditPropertyImages
          onCarouselDelete={this.carouselDelete}
          onUpload={this.onUpload}
          onUploadBanner={this.onUploadBanner}
          onSubmit={this.handleSubmit}
        // propertyDetails={propertyDetails}
        />
      );
    else
      return (
        <div>
          <div className="property-header">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h4
                style={{
                  fontWeight: "650",
                  margin: "0px",
                  marginRight: "20px"
                }}
              >
                Images Details
              </h4>
              <i
                className="fa fa-edit"
                style={{ cursor: "pointer" }}
                onClick={this.handleEdit}
              />
            </div>
          </div>
          {propertyDetails.banner !== null &&
            propertyDetails.banner !== undefined ? (
              <div className="single-row">
                <div className="modal-content-title">Banner Image</div>
                <div className="modal-content-data">
                  <img
                    src={propertyDetails.banner.location}
                    alt={propertyDetails.banner.name}
                  />
                </div>
              </div>
            ) : null}

          {propertyDetails.carousel !== null &&
            propertyDetails.carousel !== undefined ? (
              <div className="single-row">
                <div className="modal-content-title">Carousel Images</div>
                <div className="modal-content-data">
                  {propertyDetails.carousel.map(image => (
                    <div className="row">
                      <div className="col-8">
                        <img src={image.location} alt={image.name} />
                      </div>
                      <div className="col-2">
                        {this.state.showEdit ? (
                          <button
                            onClick={() => {
                              this.deleteImage(image.location);
                            }}
                            style={{ fontSize: "14px", height: "45px" }}
                            className="btn btn-danger ml-auto iconButton"
                          >
                            <i className="fa fa-trash" />
                          </button>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
        </div>
      );
  }
}
const mapDispatchToProps = { deleteImage, uploadCarousel };
export default connect(null, mapDispatchToProps)(PropertyImages);
