import Files from "react-files";
import { Button } from "reactstrap";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import React, { Component } from "react";
import { MenuItem, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import ReactQuill from 'react-quill';
import { startLoader, stopLoader } from "../../../../store/actions/Tokinize";
import { getSinglePropertyRequest, showLoader } from "../../../../store/actions/Auth";
import { propertyStep2 } from "../../../../store/actions/Property";
import 'react-quill/dist/quill.snow.css';

// import "./listNewPropertySteps.css";
var url = require("url");

class Step2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      step:2,
      id:'',
      formData: {
        requestId:''
      },
      propertyDescriptionError: false,
      inputs: ["input-0"],
      passwordValid: true,
      sections: { value1: "" }
    };
  }

  componentDidMount() {
    const urlObj = url.parse(document.location.href, true);
    if (
      urlObj.query.id !== undefined &&
      urlObj.query.id !== null &&
      urlObj.query.id !== ""
    ) {
      this.setState({id:urlObj.query.id})
      console.log('urlObj.query.id', urlObj.query.id);
    }
  }

  componentWillReceiveProps(nextProps) {
  }

  handleFormChange = event => {
    this.setState({ propertyDescriptionError: false });
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  onChangeEditor = (value, name) => {
    const { sections } = this.state;
    sections[name] = value;

    this.setState({ sections }, () => console.log("sections :: ", sections, sections[name].toString("html")));
    // if (this.props.onChange)
    // sections.value1.toString('html')
    // this.props.onChange(sections[name].toString('html'));
  };
  appendInput() {
    let sec = { ...this.state.sections };
    sec[
      `value${this.state.inputs.length + 1}`
    ] = "";

    this.setState({ sections: sec }, () => {
      var newInput = `input-${this.state.inputs.length}`;
      this.setState(prevState => ({
        inputs: prevState.inputs.concat([newInput])
      }));
    });
  }

  handleRemove(e) {
    let array = this.state.inputs;
    array.splice(e, 1);
    this.setState({ inputs: array });
  }

  modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image'],
      ['clean']
    ],
  }


  handleSubmit = () => {
    console.log("state :: ", this.state);

    this.setState({ submitted: true });
    if(this.state.sections.value1.toString("html") === "<p><br></p>")
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Atleast 1 Property Section required"
      });
    else {
      this.props.showLoader(true);
      let { formData } = this.state;
      let data = {propertySections: JSON.stringify(this.state.sections)}
      this.props.startLoader(`Uploading Property Data`);
      this.props.propertyStep2({ data, history: this.props.history, id:this.state.id });
    }
    setTimeout(() => this.setState({ submitted: false }), 4000);
  };

  render() {
    return (
      <div className="content">
        <div className="main-container">
        <h2 className="mb-5 pb-3">List New Property</h2>
        <hr />
        <div className="row pb-3">
          <h4 className="divider-heading col-md-6 pl-3">
            Property Overview
          </h4>
          <div
            className="col-md-6"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              className="btn-info"
              onClick={() => this.appendInput()}
            >
              Add New Section
            </Button>
          </div>
        </div>
        <div id="dynamicInput">
          {this.state.inputs.map((input, index) => (
            <div className="text-editor row">
              <div className="col-md-11">
               <ReactQuill 
                  name={`editor${this.state.inputs.length}`}
                  id={`editor${this.state.inputs.length}`}
                  key={input}
                  modules={this.modules}
                  value={this.state.sections[`value${index + 1}`]}
                  onChange={value =>
                    this.onChangeEditor(value, `value${index + 1}`)
                  }
                />
                {/* <RichTextEditor
                  name={`editor${this.state.inputs.length}`}
                  id={`editor${this.state.inputs.length}`}
                  key={input}
                  value={this.state.sections[`value${index + 1}`]}
                  onChange={value =>
                    this.onChangeEditor(value, `value${index + 1}`)
                  }
                /> */}
              </div>
              <div className="col-md-1">
                <button
                  className="btn btn-danger ml-auto iconButton"
                  onClick={() => this.handleRemove(index)}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className='row'>
          <div className='col-6'>
          </div>
          <div className='col-6 text-right'>
            <Button
              className="btn-info col-4"
              type="submit"
              onClick={()=>this.handleSubmit()}
              >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { singlePropertyRequest, listedProperties, buttonLoader } = Auth;
  return { singlePropertyRequest, listedProperties, buttonLoader };
};

const mapDispatchToProps = { showLoader, propertyStep2, getSinglePropertyRequest, startLoader, stopLoader };

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
