import axios from 'axios';
import jwt_decode from 'jwt-decode';
import EventBus from 'eventing-bus';
import { saveUserData } from '../actions/Auth';
import { put, all, takeEvery, call, takeLeading } from 'redux-saga/effects';
import Web3 from "web3";
import { stopLoader } from "../actions/Tokinize";

const url = require('url');
const { query } = url.parse(document.location.href, true);


function* acceptWireRequest({payload}) {
  console.log('payload', payload);
  const { error, response } = yield call(putCall, { path: `/wallet/reqWithdrawal`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_WIRE_WITHDRAWAL' })
    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader());
}

function* rejectWireRequest({payload}) {
  console.log('payload', payload);
  const { error, response } = yield call(putCall, { path: `/wallet/reqWithdrawal/${payload}` });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_WIRE_WITHDRAWAL' })

    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader());
}

function* getAllWireWithdrawal() {
  const { error, response } = yield call(getCall, `/wallet/reqWithdrawal`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({
      type: 'ALL_WIRE_WITHDRAWAL',
      payload: response['data']['body']
      })
  }
  yield put(stopLoader());
}

function* login({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/user/authEmail`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put(saveUserData(response.data.body));
    EventBus.publish("success", response.data.message);
    setTimeout(() => history.push(`/home/dashboard`), 1000);
  }
}

function* loginRequest({ payload, history }) {
  const { error, response } = yield call(getCall, `/user/getUser/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  if (response)
    yield put({
      type: "SIGN_MESSAGE",
      data: {
        history,
        type: "login",
        publicAddress: payload,
        nonce: response["data"]["body"]["nonce"]
      }
    });
}

function* handleSignMessage(action) {
  let web3 = new Web3(window.web3)
  web3.eth.personal.sign(web3.utils.utf8ToHex(`Login to Tokenism - Nonce: ${action["data"]["nonce"]}`), action["data"]["publicAddress"])
    .then(signature => handleloginAuth(signature, action["data"]))
    .catch(e => EventBus.publish("error", e['message']));
}

function handleloginAuth(signature, { publicAddress, type, history }) {
  axios
    .post(`/user/auth`, { publicAddress, signature, type })
    .then(response => {
      console.log('response', response);
      const decoded = jwt_decode(response["data"]["body"]["token"]);
      if (decoded['role'] !== 'user') {
        response['data']['body']['role'] = decoded['role'];
        EventBus.publish("saveUserData", response["data"]["body"]);
        EventBus.publish("success", response["data"]["message"]);

        setTimeout(() => history.push(`/home/dashboard`), 1000);
      } else EventBus.publish("error", "Invalid Login");
    }).catch(error => {
      console.log('error', error);
      EventBus.publish("error", error["response"]["data"]["message"])
    });
}

function* getPropertyRequest() {
  const { error, response } = yield call(postCall, { path: '/property/propertyRequests', payload: {} });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'PROPERTY_REQUEST_RESPONSE', payload: response.data.body });
}

function* getAllAgreements() {
  const { error, response } = yield call(getCall, `/user/files/Agreement`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'GET_AGREEMENTS_RESPONSE', payload: response.data.body })
}

function* getAllBlogs() {
  console.log('in getAllBlogs');
  const { error, response } = yield call(postCall, { path: '/blog', payload: {} });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'GET_BLOGS_RESPONSE', payload: response.data.body })
}

function* getSingleBlog({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: '/blog', payload: payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'SINGLE_BLOG_DETAILS', payload: response.data.body });
}

function* onDeleteAgreement({ payload }) {
  const { error, response } = yield call(getCall, `/user/deleteFile/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_AGREEMENTS' })
    EventBus.publish("success", response['data']['message'])
  }
}

function* onDeleteBlog({ payload }) {
  const { error, response } = yield call(getCall, `/blog/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'GET_ALL_BLOGS' })
    EventBus.publish("success", response['data']['message'])
  }
}

function* getListedProperties() {
  const { error, response } = yield call(postCall, { path: `/property/allProperties`, payload: {} });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'LISTED_PROPERTIES_RESPONSE', payload: response.data.body });
}

function* onUploadAgreement({ payload }) {
  yield put({ type: 'SHOW_LOADER', payload: true })
  const { error, response } = yield call(postCall, { path: `/user/uploadFile`, payload });
  if (error) {
    yield put({ type: 'SHOW_LOADER', payload: false })
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    EventBus.publish("success", response.data.message)
    yield put({ type: 'GET_ALL_AGREEMENTS' })
    yield put({ type: 'SHOW_LOADER', payload: false })
  }
}

function* listNewProperty({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/property/create`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push(`/home/tokenize?id=${response.data.body.propertyId}&name=${response.data.body.name}`), 1000);
  }
  yield put(stopLoader());
}

function* addNewBlog({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/blog/create`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) {
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response['data']['message'])
    setTimeout(() => history.push(`/home/list-blogs`), 1000);
  }
}

function* editSingleBlog({ payload, history, id }) {
  const { error, response } = yield call(putCall, { path: `/blog/${id}`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push(`/home/list-blogs`), 1000);
  }
}

function* updateBlogImage({ payload, id }) {
  console.log('edit blog', id, payload, payload.has('image'), payload.has('type'));
  const { error, response } = yield call(putCall, { path: `/blog/updateImage/${id}`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
  }
}

function* tokenizeProperty({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/property/tokenizeProperty`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    EventBus.publish("success", response.data.message);
    setTimeout(() => history.push(`/home/launch-sto?symbol=${payload['symbol']}&id=${payload['propertyId']}`), 1000);
  }
  yield put(stopLoader());
}

function* launchedSTO({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/property/launchedSTO`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push('/home/active-listings'), 1000);
  }
  yield put(stopLoader());
}

function* editUserInfo({ payload, id }) {
  const { error, response } = yield call(putCall, { path: `/admin/updateUser/${id}`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    EventBus.publish("success", response.data.message)
  }
  yield put(stopLoader());
}

function* getSinglePropertyDetails({ payload }) {
  const { error, response } = yield call(getCall, `/property/getProperty/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) yield put({ type: 'SINGLE_PROPERTY_DETAILS', payload: response.data.body });
}

function* getSinglePropertyRequest({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/property/propertyRequests`, payload: { id: payload } });
  if (error) history.push('/home/active-listings')
  else if (response) yield put({ type: 'SINGLE_PROPERTY_REQUEST', payload: response.data.body[0] });
}

function* getAllUsers() {
  console.log('in get all users');
  const { error, response } = yield call(getCall, `/user`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'All_USERS', payload: response.data.body });
  }
}

function* getAllPremiumRequests() {
  console.log('in get all PremiumRequests');
  const { error, response } = yield call(getCall, `/user/premiumRequest`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'All_PREMIUM_REQUESTS', payload: response.data.body });
  }
}

function* deletePremiumRequest({ payload }) {
  const { error, response } = yield call(getCall, `/user/rejectPremiumRequest/${payload}`)
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_PREMIUM_REQUESTS' });
    EventBus.publish("success", response['data']['message']);
  }
}

function* acceptPremiumRequest({ payload }) {
  const { error, response } = yield call(getCall, `/user/acceptPremiumRequest/${payload}`)
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_PREMIUM_REQUESTS' });
    EventBus.publish("success", response['data']['message']);
  }
}


function* getAllSupportTickets() {
  console.log('in get all support tickets');
  const { error, response } = yield call(getCall, `/user/support`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'All_SUPPORT_TICKETS', payload: response.data.body });
  }
  yield put(stopLoader());
}

function* onDeleteSupportTicket({ payload }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/user/support/${payload}` });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_SUPPORT_TICKETS' })
    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader());
}

function* getSingleUserDetails({ payload }) {
  const { error, response } = yield call(getCall, `/user/single/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response){
    console.log('response.data.body', response.data.body);
    yield put({ type: 'SINGLE_USER_DETAILS', payload: response.data.body });
  }
  yield put(stopLoader());
}

function* getSingleSupportDetails({ payload }) {
  const { error, response } = yield call(getCall, `/user/support/${payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response){
    console.log('response.data.body', response.data.body);
    yield put({ type: 'SINGLE_SUPPORT_DETAILS', payload: response.data.body });
  }
  yield put(stopLoader());
}

function* uploadDocument({ payload, id }) {
  console.log(payload, id);
  const { error, response } = yield call(postCall, { path: `/user/docs/${id}`, payload });
  if (error) {
    yield put({ type: 'SHOW_LOADER', payload: false })
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    console.log('response', response);
    EventBus.publish("success", response.data.message)
    yield put({ type: 'GET_SINGLE_USER_DETAILS', payload:id })
    yield put({ type: 'SHOW_LOADER', payload: false })
  }
}

function* uploadWithHoldingTax({ payload, id }) {
  console.log(payload, id)
  const { error, response } = yield call(postCall, { path: `/admin/updateTax/${id}`, payload });
  if (error) {
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    console.log('response', response);
    EventBus.publish("success", response.data.message)
  }
}

function* onDeleteUserDoc({ payload, id }) {
  console.log(payload.location, id);
  const { error, response } = yield call(putCall, {path:`/user/docs/${id}`, payload: {location:payload.location} });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_SINGLE_USER_DETAILS', payload:id })
    EventBus.publish("success", response['data']['message'])
  }
}

function* sendTkusd({ payload }) {
  console.log(payload);
  const { error, response } = yield call(postCall, {path:`/admin/mintTokens`, payload });
  if (error) {
    console.log('error', error);
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    console.log('response', response);
    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader());
}

function* getAllEvents() {
  const { error, response } = yield call(getCall, `/event/getAllEvents`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'All_EVENTS', payload: response.data.body });
  }
  yield put(stopLoader());
}

function* getSingleEvent(id) {
  const { error, response } = yield call(getCall,`/event/getEvent/${id.payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'SINGLE_EVENT_DATA', payload : response.data.body[0] })
  }
}

function* deleteEvent(id) {
  const { error, response } = yield call(getCall,`/event/delete/${id.payload}`);
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'GET_ALL_EVENTS' })
    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader())
}


function* getDashboardData() {
  const { error, response } = yield call(getCall,'/admin/getAllData');
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({ type: 'SET_DASHBOARD_DATA',payload: response['data']['body'] })
  }
}

function* addNewEvent({ payload, history }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, {path:`/event/create`, payload });
  if (error) {
    console.log('error.response', error.response);
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    console.log('response', response);
    yield put({ type: 'GET_ALL_EVENTS' })
    history.push(`/home/events`);
    EventBus.publish("success", response['data']['message'])
  }
  yield put(stopLoader());
}

function* updateEvent({ payload, id, history }) {
  console.log('response', id);
  const { error, response } = yield call(putCall, {path:`/event/edit/${id}`, payload });
  if (error) {
    console.log('error.response', error.response);
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    history.push(`/home/events`);
    EventBus.publish("success", response['data']['message'])
  }
}


function* actionWatcher() {
  yield takeEvery('LOGIN', login)
  yield takeEvery("ACCEPT_WIRE_REQUEST", acceptWireRequest);
  yield takeEvery("REJECT_WIRE_REQUEST", rejectWireRequest);
  yield takeEvery("GET_ALL_WIRE_WITHDRAWAL", getAllWireWithdrawal);
  yield takeEvery("SEND_TKUSD", sendTkusd);
  yield takeEvery("LOGIN_REQUEST", loginRequest);
  yield takeLeading("SIGN_MESSAGE", handleSignMessage);
  yield takeEvery('LAUNCHED_STO', launchedSTO)
  yield takeEvery('LIST_NEW_PROPERTY', listNewProperty)
  yield takeEvery('ADD_NEW_BLOG', addNewBlog)
  yield takeEvery('TOKENIZE_PROPERTY', tokenizeProperty)
  yield takeEvery('GET_PROPERTY_REQUEST', getPropertyRequest)
  yield takeEvery('GET_ALL_AGREEMENTS', getAllAgreements)
  yield takeEvery('GET_ALL_BLOGS', getAllBlogs)
  yield takeEvery('GET_LISTED_PROPERTIES', getListedProperties)
  yield takeEvery('GET_SINGLE_PROPERTY_DETAILS', getSinglePropertyDetails)
  yield takeEvery('GET_SINGLE_PROPERTY_REQUEST', getSinglePropertyRequest)
  yield takeEvery('DELETE_AGREEMENT', onDeleteAgreement)
  yield takeEvery('DELETE_BLOG', onDeleteBlog)
  yield takeEvery('UPLOAD_AGREEMENTS', onUploadAgreement)
  yield takeEvery('GET_SINGLE_BLOG', getSingleBlog)
  yield takeEvery('EDIT_SINGLE_BLOG', editSingleBlog)
  yield takeEvery('UPDATE_BLOG_IMAGE', updateBlogImage)
  yield takeEvery('GET_ALL_USERS', getAllUsers)
  yield takeEvery('GET_ALL_PREMIUM_REQUESTS', getAllPremiumRequests)
  yield takeEvery('DELETE_PREMIUM_REQUEST', deletePremiumRequest)
  yield takeEvery('ACCEPT_PREMIUM_REQUEST', acceptPremiumRequest)
  yield takeEvery('GET_SINGLE_USER_DETAILS', getSingleUserDetails)
  yield takeEvery('UPLOAD_DOCUMENT', uploadDocument)
  yield takeEvery('UPLOAD_WITH_HOLDING_TAX', uploadWithHoldingTax)
  yield takeEvery('DELETE_USER_DOC', onDeleteUserDoc)
  yield takeEvery('EDIT_USER_INFO', editUserInfo)
  yield takeEvery('GET_ALL_SUPPORT_TICKETS', getAllSupportTickets)
  yield takeEvery('GET_SINGLE_SUPPORT_DETAILS', getSingleSupportDetails)
  yield takeEvery('DELETE_SUPPORT_TICKETS', onDeleteSupportTicket)
  yield takeEvery('GET_ALL_EVENTS', getAllEvents)
  yield takeEvery('DELETE_EVENT', deleteEvent)
  yield takeEvery('GET_SINGLE_EVENT', getSingleEvent)
  yield takeEvery('ADD_NEW_EVENT', addNewEvent)
  yield takeEvery('UPDATE_EVENT', updateEvent)
  yield takeEvery('GET_DASHBOARD_DATA', getDashboardData)

}

function postCall({ path, payload }) {
  return axios.post(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}

function getCall(path) {
  return axios.get(path)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}

function deleteCall({path, payload}) {
  console.log('path, payload', path, payload);
  return axios.delete(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}

function putCall({ path, payload }) {
  return axios.put(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}

export default function* rootSaga() {
  yield all([actionWatcher()]);
}
