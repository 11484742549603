import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { getPropertyOwnerSto } from '../../../store/actions/PropertyOwner';

class PropertyStoDetails extends Component {
  state = {
    hasClosed: false,
    finalized: false,
    showModal: false,
    endDate: new Date(this.props.stoDetails['endDate']),
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
  }

  componentDidMount(){
    const url = require('url');
    const { query } = url.parse(document.location.href, true);
    this.props.getPropertyOwnerSto(query['id'])
  }
  

  handleChange(date) {
    console.log(date);
    this.setState({endDate:date})
  }

  render() {
    const { stoDetails } = this.props;
    return (
      <div className='property-overview'>
        <div className='property-header'>
          <h3 style={{ 'fontWeight': '650' }}>Token Details</h3>
        </div>
        <div>
          <div className="single-row">
            <div className="modal-content-title">
              Start Date
            </div>
            <div className="modal-content-data">
              {moment(stoDetails['startDate']).format('lll')}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              End Date
            </div>
            <div className="modal-content-data">
              {moment(stoDetails['endDate']).format('lll')}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Owner Ship Transfered
            </div>
            <div className="modal-content-data">
              {stoDetails.ownershipTransfered ? 'Done' : 'Pending'}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              STO State
            </div>
            <div className="modal-content-data">
              {stoDetails.status}
            </div>
          </div>

          {stoDetails.status === 'Pre-Launch' &&
            <div className="single-row">
              <div className="modal-content-title">
                Days Remaining to Start
            </div>
              <div className="modal-content-data">
                {stoDetails.daysRemaining}
                <span className='symbol'>
                  Days
              </span>
              </div>
            </div>
          }

          {(stoDetails.status === 'Pre-Goal' || stoDetails.status === 'Post-Goal') &&
            <div className="single-row">
              <div className="modal-content-title">
                Days Remaining to End
            </div>
              <div className="modal-content-data">
                {stoDetails.daysRemaining}
                <span className='symbol'>
                  Days
              </span>
              </div>
            </div>
          }

          <div className="single-row">
            <div className="modal-content-title">
              Property Value
            </div>
            <div className="modal-content-data">
              {stoDetails.propertyValue}
              <span className='symbol'>
                TKUSD
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Token Price
            </div>
            <div className="modal-content-data">
              {stoDetails.price}
              <span className='symbol'>
                TKUSD
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Total Tokens
            </div>
            <div className="modal-content-data">
              {stoDetails.totalTokens}
              <span className='symbol'>
                {stoDetails.currency}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Offering Goal
            </div>
            <div className="modal-content-data">
              {stoDetails.goalPercent}
              <span className='symbol'>
                %
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Offering Cap
            </div>
            <div className="modal-content-data">
              {stoDetails.capPercent}
              <span className='symbol'>
                %
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens On Sale
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenOnSale}
              <span className='symbol'>
              {stoDetails.currency}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens UnSold
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenUnSold}
              <span className='symbol'>
              {stoDetails.currency}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Tokens Sold
            </div>
            <div className="modal-content-data">
              {stoDetails.tokenSold}
              <span className='symbol'>
              {stoDetails.currency}
              </span>
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Network
            </div>
            <div className="modal-content-data">
              {stoDetails.network}
            </div>
          </div>

          <div className="single-row">
            <div className="modal-content-title">
              Stable Coin Currency
            </div>
            <div className="modal-content-data">
              {stoDetails.currency}
            </div>
          </div>

          {stoDetails.currencyAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Stable Coin Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.currencyAddress} target='_blank'>
                  {stoDetails.currencyAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.propertyWallet &&
            <div className="single-row">
              <div className="modal-content-title">
                Property Store Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.propertyWallet} target='_blank'>
                  {stoDetails.propertyWallet}
                </a>
              </div>
            </div>
          }

          {stoDetails.propertyOwner &&
            <div className="single-row">
              <div className="modal-content-title">
                Property Owner Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.propertyOwner} target='_blank'>
                  {stoDetails.propertyOwner}
                </a>
              </div>
            </div>
          }

          {stoDetails.fromWallet &&
            <div className="single-row">
              <div className="modal-content-title">
                Tokenism Manager Wallet
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.fromWallet} target='_blank'>
                  {stoDetails.fromWallet}
                </a>
              </div>
            </div>
          }

          {stoDetails.whitelistAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Tokenism Whitelist Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.whitelistAddress} target='_blank'>
                  {stoDetails.whitelistAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.contractAddress &&
            <div className="single-row">
              <div className="modal-content-title">
                Contract Address
              </div>
              <div className="modal-content-data">
                <a href={stoDetails.explorer + stoDetails.contractAddress} target='_blank'>
                  {stoDetails.contractAddress}
                </a>
              </div>
            </div>
          }

          {stoDetails.transactionHash &&
            <div className="single-row">
              <div className="modal-content-title">
                Transaction Hash
              </div>
              <div className="modal-content-data">
                <a href={(stoDetails.explorer + stoDetails.transactionHash).replace(`address`, `tx`)} target='_blank'>
                  {stoDetails.transactionHash}
                </a>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    stoDetails: state.PropertyOwner.stoDetails
  }
}
const mapDispatchToProps = { getPropertyOwnerSto };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyStoDetails);
