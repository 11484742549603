export const getOwnerPropertiesList = () => ({
    type: 'GET_OWNER_PROPERTIES_LIST',
  });
  
export const getOwnerDashboardStats = () => ({
  type: 'GET_OWNER_STATS',
});
  
export const getPropertyOwnerProfile = () => ({
  type: 'GET_PROPERTY_OWNER_PROFILE',
});

export const getPropertyOwnerWallet = (data) => ({
  type: 'GET_PROPERTY_OWNER_WALLET',
  payload: data
});

export const getPropertyOwnerSto = (data) => ({
  type: 'GET_PROPERTY_OWNER_STO',
  payload: data
});

export const getPropertyInvestments = (data) => ({
  type: 'GET_PROPERTY_OWNER_INVESTMENT',
  payload: data
});