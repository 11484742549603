import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    ModalFooter
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'
import moment from 'moment';
import {
  showLoader,
  EditSingleBlog,
  getSingleBlog,
  singleBlogDetails,
  updateBlogImage
} from "../../../store/actions/Auth";
import './editblog.css'
// import JoditEditor from "jodit-react";
import { MenuItem, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Files from "react-files";
import EventBus from "eventing-bus";
import RichTextEditor from "react-rte";
import validator from 'validator';
import ReactQuill from 'react-quill';

const config = {
  readonly: false // all options from https://xdsoft.net/jodit/doc/
}
class EditBlog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            SingleRowData: null,
            body:'',
            blogDetails:'',
            formData: {
              title: "",
              intro:"",
              body:'',
              // body:RichTextEditor.createEmptyValue(),
              writtenBy:""
            },
        };
    }

    modules = {
      toolbar: [
        [{ 'header': [1, 2, false] }],
        ['bold', 'italic', 'underline','strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
    }

    componentDidMount() {
        const {query} = require('url').parse(document.location.href, true);

        console.log('in did mount', query['id']);
        if (!query['id']) {
            EventBus.publish("error", `Please select blog`)
            setTimeout(() => this.props.history.push(`/home/blogs`), 1000);
        } else{
          console.log('query id', query['id']);
          this.setState({blogId:query['id']})
          this.props.getSingleBlog({ _id: query['id']});
        }
    }

    componentWillReceiveProps({ singleBlogDetails })
    {
        if (singleBlogDetails){
          console.log('singleBlogDetails', singleBlogDetails);
          const { formData } = this.state;
          formData['title'] = singleBlogDetails[0].title;
          formData['intro'] = singleBlogDetails[0].intro;
          let body = validator.unescape(singleBlogDetails[0].body);
          console.log('body', body);
          formData['body'] =  body;
          // formData['body'] =  RichTextEditor.createValueFromString(body, 'html');
          console.log('body', formData);
          formData['writtenBy'] = singleBlogDetails[0].writtenBy;
          this.setState({ formData });
          this.setState({ propertyDetails: singleBlogDetails, imageValue:singleBlogDetails[0].cover.location, imageThumb:singleBlogDetails[0].thumbnail.location })
        }
    }


    handleCategoryImage = e => {
      var imageValue = e.length - 1;
      this.setState({ imageValue: URL.createObjectURL(e[imageValue]) });
      var imageDetail = e[0];
      this.setState({ cardImageData: imageDetail });
      let data = new FormData();
      data.append(`image`, imageDetail);
      data.append(`type`, 'cover');
      this.props.updateBlogImage({ data, id:this.state.blogId})
    };

    handleRemoveImage = () => this.setState({ imageValue: "" });

    handleThumbImage = e => {
      var imageValue = e.length - 1;
      this.setState({ imageThumb: URL.createObjectURL(e[imageValue]) });
      var imageDetail = e[0];
      this.setState({ thumbImageData: imageDetail });
      let data = new FormData();
      data.append(`image`, imageDetail);
      data.append(`type`, 'thumbnail');
      console.log(data.has('image'), data.has('type'));
      this.props.updateBlogImage({ data, id:this.state.blogId})
    };

    handleRemoveThumbImage = () => this.setState({ imageThumb: "" });

    onFilesError = e => {
      console.log("file error :: ", e);
      EventBus.publish("error", e.message);
    };

    handleFormChange = event => {
      this.setState({ propertyDescriptionError: false });
      const { formData } = this.state;
      formData[event.target.name] = event.target.value;
      this.setState({ formData });
    };

    handleSubmit = () => {
      this.setState({ submitted: true });
      if (
        this.state.imageValue === undefined ||
        this.state.imageValue.length === 0
      )
        EventBus.publish("handleAlert", {
          auth: false,
          message: "Top Banner required"
        });
      else {
        this.props.showLoader(true);
        const { title, intro, writtenBy ,category, body } = this.state.formData;
        const data = {
          title,
          intro,
          writtenBy,
          category,
          body: body.toString("html"),
        }
        this.props.EditSingleBlog({ payload: data, history: this.props.history, id:this.state.blogId });
      }
      setTimeout(() => this.setState({ submitted: false }), 4000);
    };

    onChangeEditor = (value) => {
      console.log('value', value.toString("html"));
      this.setState({content:value})
      this.setState({body:value.toString("html")})
    };

    handelEditorChange = (value) => {
      const { formData } = this.state;
      formData['body'] =  value
      this.setState({body:value.toString("html")})
      this.setState({ formData });
    }

    render() {
      let { propertyDetails, activeTab } = this.state;
      console.log('propertyDetails', propertyDetails);

      const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['BLOCK_TYPE_BUTTONS'],
        BLOCK_TYPE_BUTTONS: [
          {label: 'Img', style: 'unordered-list-item'},
          {label: 'Img', style: 'ordered-list-item'}
        ]
      };

        return (
            <div className="content">
                <div className="main-container">
                    <div className="blog-header">
                        <h2 style={{ marginBottom: "20px" }}>Edit Blog</h2>
                    </div>
                    <ValidatorForm
                      className="validatorListForm"
                      onSubmit={this.handleSubmit}
                    >
                    <Grid container spacing={3}>
                      <Grid item xs={7}>
                        <TextValidator
                          type="text"
                          name="title"
                          label="Title"
                          margin="dense"
                          variant="outlined"
                          validators={["required"]}
                          onChange={this.handleFormChange}
                          value={this.state.formData.title}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Title can not be empty"]}
                        />
                      </Grid>

                      <Grid item xs={7}>
                        <TextValidator
                          type="text"
                          name="writtenBy"
                          label="Author"
                          margin="dense"
                          variant="outlined"
                          validators={["required"]}
                          onChange={this.handleFormChange}
                          value={this.state.formData.writtenBy}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Author can not be empty"]}
                          />
                      </Grid>

                      <Grid item xs={12}>
                        <TextValidator
                          type="text"
                          name="intro"
                          label="Excerpt"
                          margin="dense"
                          variant="outlined"
                          validators={["required"]}
                          onChange={this.handleFormChange}
                          value={this.state.formData.intro}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Excerpt can not be empty"]}
                        />
                      </Grid>
                      <Grid item xs={12}>

                      <h4 className="divider-heading">Top Banner</h4>
                      <h5> Cover image must be **** </h5>

                      {this.state.imageValue !== "" &&
                      this.state.imageValue !== undefined ? (
                        <div className="singleImage" style={{display:"flex"}}>
                          <img className="imageList" src={this.state.imageValue} />
                            <Files
                              onChange={e => this.handleCategoryImage(e)}
                              onError={this.onFilesError}
                              accepts={["image/*"]}
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                            <div style={{justifyContent: 'center', paddingLeft: '10%'}}>
                              <button
                                type="button"
                                style={{ fontSize: "14px" }}
                                className="btn btn-danger ml-auto iconButton"
                                >
                                <i className="fa fa-edit" />
                              </button>
                            </div>
                            </Files>
                        </div>
                      ) : null}

                      </Grid>

                      <Grid item xs={12}>

                      <h4 className="divider-heading">Thumbnail</h4>
                      <h5> Thumbnail must be ***** </h5>

                      {this.state.imageThumb !== "" &&
                      this.state.imageThumb !== undefined ? (
                        <div className="singleImage" style={{display:"flex"}}>
                          <img className="imageList" src={this.state.imageThumb} />
                            <Files
                              onChange={e => this.handleThumbImage(e)}
                              onError={this.onFilesError}
                              accepts={["image/*"]}
                              maxFileSize={10000000}
                              minFileSize={0}
                              clickable
                            >
                            <div style={{justifyContent: 'center', paddingLeft: '10%'}}>
                              <button
                                type="button"
                                style={{ fontSize: "14px" }}
                                className="btn btn-danger ml-auto iconButton"
                                >
                                <i className="fa fa-edit" />
                              </button>
                            </div>
                            </Files>
                        </div>
                      ) : null}

                      </Grid>

                      <Grid item xs={12}>]
                        {/*
                          <RichTextEditor
                            name="body"
                            value={this.state.formData.body}
                            onChange={body => this.handelEditorChange(body)}
                          />
                        */}

                          <ReactQuill
                             name="body"
                             id="body"
                             modules={this.modules}
                             value={this.state.formData.body}
                             onChange={body => this.handelEditorChange(body)}
                           />

                      </Grid>

                    </Grid>

                    <Button
                      type="submit"
                      className="btn-info"
                      style={{ width: "210px", marginTop: "10px" }}
                      disabled={this.props.buttonLoader}
                    >
                      {!this.props.buttonLoader ? (
                        "Edit Blog"
                      ) : (
                        <i
                          style={{ color: "white" }}
                          className="fa fa-spinner fa-spin fa-1x fa-fw"
                        ></i>
                      )}
                    </Button>

                    </ValidatorForm>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    let { singleBlogDetails } = state.Auth;
    return { singleBlogDetails };
}

const mapDispatchToProps =
{
showLoader:showLoader,
EditSingleBlog:EditSingleBlog,
getSingleBlog:getSingleBlog,
updateBlogImage:updateBlogImage
};

export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);
