import React, { Component } from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Input,
    FormGroup,
    ModalFooter
} from "reactstrap";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'
import { updateEvent, getSingleEvent } from "../../../store/actions/Auth";
import './update-event.css'
import { MenuItem, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator, SelectValidator } from "react-material-ui-form-validator";
import TimezonePicker from 'react-bootstrap-timezone-picker';
import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


class UpdateEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            SingleRowData: null,
             // content: RichTextEditor.createEmptyValue(),
            content: "",
            formData: {
              timeZone:"",
              title: "",
              type:"event",
              description:"",
              signUp:"",
              startDate:new Date(),
              endDate:new Date(),
              date:new Date(),
              link:"",
            },
        };

        // props.getAllAgreements()
    }



    toggle = () => {
        this.setState({ showModal: !this.state.showModal, agreementFile: '' })
    }

    componentDidMount(){
      ValidatorForm.addValidationRule('isOnlySpecialCharater', (value) => {
        var pattern = /^[^a-zA-Z0-9]+$/;
        return (!pattern.test(value))
      })
      ValidatorForm.addValidationRule('validURL', (str) => {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
          return !!pattern.test(str);
      })
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const ID = urlParams.get('id')
  
      this.props.getSingleEvent(ID)
    }

    componentWillReceiveProps(props) {
        if (props.buttonLoader === false)
            this.setState({ showModal: false })
        if (props.singelEvent !== this.state.formData) {
            const formData = {
                title: props.singelEvent.title,
                timeZone:props.singelEvent.timeZone,
                type:props.singelEvent.type,
                description:props.singelEvent.description,
                signUp:props.singelEvent.signUp,
                startDate: props.singelEvent.startDate ? new Date(props.singelEvent.startDate) : "",
                endDate: props.singelEvent.endDate ? new Date(props.singelEvent.endDate) : "",
                link:props.singelEvent.link,
                date: props.singelEvent.date ? new Date(props.singelEvent.date) : ""
              }

              this.setState({formData})
        }    
    }

    handleFormChange = event => {
      this.setState({ propertyDescriptionError: false });
      const {formData} = this.state
      if( event.target.name == 'type') {
        formData.link = "";
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
      } else {
        formData[event.target.name] = event.target.value;
        this.setState({ formData });
      }
    };

    handleSubmit = () => {
      this.setState({ submitted: true });
      // this.props.showLoader(true);
      const { formData} = this.state;
      const data = formData
      console.log('formData', formData);
      if(data['type'] == 'media'){
        delete data["startDate"];
        delete data["endDate"];
        delete data["signUp"];
      }else{
        delete data["date"];
        delete data["link"];
      }
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const ID = urlParams.get('id')
      this.props.updateEvent({ data, id:ID, history: this.props.history });
      setTimeout(() => this.setState({ submitted: false }), 4000);
    };

    handleDate = (date, type) => {
      const { formData } = this.state;
      formData[type] = new Date(date);
      this.setState({ formData });
    };

    handleTimeZone = timeZone => {
      const { formData } = this.state;
      formData['timeZone'] = timeZone;
      this.setState({ formData });
    }

    render() {
        const { handleDate } = this
        return (
            <div className="content">
                <div className="main-container new-event">
                    <div className="agreement-header">
                        <h2 style={{ marginBottom: "20px" }}>Update Event or Media</h2>
                    </div>
                    <ValidatorForm
                      className="validatorListForm"
                      onSubmit={this.handleSubmit}
                    >
                    <Grid container spacing={3}>
                      <Grid item xs={7}>
                        <TextValidator
                          type="text"
                          name="title"
                          label="Title"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          validators={["required",'isOnlySpecialCharater']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.title}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Title can not be empty","Should not contain only special chrachters"]}
                        />
                      </Grid>

                      <Grid item xs={7}>
                          <SelectValidator
                            select
                            type="text"
                            margin="dense"
                            name="type"
                            label="Type"
                            fullWidth
                            variant="outlined"
                            className="MyTextField"
                            validators={["required"]}
                            onChange={this.handleFormChange}
                            value={this.state.formData.type}
                            errorMessages={["type can not be empty"]}
                          >
                            <MenuItem
                              value="event"
                            >
                              Event
                            </MenuItem>
                            <MenuItem
                              value="media"
                            >
                              Media
                            </MenuItem>
                          </SelectValidator>
                      </Grid>

                      {
                      this.state.formData.type == "event"
                      ?
                      <Grid item xs={7}>
                        <TextValidator
                          type="url"
                          name="signUp"
                          label="Sign Up Link"
                          margin="dense"
                          variant="outlined"
                          validators={["required",'validURL']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.signUp}
                          className="MyTextField mx-auto col-md-10"
                          fullWidth
                          errorMessages={["Sign up link can not be empty","Invalid URL"]}
                        />
                      </Grid>
                      :
                      <Grid item xs={7}>
                        <TextValidator
                          name="link"
                          label="Video Link"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                          type="url"
                          validators={["required",'validURL']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.link}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Link can not be empty","Invalid URL"]}
                        />
                      </Grid>
                      }
                      <Grid item xs={7}>
                        <TimezonePicker
                            className="w-100"
                            absolute={false}
                            value={this.state.formData.timeZone}
                            placeholder="Select timezone..."
                            onChange={(value) => this.handleTimeZone(value)}
                          />
                      </Grid>

                      {
                        this.state.formData.type == "event"
                        ?
                        <>
                        <Grid item xs={7}>
                        <DatePicker
                          className="MuiInputBase-root MuiOutlinedInput-input MuiInputBase-input"
                          onChange={(date) => handleDate(date,'startDate')}
                          selected={this.state.formData.startDate}
                          placeholderText="Start Date"
                          showTimeSelect
                          dateFormat="MM-DD-YYYY"
                          dateFormat="Pp"
                          />
                        </Grid>

                        <Grid item xs={7}>
                        <DatePicker
                          className="MuiInputBase-root MuiOutlinedInput-input MuiInputBase-input"
                          onChange={(date) => handleDate(date,'endDate')}
                          selected={this.state.formData.endDate}
                          placeholderText="End Date"
                          showTimeSelect
                          dateFormat="Pp"
                          />
                        </Grid>
                        </>
                        :
                        <Grid item xs={7}>
                          <DatePicker
                          className="MuiInputBase-root MuiOutlinedInput-input MuiInputBase-input"
                          onChange={(date) => handleDate(date,'date')}
                          selected={this.state.formData.date}
                          placeholderText="Webinar date"
                          showTimeSelect
                          dateFormat="Pp"
                          />
                        </Grid>

                      }

                      <Grid item xs={12}>
                        <TextValidator
                          type="text"
                          name="description"
                          label="Description"
                          margin="dense"
                          variant="outlined"
                          validators={["required",'isOnlySpecialCharater']}
                          onChange={this.handleFormChange}
                          value={this.state.formData.description}
                          className="MyTextField mx-auto col-md-10"
                          errorMessages={["Description can not be empty","Should not contain only special chrachters"]}
                        />
                      </Grid>



                    </Grid>

                    <Button
                      type="submit"
                      className="btn-info"
                      style={{ width: "210px", marginTop: "10px" }}
                      disabled={this.props.buttonLoader}
                    >
                      {!this.props.buttonLoader ? (
                        "Update Event"
                      ) : (
                        <i
                          style={{ color: "white" }}
                          className="fa fa-spinner fa-spin fa-1x fa-fw"
                        ></i>
                      )}
                    </Button>

                    </ValidatorForm>

                </div>
            </div>
        );
    }
}

const mapStateToProps = ({Auth}) => {
    return {
        singelEvent: Auth.singelEvent,
    }
}

const mapDispatchToProps = { getSingleEvent, updateEvent };
export default connect(mapStateToProps, mapDispatchToProps)(UpdateEvent);