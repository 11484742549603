import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getDashStats } from '../../../store/actions/Auth'
import { startLoader, stopLoader } from "../../../store/actions/Tokinize";

// core components
import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
  getDashData
} from "variables/charts.jsx";
import "./dashboard.css"
import { connect } from "react-redux";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      isLoader:true,
    };
    props.startLoader(`Fetching Dashboard Data`);
  }
  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };

  componentDidMount(){
    this.props.getDashStats()
    getDashData()
    setTimeout(() => {
      this.props.stopLoader()
      this.setState({isLoader:false})
    }, 5000);
  }

  render() {
    const totalTokenSold = this.props.dashboardStats ? this.props.dashboardStats.propertiesSoldTokens.totalTokenSold: ""
    const totalWithdrawal = this.props.dashboardStats ? this.props.dashboardStats.withdrawnAmountWeekly.totalWithdrawal: ""
    const totalDeposit = this.props.dashboardStats ? this.props.dashboardStats.depositedAmountWeekly.totalDeposit: ""
    return (
        <div className="content">
          {this.state.isLoader ? 
          "loading"
          : 
            <>
              <Row>
                <Col xs="12">
                  <Card className="card-chart">
                    <CardHeader>
                      <Row>
                        <Col className="text-left" sm="6">
                          <CardTitle tag="h2">User Register</CardTitle>
                        </Col>
                        <Col sm="6">
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="chart-area">
                        <Line
                          data={chartExample1[this.state.bigChartData]}
                          options={chartExample1.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col lg="4">
                  <Card className="card-chart">
                    <CardHeader>
                      <h5 className="card-category">Withdrawn Amount</h5>
                      <CardTitle tag="h3">
                        <i className="tim-icons icon-bell-55 text-info" />{" "}
                        {totalWithdrawal}$
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="chart-area">
                        <Line
                          data={chartExample2.data}
                          options={chartExample2.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-chart">
                    <CardHeader>
                      <h5 className="card-category">Token Sold</h5>
                      <CardTitle tag="h3">
                        <i className="tim-icons icon-delivery-fast text-primary" />{" "}
                        {totalTokenSold}
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="chart-area">
                        <Bar
                          data={chartExample3.data}
                          options={chartExample3.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-chart">
                    <CardHeader>
                      <h5 className="card-category">Deposited Amount</h5>
                      <CardTitle tag="h3">
                        <i className="tim-icons icon-send text-success" />
                        {totalDeposit}$
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <div className="chart-area">
                        <Line
                          data={chartExample4.data}
                          options={chartExample4.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  let { dashboardStats } = state.Auth;
  return { dashboardStats };
}

const mapDispatchToProps = { getDashStats, startLoader, stopLoader }


export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
