import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getOwnerPropertiesList } from "../../../store/actions/PropertyOwner";

import './listedProperties.css'

class ListedProperties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.getOwnerPropertiesList()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleListProperty = () => this.props.history.push("/home/list-property")

  handleViews = (e) => window.open(e, '_blank')
  launchSTO = (symbol, id) => this.props.history.push(`/home/launch-sto?symbol=${symbol}&id=${id}`);
  tokenizeProperty = (id, name) => this.props.history.push(`/home/tokenize?id=${id}&name=${name}`);
  viewDetails = (property) => {
    let query = `id=${property['_id']}&status=${property['status']}`;
    if (property['tokenId'] && property['tokenId']['symbol'])
      query = query.concat(`&symbol=${property['tokenId']['symbol']}`)
    this.props.history.push(`/home/property-details?${query}`);
  }

  render() {
    const columns = [{
      Header: 'Title',
      accessor: 'title',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Stage',
      accessor: 'stage',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'NOI %',
      accessor: 'noiPercent',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'City',
      accessor: 'city',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Country',
      accessor: 'country',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: 220,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
            <Button onClick={() => this.viewDetails(props.original)} className="btn-info action-btn action-btn-outline-info">
              View
              </Button>
          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 style={{ marginBottom: "20px" }}>Listed Properties</h2>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12" style={{ display: "flex", justifyContent: 'flex-end' }}>
              <Button onClick={() => this.handleListProperty()} className="add-btn" style={{ background: 'royalblue' }}>
                Add Property
              </Button>
            </div>
          </div>

          {this.props.ownerPropertiesList.length > 0
            && <ReactTable
              data={this.props.ownerPropertiesList}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ PropertyOwner }) => {
  let { ownerPropertiesList } = PropertyOwner;
  return { ownerPropertiesList }
}

const mapDispatchToProps = { getOwnerPropertiesList };
export default connect(mapStateToProps, mapDispatchToProps)(ListedProperties);
