import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getAllSupportTickets, deleteTicket } from "../../../store/actions/Auth";
import { startLoader, stopLoader } from "../../../store/actions/Tokinize";

import moment from 'moment';

import './Support.css'

class Support extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.startLoader(`Fetching Tickets`);
    props.getAllSupportTickets()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleViews = (e) => window.open(e, '_blank')

  viewDetails = (user) => {
    let query = `id=${user['_id']}`;
    this.props.history.push(`/home/support-details?${query}`);
  }

  deleteTicket = (user) => {
    this.props.startLoader(`Deleting Ticket`);

    let query = `id=${user['_id']}`;
    this.props.deleteTicket(user['_id'])
    // this.props.history.push(`/home/support-details?${query}`);
  }


  render() {
    const columns = [{
      Header: 'Name',
      accessor: 'name',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Email',
      accessor: 'email',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Contact Type',
      accessor: 'contactType',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Issue type',
      accessor: 'issueType',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Message',
      accessor: 'message',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          {moment(props.original.createdAt).format('MMM Do YYYY')}
          </span>
        )
      }
    },
    {
      Header: '',
      style: {
        display: "flex",
        justifyContent: "center",
      },
      width: 220,
      minWidth: 120,
      maxWidth: 250,
      sortable: false,
      filterable: false,
      resizable: false,
      Cell: props => {
        return (
          <span style={{ display: "flex" }}>
          <Button onClick={() => this.deleteTicket(props.original)} className="btn-info action-btn action-btn-outline-info">
          Delete
          </Button>
          <Button onClick={() => this.viewDetails(props.original)} className="btn-info action-btn action-btn-outline-info">
          View
          </Button>
          </span>
        )
      }
    }
    ]

    return (
      <div className="content">
        <div className="main-container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <h2 style={{ marginBottom: "20px" }}>Support Tickets</h2>
            </div>
          </div>

          {this.props.allSupportTickets !== ''
            && <ReactTable
              data={this.props.allSupportTickets}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { allSupportTickets } = Auth;
  return { allSupportTickets }
}

const mapDispatchToProps = { getAllSupportTickets, deleteTicket, startLoader };
export default connect(mapStateToProps, mapDispatchToProps)(Support);
