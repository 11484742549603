import routes from "routes/PropertyOwnerRoutes.js";
import EventBus from 'eventing-bus';
import { connect } from "react-redux";
import React, { Component } from "react";
import logo from "assets/img/react-logo.png";
import PerfectScrollbar from "perfect-scrollbar";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import { Route, Switch, withRouter } from "react-router-dom";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.jsx";

var ps;

class PorpertyOwner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundColor: "blue",
      sidebarOpened: document.documentElement.className.indexOf("nav-open") !== -1
    };
  }

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel, { suppressScrollX: true });
      let tables = document.querySelectorAll(".table-responsive");
      for (let i = 0; i < tables.length; i++)
        ps = new PerfectScrollbar(tables[i]);
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      if (navigator.platform.indexOf("Win") > -1) {
        let tables = document.querySelectorAll(".table-responsive");
        for (let i = 0; i < tables.length; i++)
          ps = new PerfectScrollbar(tables[i]);
      }
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    this.setState({ sidebarOpened: !this.state.sidebarOpened });
  };

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/home") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else return null;
    });
  };

  handleBgClick = color => this.setState({ backgroundColor: color });

  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (this.props.location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1)
        return routes[i].name;
    }
    return "Brand";
  };

  render() {
    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={routes}
          bgColor={this.state.backgroundColor}
          logo={{
            outterLink: "",
            text: "Tokenism",
            imgSrc: logo
          }}
          toggleSidebar={this.toggleSidebar}
        />
        <div
          className="main-panel"
          ref="mainPanel"
          data={this.state.backgroundColor}
        >
          <div hidden={!this.props.loader} className="loader-container">
            <div className='loader-content'>
              <i style={{ color: "white" }} className="fa fa-spinner fa-spin fa-1x fa-fw"></i>
              <p style={{ color: "white", fontSize: "16px", marginTop: "20px" }}>{this.props.loadingMessage}</p>
            </div>
          </div>

          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            toggleSidebar={this.toggleSidebar}
            sidebarOpened={this.state.sidebarOpened}
          />
          <Switch>{this.getRoutes(routes)}</Switch>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loader: state.Tokenize.loading,
    loadingMessage: state.Tokenize.loadingMessage
  }
}
export default connect(mapStateToProps, null)(PorpertyOwner);
