import Files from "react-files";
import { Button } from "reactstrap";
import EventBus from "eventing-bus";
import { connect } from "react-redux";
import RichTextEditor from "react-rte";
import React, { Component } from "react";
import { MenuItem, Grid } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { startLoader, stopLoader } from "../../../../store/actions/Tokinize";
import { getSinglePropertyRequest, showLoader } from "../../../../store/actions/Auth";
import { propertyStep3 } from "../../../../store/actions/Property";

// import "./listNewPropertySteps.css";
var url = require("url");

class Step3 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      step: 1,
      allImages:[],
      allDocuments:[],
      formData: {
      },
      propertyDescriptionError: false,
      inputs: ["input-0"],
      passwordValid: true,
      sections: { value1: RichTextEditor.createEmptyValue() }
    };
  }

  componentDidMount() {
    const urlObj = url.parse(document.location.href, true);
    if (
      urlObj.query.id !== undefined &&
      urlObj.query.id !== null &&
      urlObj.query.id !== ""
    ) {
      this.setState({id:urlObj.query.id})
      console.log('urlObj.query.id', urlObj.query.id);
    }
  }

  componentWillReceiveProps(nextProps) {
    // if (nextProps.singlePropertyRequest)
      // this.setSinglePropertyRequest(nextProps.singlePropertyRequest);
  }

  handleFormChange = event => {
    this.setState({ propertyDescriptionError: false });
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  handleSubmit = () => {
    console.log("state :: ", this.state);
    this.setState({ submitted: true });
// historyGraph
    if (
      this.state.cardImageData === undefined ||
      this.state.cardImageData === ""
    )
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Property Image required"
      });
      else if (
        this.state.stackImageData === undefined ||
        this.state.stackImageData === ""
      )
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Stack Graph Image required"
      });
    else if (
      this.state.imageCrouselData === undefined ||
      this.state.imageCrouselData.length === 0
    )
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Atleast 1 Property Carousal image required"
      });
    else if (
      this.state.documentData === undefined ||
      this.state.documentData.length === 0
    )
      EventBus.publish("handleAlert", {
        auth: false,
        message: "Atleast 1 Property Document required"
      });
    else {
      this.props.showLoader(true);
      let data = new FormData();
      let { formData } = this.state;
      data.append(`images`, this.state.cardImageData);
      data.append(`images`, this.state.stackImageData);

      this.state.imageCrouselData.forEach(image => data.append(`images`, image));
      this.state.documentData.forEach(doc => data.append(`images`, doc));

      this.props.startLoader(`Uploading Property Data`);

      this.props.propertyStep3({ data, history: this.props.history, id:this.state.id });
    }
    setTimeout(() => this.setState({ submitted: false }), 4000);
  };

  handleDocumentList = e => {
    // console.log("e :: ", e);
    console.log('e is', e);
    var allDocuments = this.state.allDocuments
    var newList = e.map((x,idx,z)=>{
      if (idx === z.length - 1){
        allDocuments.push(x)
      }
    })
    this.setState({ allDocuments: allDocuments});
    this.handleDocumentFormating(allDocuments)
  };

  handleDocumentFormating = e =>{
    var documentListt = [];
    e.forEach((i, idx, x) => {
      documentListt[idx] = (
        <div className="singleImage" id={idx}>
          <p className="documentName">{i.name}</p>
          <button
            onClick={() => {
              this.handleDocumentListRemove(idx);
            }}
            style={{ fontSize: "14px" }}
            className="btn btn-danger ml-auto iconButton"
            >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    });
    this.setState({ documentList: documentListt, documentData: e });
  }

  handleImageList = e => {
    console.log('e is', e);
    var allImages = []
    e.map((x,idx,z)=>{
      // if (idx === z.length - 1){
        allImages.push(x)
      // }
    })
    this.setState({ allImages });
    console.log(allImages);

    this.handleImageFormating(allImages)
  };

  handleImageFormating = e =>{
    var imageListt = [];
    console.log('e formating', e);
    e.forEach((i, idx, x) => {
      i = URL.createObjectURL(i);
      imageListt[idx] = (
        <div className="singleImage" id={idx}>
          <img className="imageList" src={i} />
          <button
            onClick={() => {
              this.handleImageListRemove(idx);
            }}
            style={{ fontSize: "14px" }}
            className="btn btn-danger ml-auto iconButton"
          >
            <i className="fa fa-trash" />
          </button>
        </div>
      );
    });
    this.setState({ imageList: imageListt, imageCrouselData: e });
  }

  handleDocumentListRemove = e => {
    console.log(e, this.state.documentList);
    var documentsList = this.state.documentList
    var allDocuments = this.state.allDocuments
    this.state.documentList.filter(value => {
      console.log('value', value);
      if(value.props.id == e){
        documentsList.splice(documentsList.indexOf(value), 1);
      }
    });
    this.state.allDocuments.filter((x,idx,z) => {
      if(idx == e){
        allDocuments.splice(allDocuments.indexOf(x), 1);
      }
    });
    console.log('documentsList', documentsList);
    this.setState({ documentList: documentsList, allDocuments:allDocuments });
  };

  handleImageListRemove = e => {
    console.log(e, this.state.imageList);
    var imagesList = this.state.imageList
    var allImages = this.state.allImages
    this.state.imageList.filter(value => {
      console.log('value', value);
      if(value.props.id == e){
        imagesList.splice(imagesList.indexOf(value), 1);
      }
    });
    this.state.allImages.filter((x,idx,z) => {
      if(idx == e){
        allImages.splice(allImages.indexOf(x), 1);
      }
    });
    console.log('imagesList', imagesList);
    this.setState({ imageList: imagesList, allImages:allImages });

    // var filter = this.state.documentList.filter(value => value.props.id != e);
    // this.setState({ documentList: filter });
  };

  handleCategoryImage = e => {
    var imageValue = e.length - 1;
    this.setState({ imageValue: URL.createObjectURL(e[imageValue]) });
    var imageDetail = e[0];
    this.setState({ cardImageData: imageDetail });
  };

  handleStackImage = e => {
    var stackImageValue = e.length - 1;
    this.setState({ stackImageValue: URL.createObjectURL(e[stackImageValue]) });
    var imageDetail = e[0];
    this.setState({ stackImageData: imageDetail });
  };

  onFilesError = e => EventBus.publish("error", e.message);
  handleRemoveImage = () => this.setState({ imageValue: "" });
  handleRemoveStackImage = () => this.setState({ stackImageValue: "" });

  render() {
    return (
      <div className="content">
        <div className="main-container">
        <hr />
        <h4 className="divider-heading">Top Banner</h4>
        {this.state.imageValue !== "" &&
          this.state.imageValue !== undefined ? null : (
            <Files
              onChange={e => this.handleCategoryImage(e)}
              style={{ backgroundColor: "#f4f4f4" }}
              onError={this.onFilesError}
              accepts={["image/*"]}
              className="dropZone"
              maxFileSize={10000000}
              minFileSize={0}
              clickable
            >
              <div className="activities-form-cardimage-dropzone-text">
                <p>Drop the Top Banner here or click to upload it.</p>
                <p>
                  Recommended <strong>Height</strong> of at least{" "}
                  <strong>390px</strong>.
              </p>
              </div>
            </Files>
          )}

        {this.state.imageValue !== "" &&
          this.state.imageValue !== undefined ? (
            <div className="singleImage">
              <img className="imageList" src={this.state.imageValue} />
              <button
                onClick={() => {
                  this.handleRemoveImage();
                }}
                style={{ fontSize: "14px" }}
                className="btn btn-danger ml-auto iconButton"
              >
                <i className="fa fa-trash" />
              </button>
            </div>
          ) : null}

          <hr />

          <h4 className="divider-heading">Stack Graph</h4>
          {this.state.stackImageValue !== "" &&
            this.state.stackImageValue !== undefined ? null : (
              <Files
                onChange={e => this.handleStackImage(e)}
                style={{ backgroundColor: "#f4f4f4" }}
                onError={this.onFilesError}
                accepts={["image/*"]}
                className="dropZone"
                maxFileSize={10000000}
                minFileSize={0}
                clickable
                >
                <div className="activities-form-cardimage-dropzone-text">
                  <p>Drop the Stack Graph here or click to upload it.</p>
                  <p>
                    Recommended <strong>Height</strong> of at least{" "}
                    <strong>390px</strong>.
                    </p>
                  </div>
                </Files>
              )}

              {this.state.stackImageValue !== "" &&
                this.state.stackImageValue !== undefined ? (
                  <div className="singleImage">
                    <img className="imageList" src={this.state.stackImageValue} />
                    <button
                      onClick={() => {
                        this.handleRemoveStackImage();
                      }}
                      style={{ fontSize: "14px" }}
                      className="btn btn-danger ml-auto iconButton"
                      >
                      <i className="fa fa-trash" />
                    </button>
                  </div>
                ) : null}

                <hr />

        <h4 className="divider-heading" style={{ marginTop: "15px" }}>
          Property Carousal Images
        </h4>

        <div>
          <Files
            style={{ backgroundColor: "#f4f4f4" }}
            onChange={e => this.handleImageList(e)}
            value={this.state.imageList}
            onError={this.onFilesError}
            className="dropZone"
            accepts={["image/*"]}
            maxFileSize={100000000}
            minFileSize={0}
            clickable
          >
            <div>
              <p>Drop the Property Image here or click to upload it.</p>
              <p>
                Recommended <strong>Height</strong> of at least{" "}
                <strong>390px</strong>.
              </p>
            </div>
          </Files>
        </div>
        <div className="imageListContainer">{this.state.imageList}</div>

        <hr />
        <h4 className="divider-heading" style={{ marginTop: "15px" }}>
          Documents
        </h4>

        <div>
          <Files
            style={{ backgroundColor: "#f4f4f4" }}
            onChange={e => this.handleDocumentList(e)}
            onError={this.onFilesError}
            className="dropZone"
            accepts={["application/pdf"]}
            maxFileSize={100000000}
            minFileSize={0}
            clickable
          >
            <div>
              <p>
                Drop the Property Documents here or click to upload it.
              </p>
              <p>
                Recommended maximum <strong>Size</strong> is{" "}
                <strong>25MB</strong>.
              </p>
            </div>
          </Files>
        </div>
        <div className="imageListContainer">
          {this.state.documentList}
        </div>
        <div className='row'>
          <div className='col-6'>
          </div>
          <div className='col-6 text-right'>
            <Button
              className="btn-info col-4"
              type="submit"
              onClick={()=>this.handleSubmit()}
              >
              Next
            </Button>
          </div>
        </div>
    </div>
  </div>

    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { singlePropertyRequest, listedProperties, buttonLoader } = Auth;
  return { singlePropertyRequest, listedProperties, buttonLoader };
};

const mapDispatchToProps = { showLoader, propertyStep3, getSinglePropertyRequest, startLoader, stopLoader };

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
