import { Button } from 'reactstrap'
import { connect } from "react-redux"
import React, { Component } from "react"
import NotificationAlert from "react-notification-alert"
import { saveUserData, login, loginRequest } from "./../../../store/actions/Auth"
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import Web3 from "web3"
import EventBus from "eventing-bus"

import './login.css'

class Login extends Component {
  constructor() {
    super();
    this.state = {
      pageContent: "",
      isDisabled: false,
      metaFound: false,
      formData: {
        email: '',
        password: ''
      }
    }
  }

  handleLogin = async () => {
    if (window.ethereum) {
      console.log('metafound');
      window.web3.currentProvider
        .enable()
        .then(response => {
          window.web3.version.getNetwork((err, netId) => {
            console.log("*************netId", netId);
            console.log("*************process['env']['REACT_APP_NETWORK_ID']", process['env']['REACT_APP_NETWORK_ID']);
            if (netId == process['env']['REACT_APP_NETWORK_ID']) {
              this.props.loginRequest({
                data: response[0],
                history: this.props.history
              });
            } else {
              EventBus.publish(
                "info",
                `Please switch to ${process['env']['REACT_APP_NETWORK']} network before login`
                // "Please switch to kovan network before login"
              );
            }
          });
        })
        .catch(reason => {
          this.setState({ isLoading: true });
          if (reason === "User rejected provider access")
            alert("Login Request Rejected");
          else EventBus.publish("error", `There was an issue signing you in.`);
        });
      //
      // this.props.loginRequest({
      //   data: address,
      //   history: this.props.history
      // });
    } else {
      EventBus.publish("error", `You need to install Metamask First.`);
      console.log('install meta');
    }

    // this.setState({isDisabled: true});
    // this.props.login({data: this.state.formData, history: this.props.history});
    // setTimeout(()=> this.setState({isDisabled: false}), 2000);
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  render() {
    return (
      <div className="AdminLogin">
        <div className="react-notification-alert-container">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="login-metamask-div">
              <h1 className="title"> Login With MetaMask </h1>
              <h4 className="sub pt-3"> To Login you need to have MetaMask Extension </h4>
              <div className="pt-3">
                <Button
                  color='info'
                  className=''
                  type="button"
                  disabled={this.state.isDisabled}
                  style={{ width: "70%" }}
                  onClick={this.handleLogin}
                >
                  {!this.state.isDisabled ? 'Connect MetaMask' : <i className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                </Button>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="image-banner-area">
              {/*
                    <video width="200%" muted={true} autoPlay style={{marginLeft: "-30%"}}>
                      <source src="https://tknismtest.s3.amazonaws.com/webImages/Tokenism_Update.mp4" />
                    </video>
                  */}
              <figure><img style={{ width: "400px", height: "400px" }} src={require("assets/icons/Logo.png")} alt="" /></figure>

            </div>
          </div>
        </div>


        {/*
          <div className="loginDiv">
            <div className="loginFormDiv">
              <p className="loginTitle">Login</p>
              <hr style={{marginBottom:"30px"}}/>
              <ValidatorForm className="validatorForm" onSubmit={this.handleLogin}>
                <TextValidator
                  className="MyTextField"
                  label="Email"
                  onChange={this.handleFormChange}
                  name="email"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  validators={['required', 'isEmail']}
                  errorMessages={['Email can not be empty', 'Email is not valid']}
                  value={this.state.formData.email}
                />

                <TextValidator
                  className="MyTextField"
                  label="Password"
                  onChange={this.handleFormChange}
                  name="password"
                  type="password"
                  margin="dense"
                  variant="outlined"
                  validators={['required']}
                  errorMessages={['Password can not be empty']}
                  value={this.state.formData.password}
                />

                <div className="loginBtnDiv">
                  <Button
                    color='info'
                    className=''
                    type="submit"
                    disabled={this.state.isDisabled}
                    style={{ width:"75%" }}
                  >
                    {!this.state.isDisabled ? 'Login' : <i className="fa fa-spinner fa-spin fa-1x fa-fw"/>}
                  </Button>

                </div>
              </ValidatorForm>
            </div>
          </div>
        */}

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    authToken: state.Auth.authToken,
  }
}

const mapDispatchToProps =
{
  login,
  saveUserData,
  loginRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
