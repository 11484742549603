export const initialize = () => {
  console.log("initializing ..")
  return {
    type: 'INITALIZING'
  }
};

export const initialized = (data) => {
  console.log("initialized .. ", data)
  return {
    type: 'INITIALIZED',
    payload: data
  }
};

export const checkSymbol = (data) => {
  return {
    type: 'CHECK_SYMBOL',
    payload: data
  }
};

export const checkSymbolRes = (data) => {
  return {
    type: 'CHECK_SYMBOL_RESPONSE',
    payload: data
  }
};

export const fetchingReservation = () => {
  console.log("fetchingReservation .. ")
  return {
    type: 'FETCHING_RESERVATIONS',
  }
};

export const fetchedReservation = (data) => {
  console.log("fetchedReservation .. ", data)
  return {
    type: 'FETCHED_RESERVATIONS',
    payload: data
  }
};

export const reservingSymbol = () => {
  console.log("reservingSymbol .. ")
  return {
    type: 'RESERVING_SYMBOL',
  }
};

export const reservedSymbol = () => {
  console.log("reservedSymbol .. ")
  return {
    type: 'RESERVED_SYMBOL',
  }
};

export const creatingToken = () => {
  console.log("creatingToken .. ")
  return {
    type: 'CREATING_TOKEN',
  }
};

export const createdToken = () => {
  console.log("createdToken .. ")
  return {
    type: 'CREATED_TOKEN',
  }
};
export const isError = () => {
  console.log("isError .. ")
  return {
    type: 'ERROR',
  }
};

export const fetchingTokens = () => {
  console.log("fetchingTokens .. ")
  return {
    type: 'FETCHING_TOKENS',
  }
};

export const fetchedTokens = (data) => {
  console.log("fetchedTokens .. ", data)
  return {
    type: 'FETCHED_TOKENS',
    payload: data
  }
};

export const startLoader = (data) => {
  console.log("startLoader .. ")
  return {
    type: 'START_LOADER',
    payload: data
  }
};

export const stopLoader = () => {
  return {
    type: 'STOP_LOADER'
  }
};

export const fetchedToken = (data) => {
  console.log("fetchedToken .. ", data)
  return {
    type: 'FETCHED_TOKEN',
    payload: data
  }
};

export const creatingSTO = () => {
  console.log("creatingSTO .. ")
  return {
    type: 'CREATING_STO',
  }
};

export const createdSTO = () => {
  console.log("createdSTO .. ")
  return {
    type: 'CREATED_STO',
  }
};

export const finalizeSTO = (data) => ({
  type: 'FINALIZE_STO',
  payload: data,
});
