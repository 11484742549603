import React, { Component } from "react";
import { Button } from "reactstrap";
import Files from "react-files";
import { connect } from "react-redux";
class EditPropertyImages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      banner: props.propertyDetails.banner.location,
      carouselImages: props.propertyDetails.carousel,
      isLoading: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({ isLoading: false });
    this.setState({
      carouselImages: props.propertyDetails.carousel,
      banner: props.propertyDetails.banner.location
    });
  }

  handleCategoryImage = e => {
    this.setState({ isLoading: true });
    this.props.onUpload(e, "banner");
  };

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit });
  };

  handleRemoveImage = () => {
    this.setState({ isLoading: true })
    this.props.onCarouselDelete(
      [{ location: this.props.propertyDetails.banner.location }],
      "banner"
    );
  };

  handleImageList = e => {
    var carouselData = [];

    // if (this.state.carouselImages.length !== 0)
    e.forEach((i, idx, x) => {
      carouselData.push(i);
    });
    this.setState({ isLoading: true });
    this.props.onUpload(carouselData, "carousel");
  };

  handleImageListRemove = e => {
    // var filter = this.state.carouselImages.filter(
    //   value => value.location !== e
    // );
    var filterReverse = this.state.carouselImages.filter(
      value => value.location == e
    );
    // this.setState({ carouselImages: filter });
    this.setState({ isLoading: true });
    this.props.onCarouselDelete(filterReverse, "carousel");
  };

  render() {
    const { propertyDetails } = this.props;
    const { banner, carouselImages } = this.state;

    return (
      <div>
        <div hidden={!this.state.isLoading} className="loader-edit-property">
          <p className="loader-edit-property-text"> <i className='fa fa-spinner fa-spin fa-1x fa-fw'></i> Loading, Please Wait... </p>
        </div>
        <div className="property-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              style={{ fontWeight: "650", margin: "0px", marginRight: "20px" }}
            >
              Images Details
            </h4>
          </div>

          <Button onClick={() => this.props.onSubmit(this.state)}>
            {" "}
            Done{" "}
          </Button>
        </div>

        <div className="property-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              style={{ fontWeight: "650", margin: "0px", marginRight: "20px" }}
            >
              Property Banner
            </h4>
          </div>

          <Files
            onChange={e => this.handleCategoryImage(e)}
            style={{ backgroundColor: "#f4f4f4" }}
            onError={this.onFilesError}
            accepts={["image/*"]}
            className="dropZoneEdit"
            maxFileSize={10000000}
            minFileSize={0}
            clickable
          >
            <div className="activities-form-cardimage-dropzone-text">
              Change Banner
            </div>
          </Files>
        </div>

        {banner !== "" && banner !== undefined ? (
          <div className="single-row">
            {/* <div className="modal-content-title">Banner Image</div> */}
            <div className="modal-content-data" style={{ display: "flex" }}>
              <img src={banner} alt="..." style={{ width: "90%" }} />
              {/* <button
                onClick={() => {
                  this.handleRemoveImage();
                }}
                style={{ fontSize: "14px", height: "45px" }}
                className="btn btn-danger ml-auto iconButton"
              >
                <i className="fa fa-trash" />
              </button> */}
            </div>
          </div>
        ) : null}

        <div className="property-header">
          <div style={{ display: "flex", alignItems: "center" }}>
            <h4
              style={{ fontWeight: "650", margin: "0px", marginRight: "20px" }}
            >
              Carousel Images
            </h4>
          </div>

          <Files
            style={{ backgroundColor: "#f4f4f4" }}
            onChange={e => this.handleImageList(e)}
            onError={this.onFilesError}
            className="dropZoneEdit"
            accepts={["image/*"]}
            maxFileSize={100000000}
            minFileSize={0}
            clickable
          >
            <div>Upload Carousel</div>
          </Files>
        </div>

        {carouselImages !== null && carouselImages !== undefined ? (
          <div className="single-row">
            {/* <div className="modal-content-title">Carousel Images</div> */}
            <div className="modal-content-data">
              {carouselImages.map((image, idx) => (
                <>
                  <img
                    className="carousel-edit-image"
                    src={image.location}
                    alt={image.name}
                  />
                  <button
                    onClick={() => {
                      this.handleImageListRemove(image.location);
                    }}
                    style={{ fontSize: "14px", height: "45px" }}
                    className="btn btn-danger ml-auto iconButton"
                  >
                    <i className="fa fa-trash" />
                  </button>
                </>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  let { singlePropertyDetails } = state.Auth;
  return { propertyDetails: singlePropertyDetails['property'] };
};

export default connect(mapStateToProps, null)(EditPropertyImages);
