import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { uploadWithHoldingTax, editUserInfo } from "./../../store/actions/Auth";
import { TKUSDAddress, ST, WhitelistAddress, WhitelistABI, network } from './../../store/contract';
import { web3 } from './../../store/web3';

import { startLoader, stopLoader } from "../../store/actions/Tokinize";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import NumberFormat from 'react-number-format';
import EventBus from 'eventing-bus';

import './userTabs.css';
class UserSettings extends Component {
  state = {
    showEdit: false,
    taxWitholding: "",
    showModalWithholding:false,
    showModalUserInfo:false,
    showModalSuspendUser:false,
    isValidCity:true,
    isValidZip:true,
    isValidAddress:true,
    firstName:'',
    lastName:'',
    state:'',
    city:'',
    address:'',
    zipCode:'',
    securityNumber:'',
    activeUser:false,
    publicAddress:'',
    firstLoad:true
  }

  componentDidMount (){
    console.log('in componentDidMount');
    // setTimeout( () => {
    // }, 15000);

  }

  isWhiteListedUser =async (publicAddress) =>{
    console.log('in isWhiteListedUser', publicAddress, WhitelistAddress);
    if(publicAddress){
      const Whitelist = new web3.eth.Contract(WhitelistABI, WhitelistAddress);

      try {
        console.log('in try catch try');
        // this.props.startLoader(`Getting User details`);
        let userCode = await Whitelist.methods.isWhitelistedUser(publicAddress).call();
        console.log('**********userCode = ', userCode);
        this.setState({userCode:userCode})
        if(userCode == 200 || userCode == 201) this.setState({activeUser:true})
        else if(userCode == 401) this.setState({activeUser:false})
        this.props.stopLoader()
      } catch (e) {
        console.log('in catch ', e);

        this.props.stopLoader()
        // EventBus.publish("error", `Getting User details`);
      }
    }
  }

  handleSuspendUser = async() =>{
    console.log('in handleSuspendUser', this.state.publicAddress);
    if(this.state.publicAddress){
      const Whitelist = new web3.eth.Contract(WhitelistABI, WhitelistAddress);
      this.toggleSuspendUser()

      try {
        console.log('in try catch try');
        this.props.startLoader("Suspending User")
        let from = ((await web3.currentProvider.enable())[0]).toLowerCase();
        let userCode = await Whitelist.methods.suspendUser(this.state.publicAddress).send({ from }).on('error', ()=> this.props.stopLoader())
        console.log('**********userCode = ', userCode);
        this.setState({userCode:userCode, activeUser:false})
        EventBus.publish("success", `User Suspended`);

        this.props.stopLoader()
      } catch (e) {
        console.log('in catch ', e);
        EventBus.publish("error", `Cannot suspend user at this time`);
        this.props.stopLoader()
      }
    }
  }

  handleActivateUser = async () =>{
    console.log('in handleActivateUser', this.state.publicAddress);
    if(this.state.publicAddress){
      const Whitelist = new web3.eth.Contract(WhitelistABI, WhitelistAddress);
      this.toggleSuspendUser()
      this.props.startLoader("Activating User")
      try {
        console.log('in try catch try');
        let from = ((await web3.currentProvider.enable())[0]).toLowerCase();
        let userCode = await Whitelist.methods.activeUser(this.state.publicAddress).send({ from }).on('error', ()=> this.props.stopLoader())
        console.log('**********userCode = ', userCode);
        this.setState({userCode:userCode, activeUser:true})
        EventBus.publish("success", `User Activated`);

        this.props.stopLoader()
      } catch (e) {
        console.log('in catch ', e);
        EventBus.publish("error", `Cannot activate user at this time`);
        this.props.stopLoader()
      }
    }
  }

  toggleWithholding = () => {
    this.setState({ showModalWithholding: !this.state.showModalWithholding })
  }

  toggleUserInfo = () => {
    this.setState({ showModalUserInfo: !this.state.showModalUserInfo })
  }

  toggleSuspendUser = () => {
    this.setState({ showModalSuspendUser: !this.state.showModalSuspendUser })
  }

  handleEdit = () => this.setState({ showEdit: !this.state.showEdit })

  handleSubmit = (e) => {
    let { userDetails } = this.props;
    const { taxWitholding } = this.state
    const data = { taxWitholding }
    console.log("*********on submit :: ", data, userDetails['_id']);
    this.props.uploadWithHoldingTax({data, id:userDetails['_id']})
  }

  handleSubmitUserInfo = (e) =>{
    let { userDetails } = this.props;
    const { firstName, lastName, city, zipCode, address, securityNumber, state, isValidZip, isValidCity, isValidAddress } = this.state;
    if(firstName && lastName && city && zipCode && address && securityNumber && state && isValidZip && isValidCity && isValidAddress){
      let data =
      { 'firstName': firstName, 'lastName': lastName, 'city': city, 'zipCode': zipCode, 'address': address, 'securityNumber': securityNumber, 'state': state }
      console.log('data', data);
      this.props.editUserInfo({data, id:userDetails['_id']})
      this.props.startLoader(`Updating User Info`);
      this.toggleUserInfo()
    }
  }

  handleChange = (e) => {
      this.setState({[e.target.name]:e.target.value})

      if(e.target.name === 'address'){
        if(e.target.value !== '' && e.target.value !== undefined && !/[^a-zA-Z0-9-,.;'&/.()_#* ]/.test(e.target.value)) this.setState({isValidAddress:true})
        else this.setState({isValidAddress:false})
      }
      if(e.target.name === 'city'){
        if(e.target.value !== '' && e.target.value !== undefined && !/[^a-zA-Z ]/.test(e.target.value)) this.setState({isValidCity:true})
        else this.setState({isValidCity:false})
      }
      if(e.target.name === 'zipCode'){
        if(e.target.value !== '' && e.target.value !== undefined && !/[^a-zA-Z0-9]/.test(e.target.value)) this.setState({isValidZip:true})
        else this.setState({isValidZip:false})
      }
  }

  componentWillReceiveProps(props) {
    if (props.userDetails ){
      this.setState({ firstName: props.userDetails.firstName, lastName: props.userDetails.lastName, city: props.userDetails.city, zipCode: props.userDetails.zipCode, address: props.userDetails.address, securityNumber: props.userDetails.securityNumber, state:props.userDetails.state, publicAddress:props.userDetails.publicAddress})
      console.log('componentWillReceiveProps', props.userDetails);
      if(this.state.firstLoad && props.userDetails.publicAddress){
        this.isWhiteListedUser(props.userDetails.publicAddress)
        this.setState({firstLoad:false})
      }
    }
  }

  // static getDerivedStateFromProps = (nextProps, prevState) => {
  //   if (nextProps.userDetails !== prevState.userDetails) {
  //     console.log('nextProps, prevState', nextProps, prevState);
  //
  //     // let listPropertyStep = 1;
  //     // if (listPropertyStep === 2) return { activeTab: "Step2" };
  //     // else if (listPropertyStep === 3) return { activeTab: "Step3" };
  //   }
  //   else return null; // Triggers no change in the state
  // };

  render() {
    const { showEdit } = this.state;
    const { userDetails } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************userDetails `, userDetails);

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Settings</h4>
            </div>
          </div>
          <Row className="mt-3">
            <Col lg="1">
            </Col>
            <Col lg="3">
              <Card className="card-chart text-center">
                <CardHeader>
                  <CardTitle tag="h2">
                    Edit User
                  </CardTitle>
                </CardHeader>
                <CardBody className="mt-3">
                  <Button
                    className="btn-info"
                    type="button"
                    onClick={this.toggleUserInfo}
                    >
                    Edit
                  </Button>

                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="card-chart text-center">
                <CardHeader>
                  <CardTitle tag="h2">
                    Suspend/Active User
                  </CardTitle>
                </CardHeader>
                <CardBody className="mt-3">
                  <Button
                    className="btn-info"
                    type="button"
                    onClick={this.toggleSuspendUser}
                    >
                    {this.state.activeUser? "Suspend User" : "Activate User"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col lg="3">
              <Card className="card-chart text-center">
                <CardHeader>
                  <CardTitle tag="h2">
                    Edit Withholding Tax
                  </CardTitle>
                </CardHeader>

                <CardBody className="mt-3">
                  <Button
                    className="btn-info"
                    type="button"
                    onClick={this.toggleWithholding}
                    >
                    Edit
                  </Button>

                </CardBody>
              </Card>
            </Col>

          </Row>

          <Modal isOpen={this.state.showModalWithholding} toggle={this.toggleWithholding}>
            <ModalHeader toggle={this.toggleWithholding}></ModalHeader>
            <h3 style={{ margin: "0px 0px 0px 25px" }}>Update WithHolding Tax</h3>
            <ModalBody>

              <ValidatorForm
                className="validatorListForm"
                onSubmit={this.handleSubmit}
                >
                <TextValidator
                  label="Tax with holding"
                  name="taxWitholding"
                  type="number"
                  margin="dense"
                  variant="outlined"
                  className="MyTextField"
                  onChange={this.handleChange}
                  value={this.state.taxWitholding}
                  validators={["required"]}
                  errorMessages={[
                    "Tax with holding can not be empty"
                    ,"Special Chrachters and Numbers are not allowed"
                  ]}
                  />
              </ValidatorForm>
              <hr />
            </ModalBody>
            <ModalFooter>
              <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleSubmit}>
                {
                  this.props.buttonLoader
                  ? 'Updating...'
                  : 'Update'
                }
              </Button>
              <Button color="info" onClick={this.toggleWithholding}>Close</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.showModalSuspendUser} toggle={this.toggleSuspendUser}>
            <ModalHeader toggle={this.toggleSuspendUser}></ModalHeader>
            <h4 style={{ margin: "0px 50px 0px 20px" }}>
              {this.state.activeUser ? "Do you really want to suspend this user ? Suspending a user will restrict him from performing any activity on the website." : "Do you want to activate this suspended user ? Activating will let user perform activities on the platform."}
            </h4>
            <ModalBody>

              <hr />
            </ModalBody>
            <ModalFooter>
              <Button color="info" disabled={this.props.buttonLoader} onClick={this.state.activeUser ? this.handleSuspendUser : this.handleActivateUser}>
                {
                  this.props.buttonLoader
                  ? 'Updating...'
                  : 'Confirm'
                }
              </Button>
              <Button color="info" onClick={this.toggleSuspendUser}>Close</Button>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.showModalUserInfo} toggle={this.toggleUserInfo}>
            <ModalHeader toggle={this.toggleUserInfo}></ModalHeader>
            <h3 style={{ margin: "0px 0px 0px 25px" }}>Update User Info</h3>
            <ModalBody>

              <ValidatorForm
                className="validatorListForm"
                onSubmit={this.handleSubmitUserInfo}
                >
                <TextValidator
                  label="First Name"
                  name="firstName"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  className="MyTextField"
                  onChange={this.handleChange}
                  value={this.state.firstName}
                  validators={["required"]}
                  errorMessages={[
                    "First Name can not be empty"
                    ,"Special Chrachters and Numbers are not allowed"
                  ]}
                  />
                <TextValidator
                  label="Last Name"
                  name="lastName"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  className="MyTextField"
                  onChange={this.handleChange}
                  value={this.state.lastName}
                  validators={["required"]}
                  errorMessages={[
                    "Last Name can not be empty"
                    ,"Special Chrachters and Numbers are not allowed"
                  ]}
                  />
                <TextValidator
                  className="MyTextField"
                  fullWidth
                  label="City"
                  onChange={this.handleChange}
                  name="city"
                  type="text"
                  error={!this.state.isValidCity}
                  margin="dense"
                  variant="outlined"
                  validators={['required']}
                  errormessages={['City can not be empty']}
                  value={this.state.city}
                  autoComplete='off'
                  />
                <TextValidator
                  className="MyTextField"
                  fullWidth
                  inputProps={{ maxLength:20 }}
                  label="Zip/Postal code"
                  onChange={this.handleChange}
                  error={!this.state.isValidZip}
                  name="zipCode"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  validators={['required']}
                  errormessages={['Zip Code can not be empty']}
                  value={this.state.zipCode}
                  autoComplete='off'
                  />
                <TextValidator
                  className="MyTextField"
                  fullWidth
                  label="Address"
                  error={!this.state.isValidAddress}
                  onChange={this.handleChange}
                  name="address"
                  type="text"
                  margin="dense"
                  variant="outlined"
                  validators={['required']}
                  errormessages={['Address Name can not be empty']}
                  value={this.state.address}
                  autoComplete='off'
                  />
                <NumberFormat error={!this.state.isValidNumber} validators={['required']} placeholder="    ___-__-____    " variant="outlined" name='securityNumber' value={this.state.securityNumber}
                  onChange={this.handleChange} className='numberFormat-Form' format="###-##-####" />

              </ValidatorForm>
              <hr />
            </ModalBody>
            <ModalFooter>
              <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleSubmitUserInfo}>
                {
                  this.props.buttonLoader
                  ? 'Updating...'
                  : 'Update'
                }
              </Button>
              <Button color="info" onClick={this.toggleUserInfo}>Close</Button>
            </ModalFooter>
          </Modal>

        </div>

      )
  }
}

const mapDispatchToProps = {
  uploadWithHoldingTax,
  editUserInfo,
  startLoader,
  stopLoader
};

export default connect(null, mapDispatchToProps)(UserSettings);
