import moment from 'moment';
import EventBus from 'eventing-bus';
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getSingleUserDetails } from '../../../store/actions/Auth';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { fetchingTokens, fetchedToken, isError, startLoader, stopLoader } from "../../../store/actions/Tokinize";

// import './propertyDetail.css';
import InvestHistory from '../../../components/SingleUserTabs/InvestHistory';
import DepositHistory from '../../../components/SingleUserTabs/DepositHistory';
import WithdrawHistory from '../../../components/SingleUserTabs/WithdrawHistory';
import UserOverview from '../../../components/SingleUserTabs/UserOverview';
import UserDocuments from '../../../components/SingleUserTabs/UserDocuments';

import UserSettings from '../../../components/SingleUserTabs/UserSettings';
import SendTkusd from '../../../components/SingleUserTabs/SendTkusd';
import WiresTransfer from '../../../components/SingleUserTabs/WiresTransfer';

import UserWallets from '../../../components/SingleUserTabs/UserWallets';
import UserBanks from '../../../components/SingleUserTabs/UserBanks';
import { Button } from "reactstrap";

class UserDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      symbol: '',
      stoTiers: '',
      activeTab: '1',
      stoDetails: '',
      tokenDetails: '',
      propertyDetails: '',
    };
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);

    if (!query['id']) {
      EventBus.publish("error", `Please select user`)
      setTimeout(() => this.props.history.push(`/home/users`), 1000);
    } else {
      this.props.startLoader(`Fetching User Details`);
      this.props.getSingleUserDetails(query['id']);
    }
  }

  componentWillReceiveProps({ userDetail }) {
    console.log(`**************userDetail = `, userDetail);

    if (userDetail) this.setState({ userDetails: userDetail['user'], investHistory: userDetail['investHistory'], depositHistory: userDetail['depositHistory'], withdrawalHistory: userDetail['withdrawalHistory'], wallets: userDetail['wallets'], documents: userDetail['documents'], userBanks: userDetail['banks'], userCards: userDetail['cards'], userWallets: userDetail['wallets'], mintedHistory:userDetail['mintedHistory'] })
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  goBack=()=>{
    this.props.history.goBack();
  }

  render() {
    let { propertyDetails, tokenDetails, stoDetails, activeTab } = this.state;
    return (
      <div className='content'>
        <div className='main-container'>
          <div className="row">
            <div className="col-5">
              <Button onClick={this.goBack} className="btn-info action-btn action-btn-outline-info">
                Back
              </Button>
            </div>
            <div className="col-6">
              <h3 >
                User Details
              </h3>
            </div>
          </div>

          <hr className='py-3' />

          <Nav tabs>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => { this.toggle('1'); }}>
                User Info
              </NavLink>
            </NavItem>
            {tokenDetails &&
              <NavItem style={{ width: "12.5%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => { this.toggle('2'); }}>
                  Security Token
                </NavLink>
              </NavItem>
            }
            {stoDetails &&
              <NavItem style={{ width: "12.5%" }}>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => { this.toggle('3'); }}>
                  Token Offering
                </NavLink>
              </NavItem>
            }
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => { this.toggle('4'); }}>
                Invest History
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '5' })}
                onClick={() => { this.toggle('5'); }}>
                Deposit History
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '6' })}
                onClick={() => { this.toggle('6'); }}>
                Withdraw History
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '7' })}
                onClick={() => { this.toggle('7'); }}>
                User Documents
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '8' })}
                onClick={() => { this.toggle('8'); }}>
                User Account Info
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '9' })}
                onClick={() => { this.toggle('9'); }}>
                User Wallets
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '10' })}
                onClick={() => { this.toggle('10'); }}>
                User Settings
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '11' })}
                onClick={() => { this.toggle('11'); }}>
                Send Tkusd
              </NavLink>
            </NavItem>
            <NavItem style={{ width: "12.5%" }}>
              <NavLink
                className={classnames({ active: activeTab === '12' })}
                onClick={() => { this.toggle('12'); }}>
                Wires Transfer
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <UserOverview {...this.state} />
            </TabPane>
            <TabPane tabId="4">
              <InvestHistory {...this.state} />
            </TabPane>
            <TabPane tabId="5">
              <DepositHistory {...this.state} />
            </TabPane>
            <TabPane tabId="6">
              <WithdrawHistory {...this.state} />
            </TabPane>
            <TabPane tabId="7">
              <UserDocuments {...this.state} />
            </TabPane>
            <TabPane tabId="8">
              <UserBanks {...this.state} />
            </TabPane>
            <TabPane tabId="9">
              <UserWallets {...this.state} />
            </TabPane>
            <TabPane tabId="10">
              <UserSettings {...this.state} />
            </TabPane>
            <TabPane tabId="11">
              <SendTkusd {...this.state} />
            </TabPane>
            <TabPane tabId="12">
              <WiresTransfer {...this.state} />
            </TabPane>
          </TabContent>

          {(propertyDetails !== null && propertyDetails !== undefined && propertyDetails !== '') && <div className="modal-container" />}
        </div>
      </div>
    )
  }
}

const currencies =
  [
    'ETH',
    'POLY',
    'DAI (USD Stablecoin)',
    'Non-USD Stablecoin',
  ];

const mapStateToProps = (state) => {
  let { sdk } = state.Tokenize;
  let { userDetail } = state.Auth;
  return { userDetail, sdk };
}

const mapDispatchToProps = { getSingleUserDetails, fetchingTokens, startLoader, stopLoader, fetchedToken, isError };

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail)
