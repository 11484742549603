import React, { Component } from 'react';
import moment from 'moment';
import { Button, Input } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class EditPropertyOverview extends Component {

    constructor(props) {
        super(props);
        const {
            title,
            stage,
            targetedPeriod,
            annualIncome,
            volume,
            totalLiquidity,
            units,
            noiPercent,
            location,
            city,
            postalCode,
            country,
            marketPrice,
            webinarDate,
            webinarURL,
            videoURL
        } = props.propertyDetails;

        this.state = {
            title,
            stage,
            targetedPeriod,
            annualIncome,
            volume,
            totalLiquidity,
            units,
            noiPercent,
            location,
            city,
            postalCode,
            country,
            marketPrice,
            webinarDate,
            webinarURL,
            videoURL
        }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleDate = date => {
        this.setState({ webinarDate: date });
      };

    render() {
        const { propertyDetails, stoTiers } = this.props
        const {
            title,
            stage,
            targetedPeriod,
            annualIncome,
            volume,
            totalLiquidity,
            units,
            noiPercent,
            location,
            city,
            postalCode,
            country,
            marketPrice,
            webinarURL,
            webinarDate,
            videoURL
        } = this.state
        return (
            <div>
                <div className='property-header'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>Edit Property Overview</h4>
                    </div>
                    <Button onClick={()=> this.props.onSubmit(this.state)}> Save </Button>
                </div>
                <div className="single-row">
                    <div className="modal-content-title">
                        Title
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="title"
                            value={title}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Stage
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="stage"
                            value={stage}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Target Period
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="targetPeriod"
                            value={targetedPeriod}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Annual Income
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="annualIncome"
                            value={annualIncome}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Volume
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="volume"
                            value={volume}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Total Liquidity
                    </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="totalLiquidity"
                            value={totalLiquidity}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Units
                            </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="units"
                            value={units}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        NOI %
                            </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="noiPercent"
                            value={noiPercent}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        Location
                            </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="location"
                            value={location}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <div className="single-row">
                    <div className="modal-content-title">
                        City
                            </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="city"
                            value={city}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                {(propertyDetails.postalCode && propertyDetails.postalCode !== undefined) ?
                    <div className="single-row">
                        <div className="modal-content-title">
                            Postal Code
                                    </div>
                        <div className="modal-content-data">
                            <Input
                                className="modal-content-input"
                                name="postalCode"
                                value={postalCode}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    : null}

                <div className="single-row">
                    <div className="modal-content-title">
                        Country
                     </div>
                    <div className="modal-content-data">
                        <Input
                            className="modal-content-input"
                            name="country"
                            value={country}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>


                {(propertyDetails.marketPrice && propertyDetails.marketPrice !== undefined) ?
                    <div className="single-row">
                        <div className="modal-content-title">
                            Market Value
                                    </div>
                        <div className="modal-content-data">
                            <Input
                                className="modal-content-input"
                                name="marketPrice"
                                value={marketPrice}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    : null
                }
               {new Date(propertyDetails.webinarDate) > new Date() && 
               <>
                    <div className="single-row">
                        <div className="modal-content-title">
                            Webinar URL
                        </div>
                        <div className="modal-content-data">
                            <Input
                                className="modal-content-input"
                                name="webinarURL"
                                value={webinarURL}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="single-row">
                        <div className="modal-content-title">
                        Webinar Date
                        </div>
                        <div className="modal-content-data list-new">
                            <DatePicker
                                onChange={this.handleDate}
                                selected={new Date(webinarDate)}
                                showTimeSelect
                                dateFormat="Pp"
                            />
                        </div>
                    </div>
                    </>
                    }

               {new Date(propertyDetails.webinarDate) < new Date() && 
                    <div className="single-row">
                        <div className="modal-content-title">
                        Webinar Video
                        </div>
                        <div className="modal-content-data">
                            <Input
                                className="modal-content-input"
                                name="videoURL"
                                value={videoURL}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default EditPropertyOverview