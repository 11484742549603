import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import './userTabs.css';

class UserBanks extends Component {
  state = {
    showEdit: false,
  }

  handleEdit = () => this.setState({ showEdit: !this.state.showEdit })

  handleSubmit = (e) => {
    console.log("*********on submit :: ", e);
    let { userDetails } = this.props;
    if (userDetails && userDetails['_id']) e['_id'] = userDetails['_id'];

    this.setState({ showEdit: !this.state.showEdit });
    // this.props.updatePropertyOverview(e)
  }

  render() {
    const { showEdit } = this.state;
    const { userBanks } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************userBanks `, userBanks);

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Account Info</h4>
            </div>
          </div>
          <div className="single-row">

          {this.props.userBanks && this.props.userBanks.length !== 0
            ? this.props.userBanks.map((item, index) => {
              return (
                <div
                  className="bank-info card-information"
                  key={index}
                  onClick={() => this.toggleClass(index, item)}
                  >
                  <div className="row">
                    <div className="col-6">
                      <div className="text-area">
                        <h4> {item.cardNumber}</h4>
                        <p>Routing No: {item.routing}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="detail-area">
                        <h4>{item.name}</h4>
                        <p>{item.category}</p>
                      </div>
                    </div>
                  </div>

                </div>
              );
            })
            : null}
          </div>


          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Cards</h4>
            </div>
          </div>
          <div className="single-row">

          {this.props.userCards && this.props.userCards.length !== 0
            ? this.props.userCards.map((item, index) => {
              return (
                <div
                  className="bank-info card-information"
                  key={index}
                  onClick={() => this.toggleClass(index, item)}
                  >
                  <div className="row">
                    <div className="col-6">
                        <h4> {item.cardNumber}</h4>
                        <p>Expiry Date: {item.expMonth}/{item.expYear}</p>
                    </div>
                      <div className="detail-area">
                        <h4>cvc: {item.cvc}</h4>
                        <p>{item.category}</p>
                    </div>
                  </div>

                </div>
              );
            })
            : null}
          </div>

        </div>
      )
  }
}

export default connect(null, null)(UserBanks);
