import { connect } from "react-redux";
import React, { Component } from "react";
import { getPropertyOwner } from '../../../store/actions/Property'
import ReactTable from 'react-table-6';


class PropertyOwners extends Component {

    state = {
      
    };

    componentDidMount(){
      this.props.getPropertyOwner()
    }

  render() {
    const columns = [
      {
        Header: 'User Name',
        accessor: 'firstName',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        }
      },
      {
        Header: 'User Email',
        accessor: 'email',
        style: {
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          textAlign: 'center',
        }
      },
    {
      Header: 'Created At',
      accessor: 'createdAt',
      style: { textAlign: 'center' },
    }
    ]
    return (
      <div className="content">
        <div className="main-container list-new">
          <h2 className="mb-5 pb-3">Property Owner</h2>
          {this.props.propertyOwners !== ''
            && <ReactTable
              data={this.props.propertyOwners}
              columns={columns}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Property }) => {
  return {
    propertyOwners: Property.propertyOwners
  }
};

const mapDispatchToProps = { getPropertyOwner };

export default connect(mapStateToProps, mapDispatchToProps)(PropertyOwners);
