import { combineReducers } from "redux";
import Auth from "./Auth";
import Roles from "./Roles";
import Tokenize from "./Tokenize";
import Property from "./Proprety"
import PropertyOwner from "./PropertyOwner"

export default combineReducers({
  Auth,
  Roles,
  Tokenize,
  Property,
  PropertyOwner
});
