import { PURGE } from "redux-persist";

var initialState = {
    ownerPropertiesList: [],
    ownerDashboardStats:"",
    propertyAddresses:{},
    stoDetails:{},
    investmentDet:[],
    propertyOwnerProfile:""
}

const PropertyOwner = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case 'SET_OWNER_PROPERTIES_LIST':
      return {
        ...state,
        ownerPropertiesList: payload.property
      };

    case 'SET_OWNER_STATS':
    return {
    ...state,
    ownerDashboardStats: payload
    };

    case 'PROPERTY_OWNER_WALLET':
    return {
    ...state,
    propertyAddresses: payload.propertyAddresses
    };

    case 'PROPERTY_OWNER_STO':
    return {
    ...state,
    stoDetails: payload.sto
    };

    case 'PROPERTY_OWNER_INVESTMENTS':
    return {
    ...state,
    investmentDet: payload.investmentDet
    };

    case 'PROPERTY_OWNER_PROFILE':
    return {
    ...state,
    propertyOwnerProfile: payload
    };

    default:
      return state;
  }
};
export default PropertyOwner;
