import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { getPropertyRequest } from "../../../store/actions/Auth";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'

import './sellRequest.css'

class SellRequest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
    props.getPropertyRequest()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) {
        console.log("rowData", rowInfo.original);
        this.setState({ SingleRowData: rowInfo.original })
      }
    }
  })

  tokenizeProperty = (e) => this.props.history.push(`/home/list-property?id=${e}`);
  // tokenizeProperty = (e) =>  window.location.href = `/home/list-property?id=${e}`;

  render() {
    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Email',
        accessor: 'email',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Type',
        accessor: 'type',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Stage',
        accessor: 'stage',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Number',
        accessor: 'phone',
      },
      {
        Header: '',
        width: 180,
        minWidth: 120,
        maxWidth: 250,
        sortable: false,
        filterable: false,
        resizable: false,
        Cell: props => {
          return (
            <span style={{ display: "flex" }}>
              <Button onClick={() => this.toggle()} className="btn-info action-btn action-btn-outline-info">
                View
              </Button>
              {
                props.original.status == 'listed'
                  ? <Button disabled className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55" }}>
                    Tokenized
                </Button>
                  : <Button onClick={() => this.tokenizeProperty(props.original._id)} className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55" }}>
                    Tokenize
                </Button>
              }
            </span>
          )
        }
      }
    ]
    const { SingleRowData } = this.state;
    return (
      <div className="content">
        <div className="main-container">
          <Modal isOpen={this.state.showModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <h3 style={{ margin: "0px 0px 0px 25px" }}>Property Requested Detail</h3>
            <ModalBody>
              {SingleRowData !== null
                && <div className="modal-container">
                  <div className="single-row">
                    <div className="modal-content-title">
                      Name
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.name}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Email
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.email}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Type
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.type}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Stage
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.stage}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      City
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.city}
                    </div>
                  </div>
                  <div className="single-row">
                    <div className="modal-content-title">
                      Country
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.country}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Address
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.address}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Company
                </div>
                    <div className="modal-content-data">
                      {SingleRowData.company}
                    </div>
                  </div>

                  <div className="single-row">
                    <div className="modal-content-title">
                      Phone Number
                    </div>
                    <div className="modal-content-data">
                      {SingleRowData.phone}
                    </div>
                  </div>
                </div>
              }
            </ModalBody>
          </Modal>
          <h2 style={{ marginBottom: "20px" }}>Sell Requests</h2>
          {this.props.propertyRequestList !== ''
            ? <ReactTable
              data={this.props.propertyRequestList}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
            : ''
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("sellRequest state :: ", state)
  return {
    propertyRequestList: state.Auth.propertyRequested,
  }
}

const mapDispatchToProps =
{
  getPropertyRequest: getPropertyRequest
};

export default connect(mapStateToProps, mapDispatchToProps)(SellRequest);
