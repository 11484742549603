import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { Button } from "reactstrap";
import ReactTable from 'react-table-6';
class DepositHistory extends Component {
  state = {
    showEdit: false,
    SingleRowData: null
  }

  handleEdit = () => this.setState({ showEdit: !this.state.showEdit })

  handleSubmit = (e) => {
    console.log("*********on submit :: ", e);
    let { investHistory } = this.props;
    if (investHistory && investHistory['_id']) e['_id'] = investHistory['_id'];

    this.setState({ showEdit: !this.state.showEdit });
    // this.props.updatePropertyOverview(e)
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  render() {
    const { showEdit } = this.state;
    const { depositHistory } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************depositHistory `, depositHistory);

    const columns = [{
      Header: 'COIN',
      accessor: 'symbol',
      Cell: props => <span className='number'>TKUSD</span>,
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'AMOUNT',
      accessor: 'received',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'METHOD',
      accessor: 'method',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'STATUS',
      accessor: 'status',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'DATE',
      accessor: 'createdAt',
      Cell: props => <span className='number'>{moment(props.value.toLocaleString()).format('lll')} </span>,
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Transaction',
      accessor: 'viewTransaction',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    }
    ]

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Deposit History</h4>
            </div>
          </div>
          {this.props.depositHistory !== ''
            && <ReactTable
              data={this.props.depositHistory}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      )
  }
}

export default connect(null, null)(DepositHistory);
