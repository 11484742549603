import { Button } from 'reactstrap';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './listNewProperty.css';

import { web3 } from './../../../store/web3';
import { TokenizePropertyFn } from './../../../store/actions/Auth';
import { STData, ST, WhitelistAddress, network } from './../../../store/contract';
import { startLoader, stopLoader, isError, checkSymbol } from './../../../store/actions/Tokinize';

class TokenizeProperty extends Component {
  constructor(props) {
    super(props);
    let url = require('url');
    const { query } = url.parse(document.location.href, true);

    this.state = {
      formSymbolValue: '',
      formData: {
        symbol: '',
        name: query['name'],
        propertyId: query['id'],
      },
      buttonLoading: false
    }
  }

  handleFormChange = (event) => {
    const { formData } = this.state;
    if (event.target.name === 'symbol') event.target.value = event.target.value.toUpperCase();
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  validateSymbol = () => {
    let { symbol } = this.state.formData;
    this.props.checkSymbol(`TK-${symbol}`);
  }

  createToken = async () => {
    try {
      let { symbol, name, propertyId } = this.state.formData;
      let { validSymbol, validSymbolMsg, TokenizePropertyFn, startLoader, stopLoader, history } = this.props;
      if (!validSymbol) {
        EventBus.publish("error", validSymbolMsg);
        return;
      }

      symbol = `TK-${symbol}`;
      startLoader(`Creating ${symbol} Security Token`);

      console.log(`************STData = `, STData);
      let from = (await web3.currentProvider.enable())[0];
      ST.deploy({ data: STData['ByteCode'], arguments: [name, symbol, STData['GRANULARITY'], STData['CONTROLLERS'], STData['CERTIFICATE_SIGNER'], STData['CERTIFICATE_ACTIVE'], WhitelistAddress] }).send({ from })
        // ST.deploy({ data: STData['ByteCode'], arguments: [name, symbol, STData['GRANULARITY'], STData['CONTROLLERS'], STData['CERTIFICATE_SIGNER'], STData['CERTIFICATE_ACTIVE'], STData['partitions'], WhitelistAddress] }).send({ from })
        .on('transactionHash', hash => console.log(`*******hash = ${hash}`))
        .on('receipt', receipt => {
          console.log(`*******receipt = `, receipt);
          let data = {
            abi: STData['ABI'],
            byteCode: STData['ByteCode'],

            name,
            symbol,
            network,
            propertyId,

            WhitelistAddress,
            fromWallet: receipt['from'],
            transactionHash: receipt['transactionHash'],
            contractAddress: receipt['contractAddress'],
          }
          TokenizePropertyFn({ data, history });
          stopLoader();
        })
        .on('error', error => {
          console.log(`*********error = `, error);
          EventBus.publish("error", error);
          stopLoader();
        });
    }
    catch (error) {
          console.log(`*********error = `, error);
          this.props.isError();
      EventBus.publish("error", error.message);
    }
  }

  render() {
    let { buttonLoading } = this.state;
    return (
      <div className="content">
        <div className="main-container">
          <h3 className='mt-5 mb-2'>Create your Security Token</h3>
          <hr />
          <ValidatorForm className="validatorForm" onSubmit={this.createToken}>

            <TextValidator
              className="MyTextField"
              label="Symbol"
              InputProps={{
                startAdornment: <InputAdornment position="start">TK-</InputAdornment>,
              }}
              onChange={this.handleFormChange}
              name="symbol"
              type="text"
              margin="dense"
              variant="outlined"
              onBlur={this.validateSymbol}
              validators={['required', 'matchRegexp:^([a-zA-Z0-9]){0,6}$']}
              errorMessages={['Symbol can not be empty', 'Symbol can not be more than 6 characters']}
              value={this.state.formData.symbol}
            />

            <TextValidator
              disabled
              className="MyTextField"
              label="Token Name"
              onChange={this.handleFormChange}
              name="name"
              type="text"
              margin="dense"
              variant="outlined"
              validators={['required']}
              errorMessages={['Token Name is Required']}
              value={this.state.formData.name}
            />

            {/* <TextValidator
              className="MyTextField"
              label="Wallet Address"
              onChange={this.handleFormChange}
              name="treasuryWallet"
              type="text"
              margin="dense"
              variant="outlined"
              validators={['isValidAddress']}
              errorMessages={['Wallet Address is Invalid']}
              value={this.state.formData.treasuryWallet}
            /> */}

            <Button type="submit" className='btn-info' disabled={buttonLoading}>
              {!buttonLoading ? 'Create Token' : <i style={{ color: "white" }} className="fa fa-spinner fa-spin fa-1x fa-fw"></i>}
            </Button>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ Tokenize }) => {
  let { loading, loadingMessage, error, networkId, walletAddress, validSymbol, validSymbolMsg } = Tokenize;
  return {
    error,
    loading,
    networkId,
    validSymbol,
    validSymbolMsg,
    loadingMessage,
    walletAddress,
  }
}

const mapDispatchToProps = {
  isError,
  stopLoader,
  checkSymbol,
  startLoader,
  TokenizePropertyFn,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenizeProperty);
