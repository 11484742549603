import { connect } from "react-redux";
import React, { Component } from "react";
import ReactTable from 'react-table-6';
import { getSingleUserDetails, uploadDocument, onDeleteUserDoc } from "../../store/actions/Auth";
import EventBus from 'eventing-bus';

// import './userDetail.css'
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ModalFooter
} from "reactstrap";
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
class UserDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null
    };
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  handleViews = (e) => window.open(e, '_blank')

  viewDetails = (user) => {
    let query = `id=${user['_id']}`;
    this.props.history.push(`/home/user-details?${query}`);
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);

    if (!query['id']) {
      EventBus.publish("error", `Please select user`)
      setTimeout(() => this.props.history.push(`/home/Users`), 1000);
    } else this.props.getSingleUserDetails(query['id']);
  }


    filterMethod = (filter, row, column) => {
      const id = filter.pivotId || filter.id;
      return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
    }

    toggle = () => {
      this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
    }

    getTableProps = (state, rowInfo, col, instance) => ({
      onClick: (e, cb) => {
        if (rowInfo !== undefined) {
          console.log("rowData", rowInfo.original);
          this.setState({ SingleRowData: rowInfo.original })
        }
      }
    })

    onDelete = (e) => {
      const url = require('url');
      const { query } = url.parse(document.location.href, true);
      let data = {location:e.location}
      this.props.onDeleteUserDoc({data, id:query['id']})
    }

    handleUpload = (e) => {
      this.setState({ userDocumentFile: e.target.files })
    }

    handleUploadFile = (e) => {
      let data = new FormData();
      const url = require('url');
      const { query } = url.parse(document.location.href, true);
      console.log('userDocumentFile',this.state.userDocumentFile);
      for (let i = 0; i < this.state.userDocumentFile.length; i++) {
        data.append('docs', this.state.userDocumentFile[i]);
        if (this.state.userDocumentFile.length-1 == i) {
          this.props.uploadDocument({data, id:query['id']})
          this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
        }
      }
    }

    componentWillReceiveProps(props) {
      if (props.buttonLoader === false)
      this.setState({ showModal: false })
    }

    handleChange(date) {
      console.log(date);
      this.setState({year:date})
    }

    handleFormChange = event => {
      console.log(event.target.name, event.target.value);
      this.setState({[event.target.name]: event.target.value });
    };

    render() {
      console.log('userDocuments', this.props);
      const { documents } = this.props;
      const classes = useStyles;

      const columns = [
        {
          Header: 'Name',
          accessor: 'name',
          style: { textAlign: 'center' },
        },
        {
          Header: 'Type',
          accessor: 'type',
          style: { textAlign: 'center' },
        },
        {
          Header: 'Created At',
          accessor: 'createdAt',
          style: { textAlign: 'center' },
          Cell: props => {
            return (
              <span style={{ display: "flex" }}>
                {moment(props.original.uploadDate).format('MMM Do YYYY')}
              </span>
            )
          }
        },
        {
          Header: '',
          width: 180,
          minWidth: 120,
          maxWidth: 250,
          sortable: false,
          filterable: false,
          resizable: false,
          Cell: props => {
            return (
              <span style={{ display: "flex" }}>
                <a href={props.original.location} download>
                  <Button className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55" }}>
                    Download
                  </Button>
                </a>
                <Button onClick={() => this.onDelete(props.original)} className="btn-info action-btn action-btn-outline-info">
                  Delete
                </Button>
              </span>
            )
          }
        }
      ]
      const { SingleRowData } = this.state;
      return (
        <div className="content">
          <div className="main-container">
            <Modal isOpen={this.state.showModal} toggle={this.toggle}>
              <ModalHeader toggle={this.toggle}></ModalHeader>
              <h3 style={{ margin: "0px 0px 0px 25px" }}>Upload User Documents</h3>
              <ModalBody>
                <div className="download-row">
                  <Input
                    type="file"
                    multiple
                    accept=".pdf,.docs"
                    onChange={this.handleUpload}
                    name="document"
                    />
                </div>

                <hr />
              </ModalBody>
              <ModalFooter>
                <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleUploadFile}>
                  {
                    this.props.buttonLoader
                    ? 'Uploading...'
                    : 'Upload'
                  }
                </Button>
                <Button color="info" onClick={this.toggle}>Close</Button>
              </ModalFooter>
            </Modal>
            <div className="agreement-header">
              <h2 style={{ marginBottom: "20px" }}>User Documents {this.props.userDetail.firstName} {this.props.userDetail.lastName}</h2>
              <Button onClick={this.toggle} className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55", width: "160px" }}>
                Add User Documents
              </Button>
            </div>
            {
              this.props.userDetail.doc !== ''
              ? <ReactTable
              data={this.props.documents ? this.props.documents : []}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
              />
            : ''
          }
        </div>
      </div>
    );
  }
  }

const mapStateToProps = ({ Auth }) => {
  let { userDetail } = Auth;
  return { userDetail }
}

const mapDispatchToProps = { getSingleUserDetails, uploadDocument, onDeleteUserDoc };
export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments);
