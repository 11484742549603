import moment from 'moment';
import { connect } from "react-redux";
import React, { Component } from 'react';
import { Button } from "reactstrap";
import ReactTable from 'react-table-6';
class WiresTransfer extends Component {
  state = {
    showEdit: false,
    SingleRowData: null
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) this.setState({ SingleRowData: rowInfo.original })
    }
  })

  render() {
    const { showEdit } = this.state;
    const { mintedHistory } = this.props;
      console.log(`************this.props `, this.props);
    console.log(`************depositHistory `, mintedHistory);

    const columns = [{
      Header: 'Admin Name',
      accessor: 'adminId',
      Cell: props => <span className='number'>{props.value.name}</span>,
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'AMOUNT',
      accessor: 'amount',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'Description',
      accessor: 'description',
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    {
      Header: 'DATE',
      accessor: 'createdAt',
      Cell: props => <span className='number'>{moment(props.value.toLocaleString()).format('lll')} </span>,
      style: {
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        textAlign: 'center',
      },
    },
    ]

    if (showEdit) return ''
    else
      return (
        <div className='property-overview'>
          <div className='property-header'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h4 style={{ 'fontWeight': '650', margin: "0px", marginRight: "20px", }}>User Wires Transfer</h4>
            </div>
          </div>
          {this.props.mintedHistory !== ''
            && <ReactTable
              data={this.props.mintedHistory}
              columns={columns}
              defaultFilterMethod={this.filterMethod}
              filterable
              style={{ backgroundColor: "white" }}
              minRows={8}
              getTdProps={this.getTableProps}
            />
          }
        </div>
      )
  }
}

export default connect(null, null)(WiresTransfer);
