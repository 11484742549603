import React, { Component } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ModalFooter
} from "reactstrap";
import { getAllUserDocuments, onDeleteUserDocument, uploadUserDocument } from "../../store/actions/Property";
import { connect } from "react-redux";
import ReactTable from 'react-table-6'
import moment from 'moment';
import YearPicker from "react-year-picker";
import { MenuItem, Grid } from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class UserDocuments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      SingleRowData: null,
      userDocumentFile: ''
    };
    this.handleChange = this.handleChange.bind(this);
    props.getAllUserDocuments()
  }

  filterMethod = (filter, row, column) => {
    const id = filter.pivotId || filter.id;
    return row[id] !== undefined ? String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase()) : true
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
  }

  getTableProps = (state, rowInfo, col, instance) => ({
    onClick: (e, cb) => {
      if (rowInfo !== undefined) {
        console.log("rowData", rowInfo.original);
        this.setState({ SingleRowData: rowInfo.original })
      }
    }
  })

  onDelete = (e) => {
    let data = {
      name:e.name,
      location:e.location
    }
    console.log('e is ', data);
    this.props.onDeleteUserDocument({data, id:this.props.propertyDetails._id})
  }

  handleUpload = (e) => {
    this.setState({ userDocumentFile: e.target.files })
  }

  handleUploadFile = (e) => {
    console.log(this.state.category, this.state.year);
    let data = new FormData();
    data.append('type', this.state.category);
    data.append('year', this.state.year);

    for (let i = 0; i < this.state.userDocumentFile.length; i++) {
      data.append('files', this.state.userDocumentFile[i]);
      if (this.state.userDocumentFile.length-1 == i) {
        console.log('this.props.propertyDetails._id', this.props.propertyDetails._id);
        this.props.uploadUserDocument({data, id:this.props.propertyDetails._id})
        this.setState({ showModal: !this.state.showModal, userDocumentFile: '' })
      }
    }
  }

  componentWillReceiveProps(props) {
    if (props.buttonLoader === false)
    this.setState({ showModal: false })
  }

  handleChange(date) {
    console.log(date);
    this.setState({year:date})
  }

  handleFormChange = event => {
    console.log(event.target.name, event.target.value);
    this.setState({[event.target.name]: event.target.value });
  };

  render() {
    console.log('userDocuments', this.props);
    const { propertyDetails } = this.props;

    const classes = useStyles;

    const columns = [
      {
        Header: 'Name',
        accessor: 'name',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Type',
        accessor: 'type',
        style: { textAlign: 'center' },
      },
      {
        Header: 'Created At',
        accessor: 'createdAt',
        style: { textAlign: 'center' },
        Cell: props => {
          return (
            <span style={{ display: "flex" }}>
              {moment(props.original.createdAt).format('MMM Do YYYY')}
            </span>
          )
        }
      },
      {
        Header: '',
        width: 180,
        minWidth: 120,
        maxWidth: 250,
        sortable: false,
        filterable: false,
        resizable: false,
        Cell: props => {
          return (
            <span style={{ display: "flex" }}>
              <a href={props.original.location} download>
                <Button className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55" }}>
                  Download
                </Button>
              </a>
              <Button onClick={() => this.onDelete(props.original)} className="btn-info action-btn action-btn-outline-info">
                Delete
              </Button>
            </span>
          )
        }
      }
    ]
    const { SingleRowData } = this.state;
    return (
      <div className="content">
        <div className="main-container">
          <Modal isOpen={this.state.showModal} toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}></ModalHeader>
            <h3 style={{ margin: "0px 0px 0px 25px" }}>Upload User Documents</h3>
            <ModalBody>
              <div className="download-row">
                <Input
                  type="file"
                  multiple
                  accept=".pdf,.docs"
                  onChange={this.handleUpload}
                  name="document"
                  />
              </div>
              <div className="download-row mt-5">
                <Grid container justify="space-around" spacing={3}>
                  <Grid item xs={6}>
                    <FormControl variant="outlined" className={`select ${classes.formControl}`}>
                      <InputLabel id="demo-simple-select-outlined-label">
                        Category
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-outlined-label"
                        id="demo-simple-select-outlined"
                        value={this.state.category}
                        type="text"
                        name="category"
                        onChange={this.handleFormChange}
                        labelWidth={80}
                        style={{width: '180px'}}
                        >
                        <MenuItem name="category" value="General Document"><em>General Document</em></MenuItem>
                        <MenuItem name="category" value="Tax Document"><em>Tax Document</em></MenuItem>
                        <MenuItem name="category" value="Statements"><em>Statements</em></MenuItem>
                      </Select>
                    </FormControl>

                  </Grid>
                  <Grid item xs={6}>
                    <YearPicker onChange={this.handleChange} />
                  </Grid>
                </Grid>
              </div>

              <hr />
            </ModalBody>
            <ModalFooter>
              <Button color="info" disabled={this.props.buttonLoader} onClick={this.handleUploadFile}>
                {
                  this.props.buttonLoader
                  ? 'Uploading...'
                  : 'Upload'
                }
              </Button>
              <Button color="info" onClick={this.toggle}>Close</Button>
            </ModalFooter>
          </Modal>
          <div className="agreement-header">
            <h2 style={{ marginBottom: "20px" }}>User Documents</h2>
            <Button onClick={this.toggle} className="btn-info action-btn" style={{ minWidth: "80px", background: "#92BF55", width: "160px" }}>
              Add User Documents
            </Button>
          </div>
          {this.props.userDocuments !== ''
            ? <ReactTable
            data={propertyDetails ? propertyDetails.userDocuments : []}
            columns={columns}
            defaultFilterMethod={this.filterMethod}
            filterable
            style={{ backgroundColor: "white" }}
            minRows={8}
            getTdProps={this.getTableProps}
            />
          : ''
        }
      </div>
    </div>
  );
}
}

const mapStateToProps = (state) => {
  console.log("User Documents state :: ", state)
  return {
    userDocuments: state.Auth.userDocuments,
    buttonLoader: state.Auth.buttonLoader
  }
}
const mapDispatchToProps =
{
  onDeleteUserDocument: onDeleteUserDocument,
  getAllUserDocuments: getAllUserDocuments,
  uploadUserDocument: uploadUserDocument
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDocuments);
