import Roles from './Roles';
import authSagas from './Auth';
import propertySagas from './Property';
import PropertyOwnerSagas from './PropertyOwner';
import { all } from 'redux-saga/effects';

export default function* rootSaga(getState) {
  yield all([
    Roles(),
    authSagas(),
    propertySagas(),
    PropertyOwnerSagas()
  ]);
}
