import 'date-fns';
import EventBus from 'eventing-bus';
import { Button } from "reactstrap";
import { connect } from "react-redux";
import React, { Component } from "react";
import { utils as web3Utils } from 'web3';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import fromExponential from 'from-exponential';
import InputAdornment from '@material-ui/core/InputAdornment';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountBalanceWallet from '@material-ui/icons/AccountBalanceWallet';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { MuiPickersUtilsProvider, DatePicker, KeyboardTimePicker } from '@material-ui/pickers';

import "./index.css";
import moment from 'moment';
import { web3 } from '../../../store/web3';
import { startLoader, stopLoader, isError } from "../../../store/actions/Tokinize";
import { LaunchedSTO, getSinglePropertyDetails } from "../../../store/actions/Auth";
import { STOData, STO, TKUSDAddress, WhitelistAddress, network } from '../../../store/contract';
import NumberFormat from 'react-number-format';
import FormControl from '@material-ui/core/FormControl';

import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';

const currentDate = new Date()

class LaunchSTO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      symbol: '',
      propertyId: '',

      propertyToken: '',
      TKUSDAddress: '',

      price: 1000,
      propertyValue: '',

      goalPercent: '',
      capPercent: '',

      ownerWallet: '',
      propertyWallet: '',

      disabled: false,
      startDate: currentDate,
      endDate: new Date(Date.now() + 90 * 86400000),
    }
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);
    if (!query['symbol'] || query['symbol'] === '' || !query['id'] || query['id'] === '') {
      EventBus.publish("error", `Please select Symbol`);
      setTimeout(() => this.props.history.push(`/home/active-listings`), 1000);
    }
    else {
      this.props.getSinglePropertyDetails(query['id']);
      this.setState({ symbol: query['symbol'], propertyId: query['id'], TKUSDAddress });
    }

    ValidatorForm.addValidationRule('isValidAddress', (value) => {
      if (!web3Utils.isAddress(value)) return false
      else return true
    });
    ValidatorForm.addValidationRule('isGoalPercent', (value) => {
      if (Number(value) > Number(this.state.capPercent)) return false;
      return true;
    });
    ValidatorForm.addValidationRule('isPropertyValue', (value) => {
      if (value % this.state.price !== 0) return false;
      return true;
    });
    ValidatorForm.addValidationRule('isThousand', (value) => {
      if (!Number.isInteger(value / 1000)) return false;
      return true;
    });
    ValidatorForm.addValidationRule('isLessThanProperty', (value) => {
      console.log(`************value = `, value);
      console.log(`************this.state.propertyValue = `, this.state.propertyValue);

      if (Number(value) > Number(this.state.propertyValue)) {
        console.log(`************return false`);
        return false;
      } else {
        console.log(`************return true`);
        return true;
      }
    });
    ValidatorForm.addValidationRule('isSameWallet', (value) => {
      if (value == this.state.propertyWallet) return false;
      return true;
    });

  }

  componentWillUnmount() {
    ValidatorForm.removeValidationRule('isPropertyValue');
    ValidatorForm.removeValidationRule('isValidAddress');
    ValidatorForm.removeValidationRule('isGoalPercent');
    ValidatorForm.removeValidationRule('isThousand');
    ValidatorForm.removeValidationRule('isLessThanProperty');
  }

  componentWillReceiveProps({ singlePropertyDetails }) {
    if (singlePropertyDetails)
      this.setState({ propertyToken: singlePropertyDetails['token']['contractAddress'], tokenDetails: singlePropertyDetails['token'], propertyIdProps: singlePropertyDetails['property']['_id'] });
  }

  transferOwnership = async (newAdmin) => {
    try {
      let { symbol, adminWallet, abi } = this.state.tokenDetails;
      let contractAddress = this.state.propertyToken;
      let from = ((await web3.currentProvider.enable())[0]).toLowerCase();

      // let prevAdmin = (adminWallet).toLowerCase();
      // if (from !== prevAdmin) {
      //   EventBus.publish("error", `Only ${symbol} Admin can transfer Ownership`);
      //   return;
      // }

      this.props.startLoader(`Transfering ${symbol} Ownership to Security Offering`);

      const ST = new web3.eth.Contract(abi, contractAddress);
      await ST.methods.transferOwnership(newAdmin).send({ from });
      // this.props.transferOwnership({ stoId: this.props.stoDetails['_id'], propertyId: this.state.propertyIdProps });
    } catch (e) {
      this.props.stopLoader()
      EventBus.publish("error", `Error while transfering`);
    }
  }

  toPlainString = (num) => {
    return ('' + num).replace(/(-?)(\d*)\.?(\d+)e([+-]\d+)/,
      function (a, b, c, d, e) {
        return e < 0
          ? b + '0.' + Array(1 - e - c.length).join(0) + c + d
          : b + c + d + Array(e - d.length + 1).join(0);
      });
  }

  launchSTO = async () => {
    try {
      let data = {};
      let required = ['propertyId', 'symbol', 'price', 'propertyValue', 'propertyWallet',
        'propertyToken', 'TKUSDAddress', 'goalPercent', 'capPercent', 'endDate', 'startDate', 'ownerWallet'];
      let { price, propertyValue, propertyWallet, propertyToken, ownerWallet, TKUSDAddress, goalPercent, capPercent, endDate, startDate } = this.state;
      for (let key of required) data[key] = this.state[key];

      // price = this.toPlainString(await web3.utils.toWei(`${price}`, 'ether'));
      // propertyValue = this.toPlainString(await web3.utils.toWei(`${propertyValue}`, 'ether'));

      // let cap = Number((propertyValue * capPercent) / 100);
      // let goal = Number((propertyValue * goalPercent) / 100);
      //
      // if (!Number.isInteger(cap)) {
      //   EventBus.publish("error", `Cap value cannot be floating number ${cap}`);
      //   return;
      // } else if (!Number.isInteger(goal) || goal <= 0) {
      //   EventBus.publish("error", `Goal value cannot be floating number ${goal}`);
      //   return;
      // }
      //
      // // cap = this.toPlainString(cap);
      // // goal = this.toPlainString(goal);

      let cap = Number(capPercent);
      let goal = Number(goalPercent);

      // cap = this.toPlainString(capPercent);
      // goal = this.toPlainString(goalPercent);
      console.log('capPercent', cap);
      console.log('goalPercent', goal);

      endDate = parseFloat((new Date(endDate).getTime()) / 1000).toFixed(0);
      startDate = parseFloat((new Date(startDate).getTime()) / 1000).toFixed(0);
      if (startDate > endDate) {
        EventBus.publish("error", `Start Date Cannot be greater than End Date`);
        return;
      } else if (new Date().getTime() > (startDate * 1000)) {
        EventBus.publish("error", `Start Date is behind current Date`);
        return;
      }
      this.setState({ disabled: true });
      this.props.startLoader(`Launching ${data['symbol']} Security Token Offering`);

      let from = (await web3.currentProvider.enable())[0];

      let { adminWallet, symbol } = this.state.tokenDetails;
      let fromWallet = ((await web3.currentProvider.enable())[0]).toLowerCase();
      let prevAdmin = (adminWallet).toLowerCase();

      if (fromWallet !== prevAdmin) {
        EventBus.publish("error", `Only ${symbol} Admin can transfer Ownership`);
        return;
      }
      console.log('price', price);
      console.log('propertyWallet', propertyWallet);
      console.log('propertyToken', propertyToken);
      console.log('TKUSDAddress', TKUSDAddress);
      console.log('WhitelistAddress', WhitelistAddress);
      console.log('cap', cap);
      console.log('propertyValue', propertyValue);
      console.log('goal', goal);
      console.log('startDate', startDate);
      console.log('endDate', endDate);
      console.log('ownerWallet', ownerWallet);
      STO.deploy({
        data: STOData['ByteCode'], arguments: [
          price,
          propertyWallet,
          propertyToken,
          TKUSDAddress,
          WhitelistAddress,
          propertyValue,
          cap,
          goal,
          startDate,
          endDate,
          ownerWallet
        ]
      }).send({ from })
        .on('transactionHash', hash => console.log(`*******hash = ${hash}`))
        .on('receipt', async receipt => {
          console.log(`*******receipt = `, receipt);

          data['network'] = network;
          data['abi'] = STOData['ABI'];
          data['byteCode'] = STOData['ByteCode'];

          data['WhitelistAddress'] = WhitelistAddress;

          data['fromWallet'] = receipt['from'];
          data['transactionHash'] = receipt['transactionHash'];
          data['contractAddress'] = receipt['contractAddress'];

          data['capPercent'] = (cap / propertyValue) * 100;
          data['goalPercent'] = (goal / propertyValue) * 100;

          this.props.stopLoader();
          this.setState({ disabled: false });
          setTimeout(async () => {
            await this.transferOwnership(data['contractAddress'])
            this.props.LaunchedSTO({ data, history: this.props.history });
          }, 100);
        })
        .on('error', error => {
          this.props.stopLoader();
          this.setState({ disabled: false });
          EventBus.publish("error", error['message']);
        });
    } catch (error) {
      this.props.isError();
      this.props.stopLoader();
      this.setState({ disabled: false });
      EventBus.publish("error", error['message']);
      setTimeout(() => this.props.history.push(`/home/active-listings`), 1000);
    }
  }

  handleFormChange = (event) => this.setState({ [event.target.name]: event.target.value });
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
    console.log(e.target.value / 1000, Number.isInteger(e.target.value / 1000));
  }
  render() {
    let { symbol, price, propertyValue, propertyWallet, ownerWallet, TKUSDAddress, goalPercent, capPercent, endDate, startDate, disabled } = this.state;
    console.log('endDate', endDate);

    return (
      <div className="content">
        <div className="main-container text-center">
          <h1 className='mt-5 mb-2'>{symbol} Launch STO </h1>
          <hr />
          <ValidatorForm className="validatorForm col-md-12" onSubmit={this.launchSTO}>
            <Grid container justify='space-around' spacing={3}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid item xs={6}>
                  <DatePicker
                    className='col-md-4'
                    name="startDate"
                    margin="normal"
                    id="date-picker-dialog"
                    label="Start Date"
                    format="MM/dd/yyyy"
                    value={startDate}
                    onChange={(startDate) => this.setState({ startDate })}
                    KeyboardButtonProps={{ 'aria-label': 'Start Date' }}
                  />
                  <KeyboardTimePicker
                    className='col-md-4'
                    margin="normal"
                    id="time-picker"
                    label="Start Time"
                    value={startDate}
                    onChange={(startDate) => this.setState({ startDate })}
                    KeyboardButtonProps={{ 'aria-label': 'Start Time' }}
                  />
                </Grid>

                <Grid item xs={6}>
                  <DatePicker
                    className='col-md-4'
                    name="endDate"
                    margin="normal"
                    id="date-picker-dialog"
                    label="End Date"
                    format="MM/dd/yyyy"
                    value={endDate}
                    onChange={(endDate) => this.setState({ endDate })}
                    KeyboardButtonProps={{ 'aria-label': 'End Date' }}
                  />
                  <KeyboardTimePicker
                    className='col-md-4'
                    margin="normal"
                    id="time-picker"
                    label="End Time"
                    value={endDate}
                    onChange={(endDate) => this.setState({ endDate })}
                    KeyboardButtonProps={{ 'aria-label': 'End Time' }}
                  />
                </Grid>
              </MuiPickersUtilsProvider>

              <Grid item xs={6}>
                <TextValidator
                  type="number"
                  margin="dense"
                  variant="outlined"
                  name="propertyValue"
                  label="Property Value"
                  className="MyTextField col-md-8"
                  value={propertyValue}
                  onChange={this.handleFormChange}
                  validators={['required', 'isPropertyValue']}
                  errorMessages={['Property Value is Required', 'Property value must be Divisble by Price']}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <MonetizationOn />
                    </InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextValidator
                  disabled
                  type="number"
                  name="price"
                  margin="dense"
                  variant="outlined"
                  label="Token Price"
                  value={price}
                  onChange={this.handleFormChange}
                  className="MyTextField col-md-8"
                  errorMessages={['Token Price is Required']}
                  InputProps={{
                    endAdornment:
                      <InputAdornment position="end">
                        <MonetizationOn />
                      </InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextValidator
                  type="number"
                  margin="dense"
                  variant="outlined"
                  name="capPercent"
                  label="Cap Amount"
                  value={capPercent}
                  onChange={this.handleFormChange}
                  className="MyTextField col-md-8"
                  validators={['isThousand', 'minNumber:0', 'isLessThanProperty', 'required']}
                  errorMessages={['Amount must be divisible of 1000', 'Value Must be greater than 0', 'Value Must be less than Property Value', 'Cap Amount is Required']}
                  helperText='Maximum Property Value owner wants to sell'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <MonetizationOn />
                    </InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextValidator
                  type="number"
                  margin="dense"
                  variant="outlined"
                  name="goalPercent"
                  label="Goal Amount"
                  value={goalPercent}
                  onChange={this.handleFormChange}
                  className="MyTextField col-md-8"
                  validators={['isThousand', 'isGoalPercent', 'minNumber:0', 'isLessThanProperty', 'required']}
                  errorMessages={['Amount must be divisible of 1000', 'Goal Must be less than or equal to Cap', 'Amount Must be greater than 0', 'Value Must be less than Property Value', 'Goal Amount is Required']}
                  helperText='Minimum Threshold Sale Amount for successfull offering'
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <MonetizationOn />
                    </InputAdornment>,
                  }}
                />
              </Grid>
              {
                /*
                <Grid item xs={6}>
                  <FormControl variant="outlined">
                    <InputLabel>
                      Cap Percentage
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-error-helper-text"
                      className="MyTextFieldNumber col-md-12"
                      margin="dense"
                      validators={['isNumberValid', 'required']}
                      autoComplete='off'
                      disabled
                      readOnly
                      value="%"
                      helperText='Maximum Property Percentage owner wants to sell'
                      labelWidth={130}
                      errorMessages={['Percentage Must be greater than 0', 'Percentage Must be less than 100', 'Cap Percentage is Required']}
                      startAdornment={
                        <NumberFormat validators={['required']} suffix="000" variant="outlined" name='capPercent' value={capPercent}
                          onChange={this.handleFormChange} class="numberFormatCap" />
                    }
                      aria-describedby="outlined-weight-helper-text"
                      />
                    <span className="helperText">Maximum Property Percentage owner wants to sell</span>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl variant="outlined">
                    <InputLabel>
                      Goal Percentage
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-error-helper-text"
                      className="MyTextFieldNumber col-md-12"
                      margin="dense"
                      validators={['isNumberValid', 'required']}
                      autoComplete='off'
                      disabled
                      readOnly
                      value="%"
                      helperText='Maximum Property Percentage owner wants to sell'
                      labelWidth={130}
                      errorMessages={['Goal Must be less than or equal to Cap', 'Percentage Must be greater than 0', 'Goal must be a number', 'Goal Percentage is Required']}
                      startAdornment={
                        <NumberFormat validators={['required']} suffix="000" variant="outlined" name='goalPercent' value={goalPercent}
                          onChange={this.handleFormChange} class="numberFormatCap" />
                    }
                      aria-describedby="outlined-weight-helper-text"
                      />
                    <span className="helperText">Minimum Threshold Sale Percentage for successfull offering</span>
                  </FormControl>
                </Grid>
                */
              }
              <Grid item xs={6}>
                <TextValidator
                  type="text"
                  margin="dense"
                  variant="outlined"
                  name="propertyWallet"
                  label="Property Wallet"
                  className="MyTextField col-md-8"
                  onChange={this.handleFormChange}
                  value={propertyWallet}
                  validators={['required', 'isValidAddress']}
                  errorMessages={['Property Wallet is Required', 'Invalid ETH Address']}
                  helperText="Will Receive Goal TKUSD Raised"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <AccountBalanceWallet />
                    </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  type="text"
                  margin="dense"
                  variant="outlined"
                  name="ownerWallet"
                  label="Owner Wallet"
                  className="MyTextField col-md-8"
                  onChange={this.handleFormChange}
                  value={ownerWallet}
                  validators={['required', 'isValidAddress', 'isSameWallet']}
                  errorMessages={['Owner Wallet is Required', 'Invalid ETH Address', 'Property and Owner Wallet cannot be same']}
                  helperText="Will Receive Unsold Tokens & TKUSD Invested After Goal"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <AccountBalanceWallet />
                    </InputAdornment>,
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <TextValidator
                  disabled
                  type="text"
                  margin="dense"
                  variant="outlined"
                  name="TKUSDAddress"
                  label="TKUSD Address"
                  className="MyTextField col-md-8"
                  onChange={this.handleFormChange}
                  value={TKUSDAddress}
                  validators={['required', 'isValidAddress']}
                  errorMessages={['TKUSD Address is Required', 'Invalid ETH Address']}
                  helperText="TKUSD Tokenism Stable coin address"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                      <AccountBalanceWallet />
                    </InputAdornment>,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button type="submit" className='btn-info col-md-2 mr-auto' disabled={disabled}>
                  {!disabled ? 'Launch STO' : <i style={{ color: "white" }} className="fa fa-spinner fa-spin fa-1x fa-fw" />}
                </Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ Tokenize, Auth }) => {
  let { singlePropertyDetails } = Auth;
  let { error, loading, walletAddress, loadingMessage, token } = Tokenize;
  return {
    error,
    token,
    loading,
    walletAddress,
    loadingMessage,
    singlePropertyDetails
  }
}

const mapDispatchToProps = { startLoader, stopLoader, isError, getSinglePropertyDetails, LaunchedSTO };

export default connect(mapStateToProps, mapDispatchToProps)(LaunchSTO);
