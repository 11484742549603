import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import { MenuItem, Grid } from "@material-ui/core";
import { validate } from 'wallet-address-validator';
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

import { web3 } from '../../../store/web3';
import { Whitelist } from '../../../store/contract';

import { addNewRole } from "../../../store/actions/Roles";
import { showLoader } from "../../../store/actions/Auth";

class NewRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SingleRowData: null,
      formData: {
        name: "",
        role: "",
        email: "",
        publicAddress: "",
      },
      roles: [
        // { name: 'Admin', value: 'admin' },
        { name: 'Assets Manager', value: 'assets' },
        { name: 'Finance Manager', value: 'finance' }
      ]
    };
  }

  handleSubmit = async () => {
    try {
      let { publicAddress, role } = this.state.formData;

      this.props.showLoader(true);
      if (!validate(publicAddress, 'ETH')) {
        EventBus.publish("error", `Invalid Public address`)
        this.props.showLoader(false);
        return;
      }

      if (await Whitelist.methods.isWhitelistedManager(publicAddress).call()) {
        EventBus.publish("error", `Already added as Manager`)
        this.props.showLoader(false);
        return;
      }

      let from = (await web3.currentProvider.enable())[0];
      Whitelist.methods.addWhitelistedManager(publicAddress, role).send({ from })
        .on('transactionHash', hash => console.log(`*******hash = ${hash}`))
        .on('receipt', receipt => {
          console.log(`*******receipt = `, receipt);
          this.props.addNewRole({ payload: this.state.formData, history: this.props.history });
        })
        .on('error', error => {
          console.log(`*********error whitelist = `, error);
          this.props.showLoader(false);
        });
    } catch (e) { this.props.showLoader(false) }
  }

  handleFormChange = event => {
    this.setState({ propertyDescriptionError: false });
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  };

  render() {
    return (
      <div className="content">
        <div className="main-container">
          <div className="agreement-header">
            <h2 style={{ marginBottom: "20px" }}>Add New Role</h2>
          </div>
          <ValidatorForm
            className="validatorListForm"
            onSubmit={this.handleSubmit}
          >
            <Grid container justify="space-around" spacing={3}>
              <Grid item xs={12}>
                <TextValidator
                  type="text"
                  name="name"
                  label="Name"
                  margin="dense"
                  variant="outlined"
                  validators={["required"]}
                  onChange={this.handleFormChange}
                  value={this.state.formData.name}
                  className="MyTextField mx-auto col-10"
                  errorMessages={["Name can not be empty"]}
                />
              </Grid>

              <Grid item xs={12}>
                <TextValidator
                  select
                  type="text"
                  name="role"
                  label="Role"
                  margin="dense"
                  variant="outlined"
                  className="MyTextField col-10"
                  validators={["required"]}
                  onChange={this.handleFormChange}
                  value={this.state.formData.role}
                  errorMessages={["Role can not be empty"]}
                >
                  {this.state.roles.map((item, index) =>
                    <MenuItem
                      name="role"
                      key={index}
                      onChange={this.handleFormChange}
                      value={item['value']}
                    >
                      {item['name']}
                    </MenuItem>
                  )}
                </TextValidator>
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  type="email"
                  name="email"
                  label="Email"
                  margin="dense"
                  variant="outlined"
                  onChange={this.handleFormChange}
                  validators={['required', 'isEmail']}
                  value={this.state.formData.email}
                  className="MyTextField mx-auto col-10"
                  errorMessages={["Email can not be empty"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  type="text"
                  name="publicAddress"
                  label="Wallet Address"
                  margin="dense"
                  variant="outlined"
                  validators={["required"]}
                  onChange={this.handleFormChange}
                  value={this.state.formData.publicAddress}
                  className="MyTextField mx-auto col-10"
                  errorMessages={["Wallet Address can not be empty"]}
                />
              </Grid>
            </Grid>
            <div className='pt-5 pb-3'>
              <Button
                type="submit"
                className="btn-info col-4"
                disabled={this.props.buttonLoader}
              >
                {!this.props.buttonLoader ? (
                  "Submit Role"
                ) : (
                    <i
                      style={{ color: "white" }}
                      className="fa fa-spinner fa-spin fa-1x fa-fw"
                    ></i>
                  )}
              </Button>
            </div>
          </ValidatorForm>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ Auth }) => {
  let { buttonLoader } = Auth;
  return { buttonLoader }
}

const mapDispatchToProps = { showLoader, addNewRole };

export default connect(mapStateToProps, mapDispatchToProps)(NewRole);
