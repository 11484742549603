import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";

// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";
import { getOwnerDashboardStats } from '../../../store/actions/PropertyOwner'
import { startLoader, stopLoader } from "../../../store/actions/Tokinize";

// core components
import {
    ownerPropertiesStats,
    getOwnerDashData
} from "variables/charts.jsx";
import "./dashboard.css"
import { connect } from "react-redux";
class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: "data1",
      isLoader:true,
    };
    props.startLoader(`Fetching Dashboard Data`);
  }
  setBgChartData = name => {
    this.setState({
      bigChartData: name
    });
  };

  componentDidMount(){
    this.props.getOwnerDashboardStats()
    getOwnerDashData()
    setTimeout(() => {
      this.props.stopLoader()
      this.setState({isLoader:false})
    }, 5000);
  }

  render() {
    return (
        <div className="content">
          {this.state.isLoader ? 
          "loading"
          : 
            <>
              <Row>
                <Col xs="12">
                  <Card className="card-chart">
                    <CardHeader>
                      <Row>
                        <Col className="text-left" sm="6">
                          <CardTitle tag="h2">Properties Stats</CardTitle>
                        </Col>
                        <Col sm="6">
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <div className="chart-area">
                        <Line
                          data={ownerPropertiesStats[this.state.bigChartData]}
                          options={ownerPropertiesStats.options}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          }
        </div>
    );
  }
}

const mapDispatchToProps = { getOwnerDashboardStats, startLoader, stopLoader }


export default connect(null, mapDispatchToProps)(Dashboard);
