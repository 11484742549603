import moment from 'moment';
import EventBus from 'eventing-bus';
import classnames from 'classnames';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { getSingleSupportDetails } from '../../../store/actions/Auth';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { fetchingTokens, fetchedToken, isError, startLoader, stopLoader } from "../../../store/actions/Tokinize";

// import './propertyDetail.css';
import InvestHistory from '../../../components/SingleUserTabs/InvestHistory';
import DepositHistory from '../../../components/SingleUserTabs/DepositHistory';
import WithdrawHistory from '../../../components/SingleUserTabs/WithdrawHistory';
import UserOverview from '../../../components/SingleUserTabs/UserOverview';
import UserDocuments from '../../../components/SingleUserTabs/UserDocuments';

import UserSettings from '../../../components/SingleUserTabs/UserSettings';
import UserWallets from '../../../components/SingleUserTabs/UserWallets';
import UserBanks from '../../../components/SingleUserTabs/UserBanks';
import { Button } from "reactstrap";

class SupportDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      token: '',
      symbol: '',
      stoTiers: '',
      activeTab: '1',
      stoDetails: '',
      tokenDetails: '',
      propertyDetails: '',
    };
  }

  componentDidMount() {
    const url = require('url');
    const { query } = url.parse(document.location.href, true);

    if (!query['id']) {
      EventBus.publish("error", `Please select user`)
      setTimeout(() => this.props.history.push(`/home/users`), 1000);
    } else {
      this.props.startLoader(`Fetching Ticket Details`);
      this.props.getSingleSupportDetails(query['id']);
    }
  }

  componentWillReceiveProps({ supportDetail }) {
    console.log(`**************supportDetail = `, supportDetail);

    if (supportDetail) this.setState({ supportDetailsName: supportDetail['name'], supportDetailsEmail: supportDetail['email'], supportDetailsContactType: supportDetail['contactType'], supportDetailsIssueType: supportDetail['issueType'], supportDetailsMessage: supportDetail['message'], supportDetailsCreatedAt: supportDetail['createdAt'], supportDetailsPhoneNo: supportDetail['phoneNo'] })
  }

  toggle = tab => {
    const { activeTab } = this.state
    if (activeTab !== tab) this.setState({ activeTab: tab });
  }

  goBack=()=>{
    this.props.history.goBack();
  }

  render() {
    let { supportDetails, supportDetailsName, supportDetailsEmail, supportDetailsContactType, supportDetailsIssueType , supportDetailsMessage , supportDetailsCreatedAt, supportDetailsPhoneNo } = this.state;
    return (
      <div className='content'>
        <div className='main-container'>
          <div className="row">
            <div className="col-5">
              <Button onClick={this.goBack} className="btn-info action-btn action-btn-outline-info">
                Back
              </Button>
            </div>
            <div className="col-6">
              <h3 >
                Ticket Details
              </h3>
            </div>
          </div>
        <div className="single-row">
          <div className="modal-content-title">
            Name
          </div>
          <div className="modal-content-data">
            {supportDetailsName}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Email
          </div>
          <div className="modal-content-data">
            {supportDetailsEmail}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Contact Type
          </div>
          <div className="modal-content-data">
            {supportDetailsContactType}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Issue Type
          </div>
          <div className="modal-content-data">
            {supportDetailsIssueType}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Message
          </div>
          <div className="modal-content-data">
            {supportDetailsMessage}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Contact No
          </div>
          <div className="modal-content-data">
            {supportDetailsPhoneNo}
          </div>
        </div>

        <div className="single-row">
          <div className="modal-content-title">
            Created At
          </div>
          <div className="modal-content-data">
            {moment(supportDetailsCreatedAt).format('lll')}
          </div>
        </div>
      </div>
    </div>
    )
  }
}

const currencies =
  [
    'ETH',
    'POLY',
    'DAI (USD Stablecoin)',
    'Non-USD Stablecoin',
  ];

const mapStateToProps = (state) => {
  let { supportDetail } = state.Auth;
  return { supportDetail };
}

const mapDispatchToProps = { getSingleSupportDetails, fetchingTokens, startLoader, stopLoader, fetchedToken, isError };

export default connect(mapStateToProps, mapDispatchToProps)(SupportDetails)
