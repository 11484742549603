import LaunchSTO from "views/Manager/LaunchSTO";
import PropertyDetails from 'views/Manager/PropertyDetails';
import UserDetails from 'views/Manager/UserDetails';
import SupportDetails from 'views/Manager/SupportDetails';
import Dashboard from "views/Admin/Dashboard/Dashboard.jsx";
import SellRequest from "views/Manager/SellRequest/SellRequest.js";
import ListedProperties from "views/Manager/ListedProperties/ListedProperties.js";
import TokenizeProperty from "views/Manager/TokenizeProperty/TokenizeProperty.js";
import Agreements from "views/Manager/Agreements/Agreements.js";
import Users from "views/Manager/Users/Users.js";
import PremiumRequests from "views/Manager/PremiumRequests/PremiumRequests.js";
import WireWithdrawal from "views/Manager/WireWithdrawal/WireWithdrawal.js";
import Support from "views/Manager/Support/Support.js";
import Events from "views/Manager/Events/Events.js";
import Step1 from "views/Manager/ListNewPropertySteps/Step1";
import Step2 from "views/Manager/ListNewPropertySteps/Step2";
import Step3 from "views/Manager/ListNewPropertySteps/Step3";
import AddPropertyOwners from "views/Admin/AddPropertyOwners/AddPropertyOwners.js";
import PropertyOwners from "views/Admin/PropertyOwners/PropertyOwners.js";
import Blogs from "views/Manager/Blogs/Blogs.js";
import NewBlog from "views/Manager/NewBlog/NewBlog.js";
import NewEvent from "views/Manager/NewEvent/NewEvent.js";
import EditBlog from "views/Manager/EditBlog/EditBlog.js";

import Roles from "views/Manager/Roles/Roles.js";
import NewRole from "views/Manager/NewRole/NewRole";
import UpdateEvent from "views/Manager/UpdateEvent/UpdateEvent.js";

var routes = [
  {
    layout: "/home",
    name: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    icon: "tim-icons icon-chart-pie-36",
  },

  {
    name: "Property",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-bank",
    childs: [{
      component: SellRequest,
      name: "Sell Requests",
      path: "/sell-requests",
      icon: "tim-icons icon-money-coins",
    },
    {
      name: "Listed Properties",
      path: "/active-listings",
      icon: "tim-icons icon-single-copy-04",
    },
    {
      path: "/list-property",
      name: "Add Property",
      icon: "tim-icons icon-simple-add",
    }]
  },
  {
    name: "hidden",
    layout: "/home",
    component: SellRequest,
    path: "/sell-requests",
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/active-listings",
    component: ListedProperties,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/list-property",
    component: Step1,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/property-sections",
    component: Step2,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/property-documents",
    component: Step3,
  },

  {
    name: "hidden",
    layout: "/home",
    path: "/tokenize",
    component: TokenizeProperty,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/launch-sto",
    component: LaunchSTO,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/property-details",
    component: PropertyDetails,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/user-details",
    component: UserDetails,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/support-details",
    component: SupportDetails,
  },
  {
    name: "Property Owners",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-bank",
    childs: [{
      component: PropertyOwners,
      name: "Owners List",
      path: "/owner-list",
      icon: "tim-icons icon-single-copy-04",
    },
    {
      component: AddPropertyOwners,
      name: "Add Owner",
      path: "/add-owner",
      icon: "tim-icons icon-simple-add",
    }]
  },
  {
    component: PropertyOwners,
    name: "hidden",
    path: "/owner-list",
    layout: "/home",
    icon: "tim-icons icon-single-copy-04",
  },
  {
    component: AddPropertyOwners,
    name: "hidden",
    layout: "/home",
    path: "/add-owner",
    icon: "tim-icons icon-simple-add",
  },
  {
    name: "Agreements",
    layout: "/home",
    path: "/agreement",
    component: Agreements,
    icon: "tim-icons icon-paper",
  },
  {
    name: "Premium Requests",
    layout: "/home",
    path: "/premium-requests",
    component: PremiumRequests,
    icon: "tim-icons icon-paper",
  },

  {
    name: "Users",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-notes",
    childs: [{
      name: "All Users",
      path: "/users",
      icon: "tim-icons icon-single-copy-04",
    },]
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/users",
    component: Users,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/premium-requests",
    component: PremiumRequests,
  },
  {
    name: "Blogs",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-notes",
    childs: [{
      name: "List Blogs",
      path: "/list-blogs",
      icon: "tim-icons icon-single-copy-04",
    },
    {
      name: "Add Blog",
      path: "/new-blog",
      icon: "tim-icons icon-simple-add",
    }]
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/list-blogs",
    component: Blogs,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/new-blog",
    component: NewBlog,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/new-event",
    component: NewEvent,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/edit-event",
    component: UpdateEvent,
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/edit-blog",
    component: EditBlog
  },


  {
    name: "Manager Roles",
    layout: "/home",
    type: "hasSubs",
    icon: "tim-icons icon-badge",
    childs: [
      {
        name: "List Managers",
        path: "/list-manager",
        icon: "tim-icons icon-single-copy-04",
      },
      {
        name: "Add Manager",
        path: "/new-manager",
        icon: "tim-icons icon-simple-add",
      }
    ]
  },
  {
    name: "Wire Withdrawals",
    layout: "/home",
    path: "/wire-withdrawals",
    component: WireWithdrawal,
    icon: "tim-icons icon-badge",
  },
  {
    name: "Support Tickets",
    layout: "/home",
    path: "/support",
    component: Support,
    icon: "tim-icons icon-badge",
  },
  {
    name: "Events & Media",
    layout: "/home",
    path: "/events",
    component: Events,
    icon: "tim-icons icon-badge",
  },

  {
    name: "hidden",
    layout: "/home",
    path: "/list-manager",
    component: Roles
  },
  {
    name: "hidden",
    layout: "/home",
    path: "/new-manager",
    component: NewRole
  },

];
export default routes;
