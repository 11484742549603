import axios from 'axios';
import EventBus from 'eventing-bus';
import { getSinglePropertyDetails } from '../actions/Auth';
import { checkSymbolRes, stopLoader } from '../actions/Tokinize';
import { put, all, takeEvery, call } from 'redux-saga/effects';

const url = require('url');
const { query } = url.parse(document.location.href, true);

function* checkSymbol({ payload }) {
  const { error, response } = yield call(getCall, `/property/symbolValid/${payload}`);
  if (error) {
    yield put(checkSymbolRes({ data: false, message: error['response']['data']['message'] }));
    EventBus.publish("error", error['response']['data']['message'])
  }
  else if (response) {
    yield put(checkSymbolRes({ data: true, message: response['data']['message'] }));
    EventBus.publish("success", response['data']['message'])
  }
}

function* transferOwnership({ payload }) {
  const { error, response } = yield call(getCall, `/property/stoTransfer/${payload['stoId']}`);
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) {
    console.log('response', response);
    EventBus.publish("success", response['data']['message'])
  }
  yield put(getSinglePropertyDetails(payload['propertyId']));
  yield put(stopLoader());
}

function* extendStoTime({ payload }) {
  console.log('extendStoTime', payload);
  const { error, response } = yield call(getCall, `/property/extendTime/${payload['stoId']}`);
  if (error) {
    console.log('erros', error);
    EventBus.publish("error", error['response']['data']['message'])
  }
  else if (response) EventBus.publish("success", response['data']['message'])
  yield put(stopLoader());
}

function* finalizeSTO({ payload }) {
  console.log(`**************finalizeSTO = `, payload);

  const { error, response } = yield call(getCall, `/property/finalizeSto/${payload['propertyId']}`);
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) EventBus.publish("success", response['data']['message'])
  yield put(getSinglePropertyDetails(payload['propertyId']));
  yield put(stopLoader());
}

function* deleteImage({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/delete`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) EventBus.publish("success", response['data']['message'])
  yield put(getSinglePropertyDetails(payload['_id']));
}

function* onUploadCarousel({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/upload`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) {
    EventBus.publish("success", response['data']['message'])
    yield put(getSinglePropertyDetails(response['data']['body']));
  }
}

function* onUpdateSection({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/sections`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) EventBus.publish("success", response['data']['message'])
  yield put(getSinglePropertyDetails(payload['propertyId']));
}

function* updatePropertyOverview({ payload }) {
  const { error, response } = yield call(postCall, { path: `/property/updateOverview`, payload });
  if (error) EventBus.publish("error", error['response']['data']['message'])
  else if (response) {
    yield put(getSinglePropertyDetails(payload['_id']));
    EventBus.publish("success", response['data']['message'])
  }
}

function* propertyStep1({ payload, history }) {
  const { error, response } = yield call(postCall, { path: `/property/overview`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push(`/home/property-sections/?id=${response.data.body.propertyId}`), 1000);
  }
  yield put(stopLoader());
  // yield put({ type: 'SINGLE_PROPERTY_REQUEST', payload: response.data.body[0] });
}

function* propertyStep2({ payload, history, id }) {
  console.log('payload', payload);
  const { error, response } = yield call(postCall, { path: `/property/sections/${id}`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push(`/home/property-documents/?id=${response.data.body.propertyId}`), 1000);
  }
  yield put(stopLoader());
  // yield put({ type: 'SINGLE_PROPERTY_REQUEST', payload: response.data.body[0] });
}

function* propertyStep3({ payload, history, id }) {
  const { error, response } = yield call(postCall, { path: `/property/documents/${id}`, payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    console.log('response', response);
    yield put({ type: 'SHOW_LOADER', payload: false });
    EventBus.publish("success", response.data.message)
    setTimeout(() => history.push(`/home/tokenize?id=${response.data.body.propertyId}&name=${response.data.body.name}`), 1000);

    // setTimeout(() => history.push(`/home/tokenize?id=${response.data.body.propertyId}&name=${response.data.body.name}`), 1000);
  }
  yield put(stopLoader());
  // yield put({ type: 'SINGLE_PROPERTY_REQUEST', payload: response.data.body[0] });
}

function* uploadUserDocument({ payload, id }) {
  console.log(payload, id);
  const { error, response } = yield call(postCall, { path: `/property/userDoc/${id}`, payload });
  if (error) {
    yield put({ type: 'SHOW_LOADER', payload: false })
    EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    console.log('response', response);
    EventBus.publish("success", response.data.message)
    yield put(getSinglePropertyDetails(id));
    yield put({ type: 'SHOW_LOADER', payload: false })
  }
}

function* onDeleteUserDocument({ payload, id }) {
  console.log('onDeleteUserDocument', id, payload);
  const { error, response } = yield call(putCall, {path:`/property/userDoc/${id}`, payload});

  if (error) {
    console.log(error.response);
    // EventBus.publish("error", error.response.data.message)
  }
  else if (response) {
    yield put(getSinglePropertyDetails(id));
    EventBus.publish("success", response['data']['message'])
  }
}

function* getAllUserDocuments() {
  // const { error, response } = yield call(getCall, `/user/files/Agreement`);
  // if (error) EventBus.publish("error", error.response.data.message)
  // else if (response) yield put({ type: 'GET_AGREEMENTS_RESPONSE', payload: response.data.body })
}

function* getPropertyOwner() {
  const { error, response } = yield call(getCall, '/propertyOwner/getAllPropertyOwner');
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) {
    yield put({
      type: 'SET_PROPERTY_OWNER',
      payload: response['data']['body']
      })
  }
}

function* addPropertyOwner({ payload }) {
  const { error, response } = yield call(postCall, { path : '/propertyOwner/registeredOwner', payload });
  if (error) EventBus.publish("error", error.response.data.message)
  else if (response) EventBus.publish("success", response['data']['message'])
}

function* actionWatcher() {
  yield takeEvery('CHECK_SYMBOL', checkSymbol);
  yield takeEvery('DELETE_IMAGE', deleteImage)
  yield takeEvery('UPDATE_SECTION', onUpdateSection)
  yield takeEvery('UPLOAD_CAROUSEL', onUploadCarousel)
  yield takeEvery('TRANSFER_OWNERSHIP', transferOwnership)
  yield takeEvery('EXTEND_STO_TIME', extendStoTime)
  yield takeEvery('UPDATE_PROPERTY_OVERVIEW', updatePropertyOverview)
  yield takeEvery('FINALIZE_STO', finalizeSTO)
  yield takeEvery('PROPERTY_STEP_1', propertyStep1)
  yield takeEvery('PROPERTY_STEP_2', propertyStep2)
  yield takeEvery('PROPERTY_STEP_3', propertyStep3)
  yield takeEvery('DELETE_USER_DOCUMENT', onDeleteUserDocument)
  yield takeEvery('GET_ALL_USER_DOCUMENT', getAllUserDocuments)
  yield takeEvery('UPLOAD_USER_DOCUMENT', uploadUserDocument)
  yield takeEvery('GET_PROPERTY_OWNER', getPropertyOwner)
  yield takeEvery('ADD_PROPERTY_OWNER', addPropertyOwner)

}

export default function* rootSaga() { yield all([actionWatcher()]) };

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error['response']['data']['code'] === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error });
    });
}

function getCall(path) {
  return axios
    .get(path)
    .then((response) => ({ response }))
    .catch(error => {
      if (error['response']['data']['code'] === 401)
        // console.log(`***********Please Logout`);
        EventBus.publish('tokenExpired', true);
      return ({ error });
    });
}

function deleteCall(path) {
  return axios.delete(path)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}

function putCall({ path, payload }) {
  return axios.put(path, payload)
    .then((response) => ({ response }))
    .catch(error => {
      if (error.response.data.code === 401)
        EventBus.publish('tokenExpired', true);
      return ({ error })
    });
}
