import { PURGE } from "redux-persist";

var initialState = {
  propertyOwners: [],
}

const Property = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE:
      return initialState;

    case 'SET_PROPERTY_OWNER':
      return {
        ...state,
        propertyOwners: payload
      };
      
    default:
      return state;
  }
};
export default Property;
