export const saveUserData = (data) => ({
  type: 'SAVE_USER_DATA',
  payload: data,
});

export const login = ({ data, history }) => ({
  type: 'LOGIN',
  payload: data,
  history
});

export const loginRequest = ({ data, history }) => ({
  type: 'LOGIN_REQUEST',
  payload: data,
  history
});

export const logout = () => ({
  type: 'LOGOUT',
});

export const getPropertyRequest = () => ({
  type: 'GET_PROPERTY_REQUEST'
});

export const onDelete = (data) => ({
  type: 'DELETE_AGREEMENT',
  payload: data
});

export const onDeleteBlog = (data) => ({
  type: 'DELETE_BLOG',
  payload: data
});

export const getAllAgreements = () => ({
  type: 'GET_ALL_AGREEMENTS',
});

export const getAllBlogs = () => ({
  type: 'GET_ALL_BLOGS',
});

export const uploadAgreement = (data) => ({
  type: 'UPLOAD_AGREEMENTS',
  payload: data
});

export const getListedProperties = () => ({
  type: 'GET_LISTED_PROPERTIES'
});

export const ListNewPropertyFn = ({ data, history }) => ({
  type: 'LIST_NEW_PROPERTY',
  payload: data,
  history
});

export const AddNewBlog = ({ data, history }) => ({
  type: 'ADD_NEW_BLOG',
  payload: data,
  history
});

export const TokenizePropertyFn = ({ data, history }) => ({
  type: 'TOKENIZE_PROPERTY',
  payload: data,
  history
});

export const LaunchedSTO = ({ data, history }) => ({
  type: 'LAUNCHED_STO',
  payload: data,
  history
});

export const getSinglePropertyRequest = (data) => ({
  type: 'GET_SINGLE_PROPERTY_REQUEST',
  payload: data
});

export const getSinglePropertyDetails = (data) => ({
  type: 'GET_SINGLE_PROPERTY_DETAILS',
  payload: data
});

export const getSingleBlog = (data) => ({
  type: 'GET_SINGLE_BLOG',
  payload: data,
});

export const EditSingleBlog = ({ payload, history, id }) => ({
  type: 'EDIT_SINGLE_BLOG',
  payload,
  history,
  id
});

export const updateBlogImage = ({ data, id }) => ({
  type: 'UPDATE_BLOG_IMAGE',
  payload: data,
  id
});

export const showLoader = (data) => ({
  type: 'SHOW_LOADER',
  payload: data
});

export const getAllUsers = () => ({
  type: 'GET_ALL_USERS',
});

export const getAllPremiumRequests = () => ({
  type: 'GET_ALL_PREMIUM_REQUESTS',
});

export const deletePremiumRequest = (data) => ({
  type: 'DELETE_PREMIUM_REQUEST',
  payload: data
});

export const acceptPremiumRequest = (data) => ({
  type: 'ACCEPT_PREMIUM_REQUEST',
  payload: data
});

export const getAllSupportTickets = () => ({
  type: 'GET_ALL_SUPPORT_TICKETS',
});

export const deleteTicket = (data) => ({
  type: 'DELETE_SUPPORT_TICKETS',
  payload: data
});

export const getSingleUserDetails = (data) => ({
  type: 'GET_SINGLE_USER_DETAILS',
  payload: data
});

export const getSingleSupportDetails = (data) => ({
  type: 'GET_SINGLE_SUPPORT_DETAILS',
  payload: data
});

export const uploadDocument = ({data, id}) => ({
  type: 'UPLOAD_DOCUMENT',
  payload: data,
  id:id
});

export const uploadWithHoldingTax = ({data, id}) => ({
  type: 'UPLOAD_WITH_HOLDING_TAX',
  payload: data,
  id:id
});

export const editUserInfo = ({data, id}) => ({
  type: 'EDIT_USER_INFO',
  payload: data,
  id:id
});

export const onDeleteUserDoc = ({data, id}) => ({
  type: 'DELETE_USER_DOC',
  payload: data,
  id:id
});

export const sendTkusd = ({data}) => ({
  type: 'SEND_TKUSD',
  payload: data,
});

export const deleteEvent = (id) => ({
  type: 'DELETE_EVENT',
  payload: id,
});

export const updateEvent = ({data, id, history}) => ({
  type: 'UPDATE_EVENT',
  payload: data,
  id,
  history
});

export const getSingleEvent = (id) => ({
  type: 'GET_SINGLE_EVENT',
  payload: id,
});

export const getAllEvents = () => ({
  type: 'GET_ALL_EVENTS',
});

export const addNewEvent = ({data, history}) => ({
  type: 'ADD_NEW_EVENT',
  payload: data,
  history
});

export const getAllWireWithdrawal = () => ({
  type: 'GET_ALL_WIRE_WITHDRAWAL',
});

export const rejectWireRequest = ({data}) => ({
  type: 'REJECT_WIRE_REQUEST',
  payload: data,
});

export const acceptWireRequest = ({data}) => ({
  type: 'ACCEPT_WIRE_REQUEST',
  payload: data,
});

export const getDashStats = () => ({
  type: 'GET_DASHBOARD_DATA',
});