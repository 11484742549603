import { PURGE } from "redux-persist";
// var initialState={
//   loader:false,
//   propertyRequested:'',
//   listedProperties:''
// }

const initialState = {
  sdk: undefined,
  loading: false,
  loadingMessage: '',
  error: undefined,
  networkId: -1,
  walletAddress: '',
  tokens: undefined,
  validSymbol: false,
  validSymbolMsg: '',
}

const Tokenize = (state = initialState, action) => {
  console.log('ACTION', action)
  switch (action.type) {
    case 'START_LOADER':
      return {
        ...state,
        loading: true,
        error: undefined,
        loadingMessage: action.payload,
      }
    case 'STOP_LOADER':
      return {
        ...state,
        loading: false,
        loadingMessage: '',
      }
    case 'CHECK_SYMBOL_RESPONSE':
      return {
        ...state,
        validSymbol: action.payload.data,
        validSymbolMsg: action.payload.message,
      }
    case 'FETCHING_TOKENS':
      return {
        ...state,
        loading: true,
        error: undefined,
        loadingMessage: 'Fetching your previously tokens',
      }
    case 'CREATING_STO':
      return {
        ...state,
        loading: true,
        loadingMessage: 'Lunching Property STO'
      }
    case 'LAUNCHED_STO': {
      return {
        ...state,
        loading: false,
        reservations: undefined,
        loadingMessage: 'Property STO Created',
      }
    }
    case 'FETCHED_RESERVATIONS':
      return {
        ...state,
        loading: false,
        error: undefined,
        reservations: action.payload,
        loadingMessage: 'Reservation fetched',
      }
    case 'FETCHED_TOKENS':
      return {
        ...state,
        loading: false,
        error: undefined,
        tokens: action.payload,
        loadingMessage: 'Tokens fetched',
      }
    case 'FETCHED_TOKEN':
      return {
        ...state,
        loading: false,
        error: undefined,
        token: action.payload,
        loadingMessage: 'Token details fetched',
      }
    case 'ERROR':
      return {
        ...state,
        loading: false,
        loadingMessage: '',
      }
    default:
      return { ...state }
  }
}
export default Tokenize;
